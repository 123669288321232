export default {
  entities: {
    posTerminal: {
      headers: {
        id: 'ID',
        posName: 'Касса',
        storeName: 'Магазин',
        qrcode: 'QR код',
      },
      store: 'Магазин',
      modal: {
        titles: {
          qrcode: 'QR код сотрудника',
        },
        btns: {
          print: 'Распечатать',
          download: 'Скачать',
        },
      },
    },
  },
};
