<template>
  <div class="flex flex-col gap-y-2 mt-4">
    <api-error :error="error" />

    <at-table :head="tableHeaders" :elements="isLoading ? new Array(7).fill(null) : cashbacks">
      <template #element="{ element: cashback }">
        <template v-if="isLoading">
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }" />
          </at-table-item>
        </template>
        <template v-else>
          <cashbacks-table-row :cashback="cashback" />
        </template>
      </template>

      <template v-if="!isLoading && meta.last_page > 1" #footer>
        <div class="flex items-center gap-x-3 justify-between">
          <div class="text-gray-700 dark:text-white font-light">
            <i18n-t keypath="bonuses.index.table.paginationInfo">
              <template #from>
                <span class="font-medium">{{ meta.from }}</span>
              </template>
              <template #to>
                <span class="font-medium">{{ meta.to }}</span>
              </template>
              <template #total>
                <span class="font-medium">{{ meta.total }}</span>
              </template>
            </i18n-t>
          </div>
          <at-pagination
            :model-value="page"
            :total="meta.last_page"
            @update:model-value="$emit('update:page', $event)"
          />
        </div>
      </template>
    </at-table>
    <div v-if="!isLoading && !hasCashbacks" class="mt-4">
      <at-alert type="info">{{ $t('system.errors.noData') }}</at-alert>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { AtTable, AtTableItem, AtSkeleton, AtAlert, AtPagination } from '@packages/aliftech-ui';

  import CashbacksTableRow from './CashbacksTableRow.vue';

  import { ApiError } from '@/to-fsd/shared/lib';

  const props = defineProps({
    type: {
      type: String,
      default: null,
    },
    contractDate: {
      type: String,
      default: null,
    },
    chargedAt: {
      type: String,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    meta: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    cashbacks: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Object,
      default: null,
    },
  });

  defineEmits(['update:page']);

  const { t } = useI18n();

  const hasCashbacks = computed(() => {
    return props.cashbacks.length >= 1;
  });

  const tableHeaders = computed(() => {
    const headers = [
      { title: '№' },
      { title: t('bonuses.index.table.cols.applicationAmount') },
      { title: t('bonuses.index.table.cols.cashbackAmount') },
      { title: t('bonuses.index.table.cols.contractDate') },
      { title: t('bonuses.index.table.cols.chargedAt') },
      { title: t('bonuses.index.table.cols.status') },
    ];

    return headers;
  });
</script>
