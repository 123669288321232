// todo refactor module name
const moduleName = 'module_azo';

import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/faq',
    name: ROUTE_NAMES.faqList,
    component: () => import('../views/NasiyaFaqIndexPage'),
    redirect: () => {
      return { name: ROUTE_NAMES.faqListToFsd };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Часто задаваемые вопросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/faq/to-fsd',
    name: ROUTE_NAMES.faqListToFsd,
    component: async () => {
      const { FaqPage } = await import('@/to-fsd/pages/faq');

      return FaqPage;
    },

    meta: {
      middlewares: [
        setDocumentTitle('Часто задаваемые вопросы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        fetchNasiyaResources,
      ],
    },
  },
];
