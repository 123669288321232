import { CASHBACK_STATUS } from '@/to-fsd/entities/cashback';

export default {
  pages: {
    bonuses: {
      statuses: {
        [CASHBACK_STATUS.CHARGE]: 'Tasdiqlandi',
        [CASHBACK_STATUS.TO_PAY]: 'Hisoblash uchun tayyor',
        [CASHBACK_STATUS.NOT_APPROVED]: 'Tasdiqlanmadi',
        [CASHBACK_STATUS.REJECT]: 'Qaytarish uchun hisob',
        [CASHBACK_STATUS.TOTAL]: "Umumiy o'tkazilgan bonus miqdori",
      },
      filters: {
        placeholders: {
          contractDate: 'Yaratilgan sana',
          chargedAt: 'Hisobga olingan sana',
        },
      },
    },
  },
};
