import mainConnect, { generateError } from '@/services';

export const deliveriesService = {
  /**
   * Get deliveries app
   *
   * @returns {Promise<DeliveriesApp>}
   * @typedef {Object} DeliveriesApp
   *
   * @throws {Error}
   */
  getApp() {
    return mainConnect.get('deliveries/app').catch(generateError);
  },

  /**
   * Get deliveries data with pagination, search and filter by status
   *
   * @param {Object} params
   * @param {String} params.q - search query
   * @param {Number} params.status_id - `1` is new, `2` is delivered
   * @param {Number} params.page - page
   * @param {Number} params.per_page - pagination, default 15
   * @param {String} params.deliverable_type - deliverable type [application]
   *
   * @returns {Promise<Deliveries>}
   * @typedef {Object} Deliveries
   * @property {Number} data[].id - delivery id
   * @property {String} data[].type - type
   * @property {Date} data[].created_at - delivery created at datetime
   * @property {Date} data[].type - type
   * @property {Number} data[].status.id - delivery status id
   * @property {Number} data[].deliverable_id - deliverable id [application id]
   * @property {String} data[].deliverable_type - deliverable type [application]
   * @property {String} data[].receiver.receiver_info.contact_person - client's full name
   * @property {String} data[].receiver.receiver_info.contact_phone - client's phone
   * @property {String} data[].receiver.location.city - city
   * @property {String} data[].receiver.location.district - district
   * @property {String} data[].receiver.location.region - region
   * @property {String} data[].receiver.location.street - street
   * @property {String} data[].sender.location.city - city
   * @property {String} data[].sender.location.district - district
   * @property {String} data[].sender.location.region - region
   * @property {String} data[].sender.location.street - street
   * @property {Number} data[].products_info.amount - total amount
   * @property {Number} meta.last_page - last page for pagination
   * @throws {Error}
   */
  getDeliveries(params) {
    const newParams = { ...params, deliverable_type: 'application' };
    return mainConnect.get('deliveries/deliveries', { params: newParams }).catch(generateError);
  },

  /**
   * Get delivery
   *
   * @param {Number} id
   * @param {Object} params
   * @param {String} params.deliverable_type - deliverable type [application]
   *
   * @returns {Promise<Delivery>}
   * @typedef {Object} Delivery
   * @property {Number} id - delivery id
   * @property {String} address - address
   * @property {String} nearby - nearby
   * @property {String} note - note
   * @property {String} type - type
   * @property {Number} deliverable_id - application id
   * @property {String} deliverable_type - deliverable type [application]
   * @property {Number} status.id - delivery status id
   * @property {String} status_tag.body_ru - Delivery status ru
   * @property {String} status_tag.body_uz - Delivery status uz
   * @property {Number} application.client.id - client id
   * @property {String} receiver.receiver_info.contact_person - client's full name
   * @property {String} receiver.receiver_info.contact_phone - client's phone
   * @property {String} receiver.location.city - city
   * @property {String} receiver.location.district - district
   * @property {String} receiver.location.region - region
   * @property {String} receiver.location.street - street
   * @property {String} sender.location.city - city
   * @property {String} sender.location.district - district
   * @property {String} sender.location.region - region
   * @property {String} sender.location.street - street
   * @property {String} dpd_application.type_service - type service
   * @property {Number} products_info.amount - total amount
   * @throws {Error}
   */
  getDelivery(id, params) {
    const newParams = { ...params, deliverable_type: 'application' };
    return mainConnect.get(`deliveries/deliveries/${id}`, { params: newParams }).catch(generateError);
  },

  getDeliveryByApplicationId(applicationId) {
    const params = { deliverable_type: 'application' };
    const deliverableId = applicationId;

    return mainConnect.get(`/deliveries/deliverable/${deliverableId}/with-type`, { params }).catch(generateError);
  },

  getSelfDelivery(id, params) {
    return mainConnect.get(`/deliveries/deliveries/self-delivering/deliveries/${id}`, { params }).catch(generateError);
  },

  /**
   * Request for confirm self delivery
   *
   * @param {Number} id - delivery id
   * @throws {Error}
   */
  requestSelfDelivery(id) {
    return mainConnect.post(`deliveries/deliveries/self-delivering/${id}/request-delivered`).catch(generateError);
  },

  /**
   * Confirm self delivery
   *
   * @param {Number} id - delivery id
   * @param {Object} params
   * @param {String} params.code - otp code
   *
   * @throws {Error}
   */
  confirmSelfDelivery(id, params) {
    return mainConnect.post(`deliveries/deliveries/self-delivering/${id}/delivered`, params).catch(generateError);
  },

  /**
   * Cancel self delivery
   *
   * @param {Number} id - delivery id
   * @throws {Error}
   */
  cancelSelfDelivery(id) {
    return mainConnect.post(`deliveries/deliveries/self-delivering/${id}/cancel`).catch(generateError);
  },

  /**
   * Get regions list
   *
   * @param {Object} params
   *
   * @returns {Promise<Regions>}
   * @typedef {Object} Regions
   * @property {Number} id - region id
   * @property {String} body - region name
   * @property {String} key - region key
   * @throws {Error}
   *
   */
  getRegions(params) {
    return mainConnect.get('/deliveries/deliveries/dpd/regions', { params }).catch(generateError); // TEST область
  },

  /**
   * Get districts list
   *
   * @param {Object} params
   * @param {Number} params.region region id
   *
   * @returns {Promise<Districts>}
   * @typedef {Object} Districts
   * @property {Number} id - region id
   * @property {String} body - region name
   * @property {Number} region_id - region id
   * @throws {Error}
   *
   */
  getDistricts(params) {
    return mainConnect.get('/deliveries/deliveries/dpd/districts', { params }).catch(generateError); // TEST район
  },

  getCities(params) {
    return mainConnect.get('/deliveries/deliveries/dpd/cities', { params }).catch(generateError); // TEST населенный пункт
  },

  getUzPostLocations(params) {
    return mainConnect.get('/deliveries/uz-post/locations', { params }).catch(generateError);
  },

  // TODO: docs
  getApplicationDelivery(applicationDeliveryId) {
    return mainConnect.get(`/deliveries/application-deliveries/${applicationDeliveryId}`).catch(generateError);
  },

  storeApplicationDelivery(body) {
    return mainConnect.post(`/deliveries/application-deliveries/store`, body).catch(generateError);
  },

  updaterApplicationDelivery(body) {
    return mainConnect.post(`/deliveries/application-deliveries/update`, body).catch(generateError);
  },
};
