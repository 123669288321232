export const getters = {
  clientFullName: state => {
    const client = state.application?.client;
    if (!client) return null;
    return [client.name, client.surname, client.patronymic].join(' ');
  },
  clientPhone: state => {
    const client = state.application?.client;
    if (!client) return null;
    return client.phone;
  },
  applicationItems: state => {
    const items = [];
    state.application?.application_items?.forEach(el => {
      items.push({ name: el.good_name, price: el.price });
    });
    return items;
  },
};
