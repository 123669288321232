export default {
  index: {
    title: 'Hisobotlar',
    headers: {
      file: 'Fayl',
      period: "Oralig'i",
      lastGenerated: "So'nggi generatsiya",
      action: 'Harakatlar',
    },
    forAllPeriod: 'Butun davr uchun',
    generate: 'Yaratish',
    download: 'Yuklash',
    day: 'Kun',
    period: 'Davr',
  },
  list: {
    merchants_service: 'Tovarlar',
    merchants_rejects: 'Qaytarilgan tovarlar',
    merchants_problem: 'Muammoli mijozlar',
    merchants_invoice: 'Hisob faktura',
    merchants_minus_invoice: 'Minus hisob faktura',
    merchants_reconciliation: 'Solishtirma Dalolatnomasi',
    merchants_delivery: 'Yetkazib berishlar',
    delivery_tariffs: 'Yetkazib berish tariflari',
  },
  success: {
    generated: {
      title: "'{name}' nomli hisobot generatsiya bo'ldi",
      message: "Hisobotni ko'chirib olish uchun 'yuklash' tugmasini bosing",
    },
  },
  failed: {
    generated: {
      title: "'{name}' nomli hisobot generatsiya bo'lmadi",
    },
  },
  periodTypes: {
    month: 'Oy',
    quarter: 'Choraklik',
    half_year: 'Yarim yillik',
    year: 'Yillik',
  },
};
