export default {
  features: {
    employeeEditInfo: {
      panel: {
        title: 'Информация',
        fio: {
          label: 'ФИО',
        },
        phone: {
          label: 'Телефон',
        },
        partner: {
          label: 'Партнёр',
        },
        store: {
          label: 'Магазин',
        },
        submit: 'Сохранить',
      },
    },
  },
};
