import { paymentsService } from '../services/payments.service';

export const actions = {
  async fetchPaymentStatuses() {
    if (!this.paymentStatuses) {
      const response = await paymentsService.getPaymentStatuses();
      this.paymentStatuses = response?.data;
    }
    return Promise.resolve(this.paymentStatuses);
  },
};
