<template>
  <div class="rounded-full bg-white dark:bg-gray-800 text-green-600 p-2">
    <folder-add-icon class="w-6 text-primary-500" />
  </div>
  <p class="font-semibold">
    {{ $t('shared.ui.fileUploadButton.uploadFromDevice') }}
  </p>
  <div class="flex flex-col items-start">
    <at-button color="primary" type="button" :disabled="isDisabled" :loading="loading" @click="triggerFileInput">
      {{ buttonLabel || $t('shared.ui.fileUploadButton.selectFileBtn') }}
    </at-button>

    <input
      ref="fileInput"
      type="file"
      accept="image/png, image/jpeg, image/jpg"
      class="hidden"
      @change="handleFileChange"
    />

    <div v-if="errorMessage" class="mt-2 text-red-500">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import { AtButton } from '@packages/aliftech-ui';
  import { FolderAddIcon } from '@heroicons/vue/solid';

  const props = defineProps({
    buttonLabel: {
      type: String,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['file-selected', 'error']);

  const fileInput = ref(null);
  const previewUrl = ref(null);
  const errorMessage = ref('');

  const triggerFileInput = () => {
    if (!props.isDisabled && fileInput.value) {
      fileInput.value.click();
    }
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];

    errorMessage.value = '';
    previewUrl.value = null;
    emit('file-selected', null);

    if (!selectedFile) {
      return;
    }

    const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    if (!validTypes.includes(selectedFile.type)) {
      errorMessage.value = 'Invalid file type. Only PNG and JPG are allowed.';
      emit('error', errorMessage.value);
      event.target.value = '';
      return;
    }

    if (props.maxFileSize && selectedFile.size > props.maxFileSize) {
      const maxSizeMB = (props.maxFileSize / (1024 * 1024)).toFixed(2);
      errorMessage.value = `File size exceeds the limit of ${maxSizeMB} MB.`;
      emit('error', errorMessage.value);
      event.target.value = '';
      return;
    }

    previewUrl.value = URL.createObjectURL(selectedFile);
    emit('file-selected', selectedFile);
  };

  watch(previewUrl, (newUrl, oldUrl) => {
    if (oldUrl) {
      URL.revokeObjectURL(oldUrl);
    }
  });
</script>
