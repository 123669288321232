export default {
  stats: {
    sumOfItems: 'Товаров на сумму',
    itemsSold: 'Продано товаров',
    token: 'Токен партнера',
  },
  instructions: {
    title: 'Инструкция',
    conditions: {
      first: 'Оригиналы «Договора» всегда должны быть в компании.',
      second: 'Все акты должны быть полностью загружены на мерчант.',
      third:
        'Ежедневная сделка (товар) должна быть загружена в Excel через продавца и отправлена бухгалтеру, который должен отправить счет-фактуру на основе этой информации.',
      fourth: 'Порядок доставки оригинальной версии актов (в полном объеме):',
      fourthList: [
        '— Ташкент и Ташкентская область за 7 дней',
        '— Остальные регионы необходимо привозить каждые 10 дней',
      ],
    },
  },
  index: {
    allStores: 'Все магазины',
    title: 'Продажи',
    headers: {
      id: 'ID',
      item: 'Товар',
      client: 'Клиент',
      store: 'Магазин',
      sum: 'Сумма',
      signed: 'Подписано',
      amount: 'Кол-во',
    },
  },
};
