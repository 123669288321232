export default {
  index: {
    title: 'Кассы',
    headers: {
      id: 'ID',
      posName: 'Касса',
      storeName: 'Магазин',
      qrcode: 'QR код',
    },
    store: 'Магазин',
  },
};
