export default {
  entities: {
    rejectCase: {
      rejectionType: {
        full: 'Полный',
        partial: 'Частичный',
      },
      info: {
        title: 'Информация о возврате',
        clientName: 'Ф.И.О клиента',
        clientPhone: 'Номер клиента',
        date: 'Дата отправки',
        note: 'Причина возврата',
      },
      table: {
        title: 'Товары для возврата',
        headers: ['Наименование', 'Цена'],
      },
      cancelReason: {
        title: 'Причина отмены возврата',
      },
      photos: {
        title: 'Фото товара',
        alert: 'Фото товара не существует',
      },
    },
  },
};
