export default {
  features: {
    employeeChangePhone: {
      button: 'Telefonni almashtirish',
      modal: {
        title: 'Telefonni almashtirish',
        phone: {
          label: 'Telefon raqami',
        },
        code: {
          label: 'Kod',
          placeholder: 'Qabul qilingan kodni kiriting',
        },
        sendSms: 'SMS yuborish',
        confirm: 'Tasdiqlash',
        cancel: 'Bekor qilish',
      },
    },
  },
};
