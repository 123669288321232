export default {
  index: {
    title: 'Платежи',
    headers: {
      sum: 'Сумма транзакции / номер карты',
      date: 'Дата и время',
      status: 'Статус',
    },
    buttons: {
      revert: 'Отменить транзакцию',
      reset: 'Сбросить',
    },
    placeholders: {
      status: 'Статус',
    },
    statuses: {
      succeeded: 'Успешно',
      failed: 'Ошибка',
      timeout: 'Время вышло',
      pending: 'В ожидании',
      reverted: 'Отменен',
      need_confirmation: 'Нужно подтверждение',
    },
  },
  revert: {
    title: 'Отмена транзакции',
    subTitle: 'Вы действительно хотите отменить эту транзакцию?',
    buttons: {
      confirm: 'Да, отменить',
      cancel: 'Нет',
    },
    successTitle: 'Транзакция успешно отменена',
  },
};
