export default {
  index: {
    title: 'Доставки',
    titles: {
      delivery: 'Доставки',
    },
    headers: {
      client: 'Клиент',
      status: 'Статус',
      date: 'Дата',
    },
  },
  show: {
    title: 'Доставка №{id}',
    showContract: 'Показать акт',
    generate: 'Сгенерировать акт',
    contractLanguage: {
      ru: 'На русском',
      uz: 'На узбекском',
    },
    address: 'Адрес',
    nearby: 'Ориентир',
    note: 'Заметка',
    dpd: {
      title: {
        sender: 'Адрес отправителя',
        receiver: 'Адрес получателя',
      },
    },
    approximateTime: 'Приблизительное время доставки',
  },
  client: {
    name: 'Имя клиента',
    phone: 'Телефоны',
  },
  dpd: {
    create: {
      title: {
        header: 'Доставка Алифа',
        sender: 'Отправитель',
        receiver: 'Получатель',
        parcel: 'Посылка',
        schedule: 'Дни работы',
        chooseSchedule: 'Выберите пункт',
      },
      form: {
        deliveryType: 'Тип доставки',
        placeholder: 'Выберите значение',
        ENTER_ENTER: 'С двери до двери',
        ENTER_POINT: 'С двери до пункта выдачи',
        region: 'Область',
        district: 'Район',
        terminal: 'Пункт выдачи заказа',
        city: 'Населенный пункт',
        street: 'Улица',
        name: 'ФИО',
        phone: 'Номер телефона',
        datePickup: 'Дата забора посылки',
        singleWeight: 'Вес одной посылки (кг)',
        singleVolume: 'Объем одной посылки (метр куб)',
        totalWeight: 'Общий вес посылки (кг)',
        totalVolume: 'Общий объем посылки (метр куб)',
        save: 'Сохранить',
        daysOfTheWeek: {
          'monday': 'Пн',
          'tuesday': 'Вт',
          'wednesday': 'Ср',
          'thursday': 'Чт',
          'friday': 'Пт',
          'saturday': 'Сб',
          'sunday': 'Вс',
        },
        weekend: 'выходной',
      },
      alerts: {
        success: 'Доставка успешно создано',
        info: 'Отсутствует адреса складов',
      },
      btns: {
        save: 'Сохранить',
        cancel: 'Отменить',
      },
    },
  },
};
