export default {
  index: {
    title: 'Оценка карт',
    form: {
      card: {
        label: {
          pan: 'Номер карты',
          exp: 'Cрок',
        },
        buttons: {
          next: 'Продолжить',
        },
      },
      otp: {
        title: 'Введите код',
        label: {
          code: 'Код подтверждения',
        },
        buttons: {
          next: 'Продолжить',
          back: 'Назад',
        },
      },
    },
    detail: {
      card: {
        title: 'Реквизиты карты',
        avgIncome: 'Средний доход за 12 месяцев',
        button: 'Проверить другую карту',
      },
      scoring: {
        title: 'Оценка',
        transactions: {
          title: 'Транзакции за',
          amount: 'Сумма',
          count: 'Количество',
          selectPeriod: 'Выберите период',
        },
      },
      monitoring: {
        title: 'Мониторинг',
        label: 'Период',
        button: 'Запросить',
        empty: 'За выбранный период данные не найдены',
      },
    },
  },
};
