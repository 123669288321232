export default {
  index: {
    title: 'Xodimlar',
    placeholders: {
      store: "Do'kon",
    },
    allStores: "Barcha do'konlar",
    headers: {
      id: 'ID',
      name: 'Ismi',
      acquiring: 'Ekvayring',
      qrcode: 'QR kod',
      store: "Do'kon",
    },
    btns: {
      addEmployee: "Xodim qo'shish",
    },
  },
  show: {
    goBack: 'Orqaga',
    changePhone: 'Telefonni almashtirish',
    changePhoneModal: {
      title: 'Telefonni almashtirish',
      labels: {
        code: 'Kod',
        phoneNumber: 'Telefon raqami',
      },
      placeholders: {
        code: 'Qabul qilingan kodni kiriting',
      },
      btns: {
        sendSms: 'SMS yuborish',
        cancel: 'Bekor qilish',
        confirm: 'Tasdiqlash',
      },
    },
    baseInfo: {
      save: 'Saqlash',
      title: "Ma'lumotlar",
      fio: 'FIO',
      phone: 'Telefon',
      partner: 'Hamkor',
      store: "Do'kon",
    },
    permissions: {
      title: 'Kirish ruxsatlarini sozlash',
      merchant_acquiring: 'Ekvayring bilan boshqarish',
      merchant_admin: 'Admin panel',
      merchant_alifnasiya: 'Alifnasiya bilan boshqarish',
      merchant_alifshop: 'Alifshop bilan boshqarish',
      merchant_applications: 'Arizalar bilan boshqarish',
      merchant_deliveries: 'Yetkazib berish bilan boshqarish',
      merchant_manager: 'Xodimlar bilan boshqarish',
      merchant_moderation_goods: 'Tovarlarning moderatsiyasini boshqarish',
      merchant_scoring_card: 'Kartalarni skoring qilishni boshqarish',
      merchant_upload_goods: 'Tovarlarni yuklash bilan boshqarish',
    },
  },
  modal: {
    titles: {
      qrcode: 'Xodimning QR kodi',
    },
    btns: {
      print: 'Chop etish',
      download: 'Yuklab olish',
    },
  },
  create: {
    title: "Yangi xodim qo'shish",
    search: {
      label: 'Telefon raqam',
      placeholder: '(00) 000-00-00',
    },
    store: {
      label: "Do'kon",
      placeholder: "Do'konlardan birini tanlang",
    },
    noUser: 'Foydalanuvchi topilmadi',
    add: "Qo'shish",
    userIsEmployee: "Ushbu raqamli foydalanuvchi allaqachon merchantlarning biriga xodim bo'lib qo'shilgan",
    createEmployee: {
      btns: {
        sendSms: 'SMS yuborish',
        addEmployee: 'Xodim qo‘shish',
      },
      placeholders: {
        code: 'Qabul qilingan kodni kiriting',
        name: 'Ismingizni kiriting',
        store: 'Do‘konni tanlang',
      },
      labels: {
        phone: 'Telefon raqami',
        code: 'Kod',
        name: 'Ism',
        store: 'Do‘kon',
      },
    },
  },
};
