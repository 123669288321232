import i18n from '@/plugins/i18n';
import { full } from '../../common/constants/months';

const generateYearOptions = month => {
  let start = 2020;
  const end = new Date().getFullYear();
  const result = [];
  while (start <= end) {
    result.push({ title: start, value: start + '-' + month + '-' + '01' });
    start++;
  }
  return result;
};

export const getters = {
  tabs: () => [
    { title: i18n.global.t('analytics.tabs.monthly'), value: 'monthly' },
    { title: i18n.global.t('analytics.tabs.yearly'), value: 'yearly' },
  ],
  limitOptions: () => [
    { title: '10', value: 10 },
    { title: '20', value: 20 },
    { title: '30', value: 30 },
    { title: '40', value: 40 },
    { title: '50', value: 50 },
  ],
  ageOptions: () => [
    { title: i18n.global.t('analytics.select.options.all'), value: null },
    { title: i18n.global.t('analytics.select.options.ages[0]'), value: '18-25' },
    { title: i18n.global.t('analytics.select.options.ages[1]'), value: '26-32' },
    { title: i18n.global.t('analytics.select.options.ages[2]'), value: '33-40' },
    { title: i18n.global.t('analytics.select.options.ages[3]'), value: '41-55' },
    { title: i18n.global.t('analytics.select.options.ages[4]'), value: '56-more' },
  ],
  yearOptions: () => {
    return state => {
      const requestDate = state.query?.request_date;
      const month = requestDate?.split?.('-')?.[1] ?? '01';
      return [{ title: i18n.global.t('analytics.select.options.all'), value: null }].concat(generateYearOptions(month));
    };
  },
  monthOptions: () => {
    return (state, locale) => {
      const localeMonths = full[locale];
      const requestDate = state.query?.request_date;
      return localeMonths?.reduce?.(
        (result, item, index) => {
          const date =
            (requestDate?.split?.('-')?.[0] ?? new Date().getFullYear()) +
            '-' +
            index?.toString().padStart(2, '0') +
            '-' +
            '01';
          if (index)
            result.push({
              title: item,
              value: date,
            });
          return result;
        },
        [{ title: i18n.global.t('analytics.select.options.all'), value: null }]
      );
    };
  },
};
