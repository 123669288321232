export default {
  pages: {
    home: {
      title: 'Asosiy sahifa',
      quickLinks: {
        calculator: {
          title: 'Kalkulyator',
        },
        faq: {
          title: 'FAQ',
        },
        knowledgeBase: {
          title: 'Bilimlar bazasi',
        },
        techSupport: {
          title: 'Texnik yordamga murojaat qilish',
        },
      },
      clientsTable: {
        secondaryTitle: 'Bugun uchun',
      },
      applicationsTable: {
        secondaryTitle: 'Bugun uchun',
      },
    },
  },
};
