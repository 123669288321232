import mainConnect, { generateError, setToken } from '@/services';
import { app as Vue } from '@/main';

export default {
  /**
   * Метод авторизации
   * @param {{phone: String}} params
   * @param {{password: String}} params
   * @param {{remember_me: Boolean}} params
   * @return {Promise}
   * */
  loginPassword(params) {
    return mainConnect.post('auth/login-password', { ...params, application: 'MERCHANT' }).catch(generateError);
  },

  /**
   * Метод обновления токена
   * @return {Promise}
   * */
  refreshToken() {
    return mainConnect.post('auth/refresh', { application: 'MERCHANT' }).catch(generateError);
  },

  /**
   * Метод получения кода подтверждения по СМС ( восстановление пароля )
   * @param {{phone: String}} params
   * @return {Promise}
   * */
  requestResetPassword(params) {
    return mainConnect.post('auth/request-reset', params).catch(generateError);
  },

  /**
   * Метод подтверждения кода из СМС ( восстановление пароля )
   * @param {{phone: String}} params
   * @param {{otp: String}} params
   * @return {Promise}
   * */
  verifyResetPassword(params) {
    return mainConnect.post('auth/verify-reset', params).catch(generateError);
  },

  /**
   * Метод отправки нового пароля ( восстановление пароля )
   * @param {{phone: String}} params
   * @param {{password: String}} params
   * @param {{password_confirmation: String}} params
   * @return {Promise}
   * */
  resetPassword(params) {
    return mainConnect.post('auth/reset', params).catch(generateError);
  },

  /**
   * Метод выхода с удалением токена авторизации из куки
   * @returns {Promise}
   */
  logout() {
    return mainConnect
      .post(`auth/logout`)
      .then(() => {
        if ('$cookies' in Vue.config.globalProperties) Vue.config.globalProperties.$cookies.delete('auth');
        setToken('');
      })
      .catch(generateError);
  },
  /**
   * Метод получения данных авторизованного мерчанта
   * @returns {Promise}
   */
  getUser() {
    return mainConnect.get('auth/user').catch(generateError);
  },
};
