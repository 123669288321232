import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getCashbacksFromMerchantUser } from '../api';
import { useUserStore } from '@/modules/main/user/stores';

const useGetCashbacksFromMerchantUserQuery = ({ type, contractDate, chargedAt, page }) => {
  const userStore = useUserStore();

  const authId = computed(() => userStore.me.id);

  return useQuery({
    queryKey: ['cashbacks', 'list', { authId, type, contractDate, chargedAt, page }],
    async queryFn({ queryKey }) {
      const { authId, type, contractDate, chargedAt, page } = queryKey[2];

      const response = await getCashbacksFromMerchantUser(authId, {
        type,
        contract_date: contractDate,
        charged_at: chargedAt,
        page,
      });

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetCashbacksFromMerchantUserQuery;
