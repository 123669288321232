import { computed } from 'vue';
import { checkType } from '@/plugins/aliftech-ui/utils';

export const useErrors = queryOrMutation => {
  return computed(() => {
    if (!queryOrMutation.isError.value) {
      return null;
    }

    const error = queryOrMutation.error.value;

    if (checkType(error, 'object') && 'errors' in error) {
      return error.errors;
    }

    return null;
  });
};
