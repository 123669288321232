import mainConnect, { generateError } from '@/services';

export const applicationsService = {
  /**
   * Get all applications of merchant
   *
   * @param {Object} params
   * @param {String} params.q - search query
   * @param {Number} params.client_id - client id
   * @param {Number} params.status_id - status id
   * @param {Date} params.date - date (Y-m-d)
   * @param {Number} params.contract_uploaded - contract uploaded
   * @param {String} params.created_from_str - created source
   * @param {Number} params.cancel_reason - cancel reason
   * @param {Number} params.reject_reason - reject reason
   *
   * @returns {Promise<Applications>}
   * @typedef {Object} Applications
   * @property {Number} data[].id - application id
   * @property {Number} data[].amount - application amount
   * @property {Object} data[].application_status - application status
   * @property {Number} data[].application_status.id - application status id
   * @property {String} data[].application_status.key - application status key
   * @property {String} data[].type - application type
   * @property {Number} data[].merchant_engaged_by_id - merchant engaged by id
   * @property {String} data[].merchant_engaged_by_str - merchant engaged by str
   * @property {String} data[].created_from_str - created from str
   * @property {Date} data[].created_at - application created at datetime
   * @property {String} data[].client.name - client name
   * @property {String} data[].client.surname - client surname
   * @property {String} data[].client.patronymic - client patronymic
   * @property {String} data[].client.phone - client phone
   * @property {Number} meta.last_page - last page for pagination
   * @throws {Error}
   */
  getApplications(params) {
    return mainConnect.get('applications/applications', { params }).catch(generateError);
  },

  /**
   * Get all applications of merchant
   *
   * @param {Object} params
   * @param {String} params.q - search query
   * @param {Number} params.client_id - client id
   * @param {Number} params.status_id - status id
   * @param {Date} params.date - date (Y-m-d)
   * @param {Number} params.contract_uploaded - contract uploaded
   * @param {String} params.created_from_str - created source
   * @param {Number} params.cancel_reason - cancel reason
   * @param {Number} params.reject_reason - reject reason
   *
   * @returns {Promise<Applications>}
   * @typedef {Object} Applications
   * @property {Number} data[].id - application id
   * @property {Number} data[].amount - application amount
   * @property {Object} data[].application_status - application status
   * @property {Number} data[].application_status.id - application status id
   * @property {String} data[].application_status.key - application status key
   * @property {String} data[].type - application type
   * @property {Number} data[].merchant_engaged_by_id - merchant engaged by id
   * @property {String} data[].merchant_engaged_by_str - merchant engaged by str
   * @property {String} data[].created_from_str - created from str
   * @property {Date} data[].created_at - application created at datetime
   * @property {String} data[].client.name - client name
   * @property {String} data[].client.surname - client surname
   * @property {String} data[].client.patronymic - client patronymic
   * @property {String} data[].client.phone - client phone
   * @property {Number} meta.last_page - last page for pagination
   * @throws {Error}
   */ getCompletedApplications(params) {
    return mainConnect.get('/applications/applications-completed', { params }).catch(generateError);
  },

  /**
   * Get all applications of merchant
   *
   * @param {Object} params
   * @param {String} params.q - search query
   * @param {Number} params.client_id - client id
   * @param {Number} params.status_id - status id
   * @param {Date} params.date - date (Y-m-d)
   * @param {Number} params.contract_uploaded - contract uploaded
   * @param {String} params.created_from_str - created source
   * @param {Number} params.cancel_reason - cancel reason
   * @param {Number} params.reject_reason - reject reason
   *
   * @returns {Promise<Applications>}
   * @typedef {Object} Applications
   * @property {Number} data[].id - application id
   * @property {Number} data[].amount - application amount
   * @property {Object} data[].application_status - application status
   * @property {Number} data[].application_status.id - application status id
   * @property {String} data[].application_status.key - application status key
   * @property {String} data[].type - application type
   * @property {Number} data[].merchant_engaged_by_id - merchant engaged by id
   * @property {String} data[].merchant_engaged_by_str - merchant engaged by str
   * @property {String} data[].created_from_str - created from str
   * @property {Date} data[].created_at - application created at datetime
   * @property {String} data[].client.name - client name
   * @property {String} data[].client.surname - client surname
   * @property {String} data[].client.patronymic - client patronymic
   * @property {String} data[].client.phone - client phone
   * @property {Number} meta.last_page - last page for pagination
   * @throws {Error}
   */
  getCompletedApplicationsWithSearch(params) {
    return mainConnect.get('/applications/applications-completed-search', { params }).catch(generateError);
  },

  /**
   * Get application by id
   *
   * @param {Number} id - application id
   * @param {Object} params
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   * @property {Number} id - application id
   * @property {Boolean} auto_review - auto review
   * @property {Object} application_status - application status
   * @property {String} application_status.key - application status key
   * @property {Array}  application_items - application items
   * @property {Number} application_items[].id - id
   * @property {Number} application_items[].price - price
   * @property {Number} application_items[].quantity - quantity
   * @property {String} application_items[].good_name - good name
   * @property {String} application_items[].deleted_at - date when good was deleted
   * @property {String} application_items[].good_type_name - good type name
   * @property {String} application_items[].good_type.name - good type name
   * @property {Number} application_items[].good_type.is_markable - is good markable
   * @property {Number} application_items[].good_type.imeis - imeis
   * @property {Number} client_id - application client_id
   * @property {String} client.name - client name
   * @property {String} client.surname - client surname
   * @property {String} client.patronymic - client patronymic
   * @property {String} client.phone - client phone
   * @property {Number} store_id - application store id
   * @property {Number} application_condition_id - application condition id
   * @property {Number} amount - application amount
   * @property {Number} reject_reason - application reject reason
   * @property {Number} cancel_reason - application cancel reason
   * @property {Object} down_payment_amount - down payment amount
   * @property {Number} file_id - application contract file id
   * @property {String} created_from_str - created from str
   * @property {Number} delivery.id - delivery id
   * @property {Number} delivery.type - delivery type
   * @property {Number} delivery.delivery_status_id - delivery status id
   * @property {String} delivery.address - delivery address
   * @property {String} delivery.nearby - delivery nearby
   * @property {String} delivery.note - delivery note
   *
   * @throws {Error}
   */
  getApplication(id, params = {}) {
    return mainConnect.get(`applications/applications/${id}`, { params: { ...params } }).catch(generateError);
  },

  /**
   * Engage application
   *
   * @param {Number} id - application id
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   * @property {Number} merchant_engaged_by_id - merchant engaged by id
   * @property {String} merchant_engaged_by_str - merchant engaged by str
   *
   * @throws {Error}
   */
  engage(id) {
    return mainConnect.put(`applications/applications/${id}/engage`).catch(generateError);
  },

  /**
   * Request to approval
   *
   * @param {Number} id - application id
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   * @property {Object} application_status - application status
   * @property {Number} application_status.id - application status id
   * @property {String} application_status.key - application status key
   * @property {String} status_updated_at - status updated at
   * @property {String} updated_at - updated at
   * @property {Number} updated_by_id - updated by id
   * @property {String} updated_from_str - updated by str
   * @throws {Error}
   */
  requestApproval(id) {
    return mainConnect.post(`applications/applications/${id}/request_approval`).catch(generateError);
  },

  /**
   * Cancel request to approval
   *
   * @param {Number} id - application id
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   * @property {Object} application_status - application status
   * @property {Number} application_status.id - application status id
   * @property {String} application_status.key - application status key
   * @property {String} status_updated_at - status updated at
   * @property {String} updated_at - updated at
   * @property {Number} updated_by_id - updated by id
   * @property {String} updated_from_str - updated by str
   * @throws {Error}
   */
  cancelRequestApproval(id) {
    return mainConnect.post(`applications/applications/${id}/cancel_request_approval`).catch(generateError);
  },

  /**
   * Get alifshop items
   *
   * @param {Object} params
   * @param {String} params.q - search query
   *
   * @returns {Promise<AlifshopItems>}
   * @typedef {Object} AlifshopItems
   * @property {Number} data[].id - id
   * @property {String} data[].name - name
   * @property {String} data[].category - category
   * @property {Number} data[].price - price
   * @property {Number} data[].quantity - quantity
   * @property {String} data[].image_path - image path
   *
   * @throws {Error}
   */
  getAlifshopItems(params) {
    return mainConnect.get(`applications/alifshop`, { params }).catch(generateError);
  },

  /**
   * Save item
   *
   * @param {Number} id - id
   * @param {Object} params
   * @param {String} params.good_name - good name
   * @param {String} params.good_type_name - good type name
   * @param {String} params.ikpu - ikpu
   * @param {String} params.sku - sku
   * @param {Number} params.price - price
   * @param {Number} params.quantity - quantity
   * @param {Number} params.is_markable - good type is markable
   *
   * @returns {Promise<Item>}
   * @typedef {Object} Item
   * @property {Number} id - id
   * @property {Number} price - price
   * @property {Number} quantity - quantity
   * @property {String} good_name - good name
   * @property {String} good_type.name - good type name
   * @property {Number} good_type.is_markable - good type is markable

   *
   * @throws {Error}
   */
  saveItem(id, params) {
    return mainConnect
      .post(`applications/applications/${id}/application_items`, params)
      .then(res => {
        return { ...res, data: { ...(res?.data || {}), quantity: res?.data?.quantity || 1 } };
      })
      .catch(generateError);
  },

  /**
   * Delete item
   *
   * @param {Number} applicationId - applicationId
   * @param {Number} itemId - itemId
   *
   * @returns {Promise}
   *
   * @throws {Error}
   */
  deleteItem(applicationId, itemId) {
    return mainConnect
      .delete(`applications/applications/${applicationId}/application_items/${itemId}`)
      .catch(generateError);
  },
  /**
   * Mark item
   *
   * @param {Object} params
   * @param {String} params.marking - good's mark
   * @param {Number} applicationId - applicationId
   * @param {Number} itemId - itemId
   *
   *
   * @returns {Promise}
   *
   * @throws {Error}
   */
  markItem(applicationId, itemId, params) {
    return mainConnect
      .put(`applications/applications/${applicationId}/application_items/${itemId}/mark`, params)
      .catch(generateError);
  },
  /**
   * upload imei
   *
   * @param {Object} params
   * @param {Number} applicationId - applicationId
   * @param {Number} itemId - itemId
   *
   *
   * @returns {Promise}
   *
   * @throws {Error}
   */
  uploadImei(applicationId, itemId, params) {
    return mainConnect
      .post(`applications/applications/${applicationId}/application_items/${itemId}/imei`, params)
      .catch(generateError);
  },
  /**
   * Delete imei
   *
   * @param {Object} params
   *
   *
   * @returns {Promise}
   *
   * @throws {Error}
   */
  deleteImei(params) {
    return mainConnect.post(`applications/applications/imei`, params).catch(generateError);
  },

  /**
   * Get all comment for application
   *
   * @param {Object} params
   * @param {String} params.commentable_type - commentable type
   * @param {Number} params.commentable_id - application id
   *
   * @returns {Promise<Comments>}
   * @typedef {Object} Comments
   * @property {Number} data[].id - comment id
   * @property {String} data[].body - comment body
   * @property {Date} data[].updated_at - comment updated at datetime
   * @property {Number} meta.last_page - last page
   * @property {Number} meta.current_page - current page
   * @property {Number} meta.total - total
   * @throws {Error}
   */
  getComments(params) {
    return mainConnect.get('/applications/applications/comments', { params }).catch(generateError);
  },

  /**
   * Update condition
   *
   * @param {Number} applicationId - application id
   * @param {Number} conditionId - condition id
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   *
   * @throws {Error}
   */
  updateCondition(applicationId, conditionId) {
    return mainConnect
      .post(`applications/applications/${applicationId}/update_condition`, {
        condition_id: conditionId,
      })
      .catch(generateError);
  },

  /**
   * Switch store
   *
   * @param {Number} applicationId - application id
   * @param {Number} storeId - store id
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   *
   * @throws {Error}
   */
  switchStore(applicationId, storeId) {
    return mainConnect
      .post(`/applications/applications/${applicationId}/switch-store`, { store_id: storeId })
      .catch(generateError);
  },

  /**
   * Cancel application
   *
   * @param {Number} applicationId - application id
   * @param {Object} params
   * @param {Number} params.cancel_reason - cancel_reason
   *
   * @returns {Promise}
   * @property {String} message - message
   *
   * @throws {Error}
   */
  cancelApplication(applicationId, params) {
    return mainConnect.post(`applications/applications/${applicationId}/cancel`, params).catch(generateError);
  },

  /**
   * Store application
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   * @param {Number} params.condition_id - condition id
   * @param {Number} params.prepayment - prepayment
   * @param {String} params.first_payment_date - first payment date
   * @param {String} params.type - type (in_store || out_store)
   * @param {Array} params.items - application items
   *
   * @returns {Promise<Application>}
   * @typedef {Object} Application
   * @property {Number} id - application id
   * @throws {Error}
   */
  storeFull(params) {
    return mainConnect.post(`applications/applications/store-full`, params).catch(generateError);
  },

  /**
   * Get new contracts count
   *
   * @param {Object} params
   *
   * @returns {Promise<NewContractsCount>}
   * @typedef {Object} NewContractsCount
   * @property {Number} count - count
   *
   * @throws {Error}
   */
  getNewContractsCount(params) {
    return mainConnect.get('/applications/counters/not-uploaded-acts', { params }).catch(generateError);
  },

  /**
   * Create hold down Payment
   *
   * @param {Number} applicationId - application id
   * @param {Object} params
   * @param {Number} params.amount - amount
   *
   * @returns {Promise<HoldDownPayment>}
   * @typedef {Object} HoldDownPayment
   * @property {Number} id - id
   * @property {Number} amount - amount
   *
   * @throws {Error}
   */
  createHoldDownPayment(applicationId, params) {
    return mainConnect
      .post(`/applications/applications/${applicationId}/hold-down-payment`, params)
      .catch(generateError);
  },

  /**
   * Cancel hold down Payment
   *
   * @param {Number} holdId - hold id
   *
   * @returns {Promise<HoldDownPayment>}
   * @typedef {Object} HoldDownPayment
   *
   * @throws {Error}
   */
  cancelHoldDownPayment(holdId) {
    return mainConnect.post(`/applications/down-payment-holds/${holdId}/cancel`).catch(generateError);
  },

  /**
   * Get signed generated application contract
   * @param {number} applicationId - application ID
   * @param {Object} params
   * @param {String} params.locale - locale [uz || ru]
   * @returns {Promise<Contract>}
   * @typedef {Object} Contract
   * @property {Number} application_id - application id
   * @property {String} file_type_key - application amount
   * @property {Number} id - contract id
   * @property {Number} link - contract link
   * @property {String} mime_type - mime type
   * @property {Number} size - size
   * @throws {Error}
   */

  getSignedContract(applicationId, params) {
    return mainConnect
      .post(`/credits/${applicationId}/bnpl-contract`, params, { responseType: 'blob' })
      .catch(generateError);
  },

  /**
   * Get daily info
   *
   * @returns {Promise<DailyInfo>}
   * @typedef {Object} DailyInfo
   * @property {Number} new_clients - new clients
   * @property {Number} total_number - total number
   * @property {Number} total_sum - total sum
   *
   * @throws {Error}
   */
  getDailyInfo() {
    return mainConnect.get('dashboard/applications_today').catch(generateError);
  },

  /**
   * Get not engaged alifshop applications count
   *
   * @returns {Promise<NotEngagedCount>}
   * @typedef {Object} NotEngagedCount
   * @property {Array} not_engaged_count - not engaged count
   * @property {String} not_engaged_count[].name - name
   * @property {Number} not_engaged_count[].count - count
   * @throws {Error}
   */
  notEngagedCount() {
    return mainConnect.get('applications/applications/not-engaged-alifshop').catch(generateError);
  },

  /**
   * Get conditions from merchants service
   *
   * @param {Object} params
   * @param {Number} params.post_alifshop - If we need online conditions - 1, else - do not send this parameter
   *
   * @returns {Promise<Conditions>}
   * @typedef {Object} Conditions
   * @property {Number} id - condition id
   * @property {Number} duration - duration
   * @property {Number} commission - commission
   * @property {Number} discount - discount
   * @property {String} title - title
   * @throws {Error}
   */
  getConditions(params) {
    return mainConnect.get('applications/application_conditions', { params }).catch(generateError);
  },

  /**
   * Sell application items to Alif
   *
   * @param {Number} applicationId - application id
   *
   * @throws {Error}
   */
  sellApplicationItemsToAlif(applicationId) {
    return mainConnect.post(`applications/applications/${applicationId}/purchase`).catch(generateError);
  },

  /**
   * Cancel sell application items to Alif
   *
   * @param {Number} applicationId - application id
   *
   * @throws {Error}
   */
  cancelSellApplicationItemsToAlif(applicationId) {
    return mainConnect.delete(`applications/applications/${applicationId}/purchase`).catch(generateError);
  },
};

export default applicationsService;
