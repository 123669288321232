import authService from '@/modules/main/auth/services/auth.services';
import obserblePanda from './obserblePanda';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { tokenDecryption } from '@/utils/filters';
import { app } from '../main';
import { setToken } from '.';
import { app as Vue } from '@/main';
import { tokenEncoding } from '../utils/filters';

const pandaCache = new InMemoryCache();

let requestPromise = null;

const link = createHttpLink({
  uri: process.env.VUE_APP_PANDA_GRAPHQL,
  fetch: (uri, options) => {
    options.headers = getHeaders();
    return fetch(uri, options);
  },
});

const errorLink = onError(err => {
  if (err && err.networkError.statusCode === 401) {
    return obserblePanda(
      refreshToken(err).then(token => {
        err.operation.setContext({
          headers: {
            Authorization: token,
          },
        });
        return err.forward(err.operation);
      })
    ).flatMap(() => err.forward(err.operation));
  }
});

export const pandaClient = new ApolloClient({
  link: errorLink.concat(link),
  cache: pandaCache,
});

function getHeaders() {
  const headers = {};
  const token = app.config.globalProperties.$cookies.get('auth');
  if (token) {
    headers['Authorization'] = tokenDecryption(token);
  }
  headers['Content-Type'] = 'application/json';
  return headers;
}

const refreshToken = async () => {
  let response = null;

  try {
    if (requestPromise) {
      response = await requestPromise;
    } else {
      requestPromise = authService.refreshToken();
      response = await requestPromise;
    }
  } finally {
    requestPromise = null;
  }

  if (response) {
    const token = response.data?.token_type + ' ' + response.data?.access_token;

    setToken(token);
    Vue.config.globalProperties.$cookies.set('auth', tokenEncoding(token), { expire: '1m' });

    return token;
  }

  return null;
};

pandaClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};
