export default {
  required: "Ushbu maydon to'ldirilishi shart",
  minLength: 'Minimal ruxsat etilgan belgilar uzunligi - {min}',
  maxLength: 'Maksimal ruxsat etilgan belgilar uzunligi - {max}',
  length: 'Ruxsat etilgan belgilar uzunligi - {len}',
  minValue: 'Minimal ruxsat etilgan qiymat - {min}',
  maxValue: 'Maksimal ruxsat etilgan qiymat - {max}',
  between: "Qiymat {min} va {max} orasida bo'ishi kerak",
  alpha: 'Faqat harflar ishlatish mumkin',
  alphaNum: 'Faqat harflar va sonlar ishlatish mumkin',
  numeric: 'Faqat sonlar ishlatish mumkin',
  integer: 'Faqat butun sonlar ishlatish mumkin',
  decimal: 'Faqat kasr sonlar ishlatish mumkin',
  email: "Iltimos, to'g'ri e-mail manzilini kiriting",
  ipAddress: 'Yaroqli IP-manzilni kiriting',
  macAddress: 'Yaroqli MAC-manzilni kiriting',
  url: 'Yaroqli veb-sayt manzilini kiriting',
  sameAs: "Kiritilgan qiymat '{otherName}' maydoni bilan bir-xil bo'lishi kerak",
  isUnique: 'Kiritilgan qiymat takrorlanmasligi lozim',
  isDMYDateFormat: 'kk-oo-yyyy formatiga mos emas',
  uppercase: 'Lotin alifbosidan kamida 1 dona bosh harf lozim [A-Z]',
  lowercase: 'Lotin alifbosidan kamida 1 dona harf lozim [a-z]',
  number: 'Kamida 1 dona son lozim',
  specSymbol: "Kamida 1 dona qo'shimcha belgi",
  itemMarking: "Belgi noto'g'ri to'ldirilgan",
};
