<template>
  <at-alert v-if="hasError" type="danger" dismissible>
    <div class="flex flex-col gap-y-2">
      <span v-if="error.message" class="whitespace-normal">{{ error.message }}</span>

      <ul v-if="showValidationErrors && error.errors">
        <li v-for="(errorMessage, field) in error.errors" :key="field">{{ field }}: {{ errorMessage }}</li>
      </ul>
    </div>
  </at-alert>
</template>

<script setup>
  import { computed } from 'vue';
  import AtAlert from '@/plugins/aliftech-ui/components/AtAlert/AtAlert';

  const props = defineProps({
    error: {
      type: Object,
      default: null,
    },
    showValidationErrors: {
      type: Boolean,
      default: true,
    },
  });

  const hasError = computed(() => {
    return props.error && (props.error.message || (props.showValidationErrors && props.error.errors));
  });
</script>
