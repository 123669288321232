import { useRouter } from 'vue-router';

const useBack = () => {
  const router = useRouter();

  return () => {
    router.go(-1);
  };
};

export default useBack;
