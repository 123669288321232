export default {
  create: {
    showContract: 'Dalolatnoma',
    btn: "So'rovni o'chirish",
    items: {
      title: 'Qaytarish tovarini tanlang',
    },
    title: 'Yangi qaytarish',
    confirm: {
      button: 'Rasmiylashtirish',
      header: 'Sizning imzoingiz va/yoki mijoz imzosisiz qaytarish so‘rovi bekor qilinadi',
      title: 'Qaytarishni rasmiylashtirish',
      modal: {
        title: 'Ushbu tovarni qaytarishni istaysizmi ?',
        btns: {
          confirm: 'Tasdiqlash',
          cancel: 'Bekor qilish',
        },
      },
    },
    instruction: {
      title: "Ko'rsatmalar",
      list: ['Qaytarish sababini kiriting', 'Tovarlarni tanlang'],
    },
    note: {
      title: 'Qaytarish sababi',
      noItem: 'Tovar qolmadi',
      wrongItem: "Noto'g'ri tovar kiritilgan",
      defectiveItem: 'Buzuq tovar',
      clientRefused: 'Mijoz tovarni rad etdi',
      goodsWereNotDelivered: 'Tovar yetkazib berilmadi',
      other: 'Boshqa',
    },
  },
  index: {
    title: 'Qaytarishlar',
    headers: {
      client: 'Mijoz',
      status: 'Holati',
      type: 'Turi',
      date: 'Sana',
      reason: 'Sabab',
    },
  },
  items: {
    title: 'Qaytarish tovarlari',
    headers: {
      name: 'Nomi',
      price: 'Narxi',
    },
  },
  info: {
    title: "Qaytarish haqida ma'lumot",
    clientName: 'Mijoz F.I.Sh.',
    clientPhone: 'Mijoz raqami',
    date: 'Yuborilgan sanasi',
    note: 'Qaytarish sababi',
  },
  photos: {
    title: 'Tovar rasmi',
    alert: 'Tovar rasmi mavjud emas',
  },
  cancelReason: {
    title: 'Qaytarish rad etish sababi',
  },
  rejectType: {
    full: 'Butun',
    partial: 'Qisman',
  },
  btns: {
    goToApplication: "Arizaga o'tish",
    reject: 'Rad etish',
    approve: 'Tasdiqlash',
  },
  reject: {
    modal: {
      title: "Rad etish sababini ko'rsating",
      btns: {
        cancel: 'Bekor qilish',
        reject: 'Tasdiqlash',
      },
    },
    toast: {
      reject: 'Qaytarish rad etildi!',
      approve: 'Qaytarish tasdiqlandi!',
    },
  },
};
