export default {
  index: {
    title: 'Mijozlar',
    headers: {
      client: 'Mijoz',
      clientNumber: 'Mijoz raqami',
      status: 'Holat',
      date: 'Sana',
    },
    addClient: "Mijoz qo'shish",
    searchParams: {
      name: "Ism bo'yicha",
      passport: "Pasport bo'yicha",
      phone: "Telefon bo'yicha",
      pinfl: "PINFL bo'yicha",
      clientId: "Mijoz ID bo'yicha",
      applicationId: "Ariza ID bo'yicha",
    },
    useSearchToFindAzoClientAlert: "A'zo mijozlarni topish uchun qidiruvdan foydalaning",
  },
  applications: {
    headers: {
      sum: 'Miqdori',
      createdDate: 'Yaratilgan sana',
      applicationType: 'Ariza turi',
    },
    inStore: {
      title: "Yangi ariza (do'kondan)",
      body: "Agar mijoz do'konda bo'lsa",
      type: "Do'kondan",
    },
    outOfStore: {
      title: 'Yangi ariza (masofadan)',
      body: "Agar mijoz do'konda bo'lmasa",
      type: 'Masofadan',
    },
    more: 'Batafsil',
    create: 'Yaratish',
    confirmCreateApplicationModal: {
      title: 'Yangi ariza?',
      body:
        "Sizga aytib o'tmoqchimizki, siz ushbu mijozga {applicationsCreated} ta ariza yubordingiz. {attemptsLeft} ta urinish qoldi!",
      btns: {
        cancel: 'Bekor qilish',
        confirm: 'Davom ettirish',
      },
    },
  },
  cbAgreement: {
    title: "Mijoz roziligi - o'zi to'g'risidagi kredit hisobotini taqdim etish uchun jismoniy shaxslarning roziligi",
    header:
      "O'z. Res. «Kredit axboroti almashinuvi to'g'risida» gi Qonunining 19-chi moddasi va" +
      " O'z. Res. «Shaxsiy ma'lumotlar to'g'risida» gi Qonunining 18-chi moddasiga ko'ra Qarz oluvchi:",
    body:
      'Men, __________ manzilida istiqomat qiluvchi, __________ ' +
      'tomonidan berilgan pasport raqami egasi, shu bilan ' +
      "men «ALIF MOLIYA» MChJ kredit tashkilotidan o'zim haqimda kredit hisobotini olishga, shuningdek, o'zim " +
      "tarafdor bo'lgan shartnomani tuzish va bajarish choralarini ko'rish uchun shaxsiy " +
      "ma'lumotlarimni taqdim qilishga va qayta ishlanishiga roziman.",
    from: "Mijozning nomidan va ko'rsatmasiga binoan",
    note: "(Ishonchli vakilning pasport bo'yicha F.I.SH, agar harakatlar ishonchnoma asosida yuritilsa)",
    footer: '__________ sanada qayd etilgan № __________ raqamli ishonchnomasi asosida',
    clientAgreement: 'Mijoz roziligi __________',
    receivedEmployee: 'Rozilik oldim:',
    confirmation: 'Rozilikni tasdiqlash',
    confirm: 'Tasdiqlash',
  },
  show: {
    limit: {
      request: 'Limit olish',
    },
    tabs: {
      requirements: 'Talablar',
      applications: 'Arizalar',
    },
    notifications: {
      statusChanged: "Mijoz holati o'zgardi",
    },
  },
  requirements: {
    requestReview: {
      desc: 'Mijozni identifikatsiya qilish uchun yuboring',
      btn: 'Yuborish',
    },
    updatePassportSuggestion: {
      desc: "Mijoz pasportini o'zgartirdimi? Bu yerda maʼlumotlaringizni yangilashingiz mumkin",
      btns: {
        update: 'Yangilash',
      },
    },
    reject: {
      title: 'Rad etish sababi',
    },
    fix: {
      title: 'Tuzatish sababi',
    },
    passport: {
      title: 'Pasport',
      description: 'Pasport rasmini yuklang',
      info: "Barcha pasport ma'lumotlarini avtomatik ravishda to'ldirish uchun pasport/id karta rasmini yuklang",
      titles: {
        passport: 'Pasport',
        id: 'ID karta',
      },
      label: {
        passport: 'Pasport rasmi',
        idBack: 'ID karta orqa tomoni rasmi',
        idFront: 'ID karta old tomoni rasmi',
      },
      toggle: "Ma'lumotlarni qo'lda to'ldirish",
      uploadPassport: 'Pasportni yuklang',
      ocr: 'Rasmdan aniqlash',
      save: 'Saqlash',
      type: {
        label: 'Shaxsni tasdiqlovchi hujjat turi',
        placeholder: 'Hujjat turini tanlang',
        options: {
          passport: 'Pasport',
          id: 'ID',
        },
      },
      pinfl: {
        label: 'JSHSHIR',
        helper: 'Pasport raqamidagi oxirgi 14 belgilar',
        placeholder: 'JSHSHIRni kiriting',
      },
      passport_id: {
        label: 'Seriya va raqam',
        placeholder: 'Pasport seriyasi va raqamini kiriting',
      },
      surname: {
        label: 'Familiya',
        placeholder: 'Familiyani kiriting',
      },
      patronymic: {
        label: 'Sharif',
        placeholder: 'Sharifni kiriting',
      },
      name: {
        label: 'Ism',
        placeholder: 'Ismni kiriting',
      },
      gender: {
        label: 'Jins',
        placeholder: 'Jinsni tanlang',
        options: {
          male: 'Erkak',
          female: 'Ayol',
        },
      },
      birth_date: {
        label: "Tug'ilgan sana",
        placeholder: 'yil-oy-kun',
      },
      address: {
        label: "Ro'yxatdan o'tgan manzil",
        placeholder: 'Manzilni kiriting',
      },
      citizenship: {
        label: 'Fuqarolik',
        placeholder: 'Fuqarolikni kiriting',
      },
      birthplace: {
        label: "Tug'ilgan joy",
        placeholder: "Tug'ilgan joyni kirting",
      },
      issuedBy: {
        label: 'Kim tomondan berilgan',
        placeholder: 'Pasport kim tomondan berilgan',
      },
      issuedAt: {
        label: 'Berilgan sana',
        placeholder: 'yil-oy-kun',
      },
      expiresAt: {
        label: 'Amal qilish muddati',
        placeholder: 'yil-oy-kun',
      },
      region: {
        label: 'Viloyat',
        placeholder: 'Viloyatni tanlang',
      },
    },
    card: {
      title: 'Karta',
      description: "Kartani qo'shing",
      active: 'Faol',
      notActive: 'Faol emas',
      checked: 'Tekshirildi {date}',
      notChecked: 'Tekshirilmagan',
      number: {
        label: 'Karta raqami',
      },
      cardValidityPeriod: {
        label: 'Karta muddati',
      },
      successSendCode: 'Tasdiqlash kodi quyidagi raqamga yuborildi',
      smsNotificationsAreTiedToAnotherPhone: 'SMS-xabarnoma boshqa raqamga ulangan {phone}',
      informClientCardIsTiedToAnotherPhone: 'Karta boshqa telefon raqamiga ulanganligi haqida xaridorga xabar bering',
      confirmationCode: {
        label: 'Tasdiqlash kodi',
      },
      wrongCardName: "Asosiy Kartadagi ism mijoz ismi bilan bir xil bo'lishi kerak",
      smsIsSentToNumber: '{phone} raqamiga SMS xabar yuborildi.',
      clientFIOShouldMatchCardOwnerFIO: "Asosiy kartadagi to'liq ism mijozning to'liq ismiga mos kelishi kerak",
      whySmsNotComing: 'Nega SMS kelmayapti?',
      popup: {
        title: 'Tekshirish uchun nima qilishim kerak?',
        steps: [
          'Karta ulangan telefon raqamini tekshiring va raqam SMS kutilayotgan raqamga mos kelishiga ishonch hosil qiling.',
          'Telefoningiz balansini tekshiring.',
          "Telefondagi xotirani tekshiring (agar xotirasi to'lgan bo'lsa, uni tozalang).",
          "Telefonni o'chirib, yana yoqib ko'ring.",
          "Alif Mobi spamga qo'shilgan yoki yo'qligini tekshiring.",
          'Agar yuqorida aytilganlarning hech biri yordam bermasa, {technicalSupport} xizmatiga  murojaat qiling.',
        ],
        technicalSupport: 'texnik yordam',
      },
      orderCall: "Qo'ng'iroqni buyurtma qilish",
      confirm: 'Tasdiqlash',
      add: "Boshqa kartani qo'shish",
      cancel: 'Bekor qilish',
      save: 'Saqlash',
      noAttachedCardsAlert: 'Mijozda hali bog‘langan kartalari yo‘q',
    },
    additionalDocs: {
      title: "Qo'shimcha rasmlar",
      description: "Qo'shimcha rasmlarni yuklang",
      fillPassport: "Iltimos qo'shimcha rasmlarni yuklashdan avval, pasport ma'lumotlarini to'ldiring",
      maxFileSize: 'Maksimal fayl hajmi - 5mb',
      commonFiles: 'Boshqa fayllar',
      delete: "O'chirish",
      addCommonFile: "Qo'shish",
      passportWithFace: 'Pasport mijoz yuzi yonida',
      idWithFace: 'ID karta mijoz yuzi yonida',
      common: 'Fayl',
      noCommonFiles: "Boshqa fayllar yo'q",
      taxiDailyIncome: 'Taksi haydovchisining kunlik daromadi',
      taxiWeeklyIncome: 'Taksi haydovchisining haftalik daromadi',
      taxiMonthlyIncome: 'Taksi haydovchisining oylik daromadi',
      militaryCertificate: 'Guvohnoma',
      moneyTransferRecipient: "Pul o'tkazmalarini oluvchi",
    },
    questionnaire: {
      title: "Shaxsiy ma'lumotlar",
      description: 'Anketa',
      activityArea: {
        label: 'Faoliyat sohasi',
        placeholder: 'Faoliyat sohasi tanlang',
      },
      profession: {
        label: 'Lavozim',
        placeholder: 'Lavozimni kiriting',
      },
      preferredLanguage: {
        title: 'Tanlangan til',
        uzbek: "O'zbek tili",
        russian: 'Rus tili',
      },
      car: {
        title: 'Avtomobil',
        do: 'Bor',
        dont: "Yo'q",
      },
      save: 'Saqlash',
    },
    phones: {
      title: 'Kontaktlar',
      description: "Qo'shimcha kontaktlar",
      main: 'Asosiy raqam',
      number: {
        label: 'Telefon raqami',
      },
      relationship: {
        label: 'Kim? Birodar, singil ...',
      },
      owner: {
        label: 'Raqam egasining ismi',
      },
      add: "Kontakt qo'shish",
      save: 'Saqlash',
      added: 'Kontaktlar muvaffaqiyatli saqlandi',
      singleAdded: 'Kontakt muvaffaqiyatli saqlandi',
    },
    addresses: {
      title: 'Manzillar ',
      residence: 'Propiska',
      address: {
        label: 'Manzil',
      },
      landmark: {
        label: "Mo'ljal",
      },
      cancel: 'Bekor qilish',
      save: 'Saqlash',
      add: "Manzil qo'shish",
      added: 'Manzil muvaffaqiyatli saqlandi',
    },
  },
  create: {
    title: 'Yangi mijoz yaratish',
    passport_id: {
      label: 'Seriya va raqam',
      placeholder: 'AA1234567',
    },
    phone: {
      label: 'Telefon raqam',
      placeholder: '99 999 99 99',
    },
    birth_date: {
      label: "Tug'ilgan sana",
      placeholder: 'kk-oo-yyyy',
    },
    code: {
      label: 'Kod',
      placeholder: 'Qabul qilingan kodni kiriting',
    },
    sendSms: 'SMS yuborish',
    status: {
      title: 'Yangi mijoz',
      body: [
        '- Mijoz mavjudligini tekshirish uchun telefon raqamini kiriting',
        "- Raqam 9 ta raqamdan iborat bo'lishi kerak",
      ],
    },
    clientExists: {
      title: 'Ushbu raqamga ega mijoz mavjud',
      instructions: 'Iltimos, yordam uchun xodimlarimizga murojaat qiling',
      btn: "Sahifaga o'tish",
    },
    passportExists: {
      title: "Endi mijoz raqamini o'zgartirish xavfsizroq bo'ldi",
      instructions:
        '"alif merchant" ilovasida mijozning telefon raqamini oʻzgartirish uchun quyidagi amallarni bajaring:',
      request: "Iltimos, soxta pasport ma'lumotlari bilan takroriy mijoz yaratmang",
      step1: 'Ilovani QR-kod orqali yoki App Store yoki Play Market orqali yuklab oling',
      step2: `Asosiy sahifadagi "+" tugmasini bosib mijozni qo'shing`,
      step3: "MyID yordamida raqamni o'zgartiring",
      close: 'Tushunarli',
    },
    confirm: 'Tasdiqlash',
    cancel: 'Bekor qilish',
    clientAlreadyExistsModal: {
      title: 'Mijoz ushbu telefon raqam bilan mavjud',
      body: "Davom etish uchun mijozning pasport ma'lumotlarini yangilang",
      btns: {
        cancel: 'Bekor qilish',
        updatePassport: "Pasport ma'lumotlarini yangilash",
      },
    },
  },
  scoring: {
    reject: {
      title: 'Rad etildi',
      header: 'Mijoz {name} rad etildi',
      goToClient: "Mijoz sahifasiga o'tish",
      reasons: {
        has_mib_delays: 'MIB-da qarzdorlik bor',
        zero_incomes: 'Oxirgi 3 oy kartada tushumlar yoq',
        current_delays: 'Aktiv qarzdorlik bor',
        low_incomes: 'Kartada tushumlar 400 ming somdan kam',
        credit_status: 'Alifshop-da muammoli qarzdorlik bor',
        more_than_monthly_payment: "Qarzdorlikni oylik to'lovi katta",
      },
    },
  },
  updatePassport: {
    btns: {
      save: 'Saqlash',
      cancel: 'Bekor qilish',
    },
    description: 'Pasport rasmini yuklang',
    titles: {
      passport: 'Pasport',
      id: 'ID karta',
    },
    label: {
      passport: 'Pasport rasmi',
      idBack: 'ID karta orqa tomoni rasmi',
      idFront: 'ID karta old tomoni rasmi',
      passportPhotoFace: "Orqa fonda yuzi bo'lgan pasport fotosurati",
      idPassportPhotoFace: 'Orqa fonda yuzi boʻlgan ID surati',
    },
    uploadPassport: 'Pasportni yuklang',
    save: 'Saqlash',
    type: {
      label: 'Shaxsni tasdiqlovchi hujjat turi',
      options: {
        passport: 'Pasport',
        id: 'ID',
      },
    },
  },
  reminder: {
    title: "Endi mijozni ro'yxatdan o'tkazish jarayoni ancha tezroq va xavfsizroq!",
    subTitle: "Mijozni Alif Merchant ilovasida ro'yxatdan o'tkazish uchun quyidagi bosqichlarni bajaring:",
    steps: {
      first: 'QR kodini skanerlash orqali dasturni yuklab oling',
      second: "Asosiy sahifadagi '+' tugmasini bosib mijozni qo'shing",
      third: "Mijozni MyID orqali ro'yxatdan o'tkazing",
    },
    footer: {
      description: "Iltimos, soxta pasport ma'lumotlari bilan takroriy mijozlar yaratmang",
      button: 'Tushunarli',
    },
  },
};
