export default {
  widgets: {
    problemCaseInfoPanel: {
      title: 'Информация',
      rows: [
        'Ф.И.О клиента',
        'Номер клиента',
        'Товары',
        'Причина',
        'Комментарии',
        'Статус',
        'Дата создания',
        'Дата истечения',
      ],
      emptyPhone: 'Номер не указан',
      emptyItems: 'Товары не указаны',
      emptyReason: 'Причина не указана',
      emptyComments: 'Комментарии не указаны',
      emptyCreated: 'Дата создания не указана',
      emptyExpired: 'Дата истечения не указана',
    },
  },
};
