<template>
  <div v-if="!file?.link && !file?.id" class="w-full">
    <template v-if="!!loading">
      <at-skeleton type="input"></at-skeleton>
    </template>
    <template v-else>
      <at-file-select
        :title="title"
        :label="label"
        :accept="accept"
        :error="error"
        @on-select="$event => $emit('upload', $event, type)"
      >
        <template v-if="$slots.title" #title>
          <slot name="title"></slot>
        </template>
        <template v-if="$slots.selectLabel" #label>
          <slot name="selectLabel"></slot>
        </template>
      </at-file-select>
    </template>
  </div>
  <template v-else>
    <div v-if="file.id" class="flex flex-wrap justify-between items-center w-full">
      <div class="flex flex-wrap -m-2">
        <div v-viewer class="p-2 cursor-pointer">
          <img
            :id="`client-requirements-file-${file.id}`"
            v-image-loader
            :src="file.link"
            class="w-16 h-16 object-cover"
          />
        </div>
        <div class="p-2 flex flex-col justify-between">
          <p class="text-gray-900 dark:text-white font-medium">{{ label }}</p>
          <p class="text-gray-400 dark:text-gray-500 font-medium text-xs flex flex-wrap items-center">
            <document-icon class="h-6 w-5" />
            <span class="ml-1">{{ fileSize(file.size) }}</span>
          </p>
        </div>
      </div>
      <at-button
        v-if="!hideButton"
        color="danger"
        :loading="!!loading"
        :disabled="!!loading"
        size="xs"
        @click.prevent.stop="$emit('remove', file.id, type)"
      >
        {{ $t('system.btns.delete') }}
      </at-button>
    </div>
  </template>
</template>

<script>
  import { defineComponent, defineAsyncComponent } from 'vue';
  import { DocumentIcon } from '@heroicons/vue/solid';
  import { fileSize } from '@/utils/filters';

  export default defineComponent({
    name: 'BaseFileSelect',
    components: {
      DocumentIcon,
      AtFileSelect: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtFileSelect/AtFileSelect')),
      AtSkeleton: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton')),
      AtButton: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtButton/AtButton')),
    },
    props: {
      loading: {
        type: [Boolean, Number, String],
        default: false,
      },
      file: {
        type: Object,
        default: () => null,
      },
      title: { type: String, default: '' },
      label: { type: [String, Number], default: () => '' },
      type: { type: String, default: () => 'COMMON' },
      accept: { type: [String, Object, Array], default: '.*' },
      error: { type: [String, Number, Boolean], default: () => null },
      hideButton: { type: Boolean, default: false },
    },
    emits: ['remove', 'upload'],
    methods: {
      fileSize,
    },
  });
</script>
