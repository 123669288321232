export default {
  title: "Ro'yxatdan O'tish",
  askAccount: ['Akkauntingiz bormi?', 'Kiring'],
  infoPage: {
    labels: {
      name: "Do'konning nomi",
      storeAddress: "Do'kon manzili",
      user_name: 'Sizning F.I.SH.',
      user_phone: 'Sizning telefon raqamingiz',
    },
    placeholders: {
      name: "Do'konning nomini kiriting",
      storeAddress: 'Toshkent shaxri, Yakkasaroy tumani, Shota Rustaveli 138a',
      user_name: 'Sizning F.I.SH.',
      user_phone: '(99) 999-99-99',
    },
    errors: {},
  },
  btns: {
    prev: 'Orqaga',
    complete: 'Yakunlash',
    ok: 'Tushunarli',
  },
  complete: {
    header: 'Rahmat kattakon! Tez orada bizning mutaxassislarimiz siz bilan aloqaga chiqishadi',
    pleaseWait: 'Iltimos, bizning mutaxassislarimiz siz bilan aloqaga chiqishini kuting!',
  },
};
