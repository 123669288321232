export default {
  features: {
    rejectRejectCaseRefund: {
      button: 'Отказать',
      modal: {
        title: 'Укажите причину отказа',
        cancel: 'Отмена',
        submit: 'Подтвердить',
      },
      successAlert: 'Отказано в возврате!',
    },
  },
};
