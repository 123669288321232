export default {
  widgets: {
    salesItemsListTable: {
      stats: {
        itemsSold: 'Sotilgan tovarlar',
        sumOfItems: 'Sotuv summasi',
        token: 'Hamkor tokeni',
      },
      filters: {
        store: {
          allStores: "Barcha do'konlar",
        },
        reset: 'Tozalash',
      },
      table: {
        headers: ['ID', 'Mijoz', 'Tovar', "Do'kon", 'Miqdori', 'Imzolangan', 'Soni'],
      },
    },
  },
};
