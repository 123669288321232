import { DELIVERY_TYPE_SERVICE } from '@/to-fsd/entities/delivery/constants';

export default {
  entities: {
    delivery: {
      deliveryInfo: {
        title: 'Yetkazib berishlar',
        deliveryNumber: 'Yetkazib berish №{number}',
        modal: {
          title: 'Yetkazib berish №{number}',
          timeToDelivery: 'Yetkazib berishga qolgan vaqt',
          senderAddress: "Jo'natuvchi manzili:",
          receiverAddress: 'Qabul qiluvchi manzili:',
          closeButton: 'Tushunman',
        },
      },
      dpdDeliveryTypeService: {
        [DELIVERY_TYPE_SERVICE.ENTER_ENTER]: 'Eshikdan eshikkacha',
        [DELIVERY_TYPE_SERVICE.ENTER_POINT]: 'Eshikdan buyurtma berish punktigacha',
        [DELIVERY_TYPE_SERVICE.POINT_POINT]: 'Buyurtma berish punktidan buyurtma berish punktigacha',
        [DELIVERY_TYPE_SERVICE.POINT_ENTER]: 'Buyurtma berish punktidan eshikkacha',
      },
      deliveryReceiverInfo: {
        name: 'Mijoz ismi',
        phone: 'Telefon raqami',
      },
      approximateTime: {
        text: 'Tahminiy yetkazib berish vaqti',
      },
      senderAndReceiverAddress: {
        sender: 'Yuboruvchi manzili',
        receiver: 'Qabul qiluvchi manzili',
      },
    },
  },
};
