import { formatDistanceToNow as dateFnsformatDistanceToNow } from 'date-fns';
import i18n from '@/plugins/i18n';
import { ru, uz } from 'date-fns/locale';

const formatDistanceToNow = date => {
  const lang = i18n.global.locale || 'ru';

  const map = {
    'ru': ru,
    'uz': uz,
  };

  return dateFnsformatDistanceToNow(date, { addSuffix: true, locale: map[lang] });
};

export default formatDistanceToNow;
