export default {
  pages: {
    calculatorPreviewPayment: {
      title: "To'lov jadvali",
      btns: {
        close: 'Yopish',
        print: 'Chop etish',
      },
    },
  },
};
