import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useApplicationStore = defineStore('ApplicationStore', {
  state: () => {
    return {
      application: null,
      bnplContract: null,
      items: null,
      conditions: null,
      condition: null,
      previewPayment: null,
      isSegregated: false,
      creditCreate: {
        step: 'initial',
        tab: 'inPlace',
      },
      loading: {},
    };
  },
  actions,
  getters,
});
