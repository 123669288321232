import { comparisonValues } from '@/plugins/aliftech-ui/utils';
import { useRoute, useRouter } from 'vue-router';
import { isRef, ref } from 'vue';
/**
 * @typedef { import("vue").Ref } Ref
 */

/**
 * @typedef {Function} ChangeQuery - Function to change query and push router with new obtained query
 * @param {string | Array<string>} key
 * @param {string | number | boolean | Array.<string, number, boolean>} value
 * @return {Promise<{}>|Promise<RouteLocationNormalizedLoaded>}
 */

/**
 * @typedef {Function} ClearQuery - Function to clear query from keys that are empty, null or undefined
 * @param {Object} query
 * @return {Object} newQuery
 */

/**
 * @typedef {Object} WrapperObjectForQueryRefAndMethods
 * @property {ChangeQuery} changeQuery
 * @property {ClearQuery} clearQuery
 * @property {Ref} query
 */

/**
 * @typedef {Function} UseQuery
 * @param {Ref} query - Initial query values
 * @returns {WrapperObjectForQueryRefAndMethods} obj
 */

/**
 * @type {UseQuery}
 */
export const useQuery = (query = {}) => {
  const queryRef = isRef(query) ? query : ref({ ...query });
  const route = useRoute();
  const router = useRouter();

  /**
   * @type {ClearQuery} clearQuery
   */
  const clearQuery = obj => {
    const result = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const val = obj[key];
        if (val !== null && val !== undefined && val !== '') {
          result[key] = val;
        }
      }
    }
    return result;
  };

  /**
   *
   * @param {string | string[]} key
   * @param {string | string[]} value
   * @return {Object} generatedQuery
   */
  const generateQuery = (key, value) => {
    const urlQuery = { ...(route?.query || {}) };
    if (Array.isArray(key)) {
      const newQuery = { ...(queryRef.value ?? {}) };
      key.forEach((key, index) => {
        newQuery[key] = value?.[index] ?? null;
        delete urlQuery[key];
      });
      queryRef.value = { ...newQuery };
    } else {
      if (queryRef.value?.[key] !== value) {
        queryRef.value = { ...queryRef.value, [key]: value };
        delete urlQuery[key];
      }
    }
    return { ...urlQuery, ...clearQuery(queryRef.value ?? {}) };
  };

  /**
   * @type {ChangeQuery}
   */
  const changeQuery = (key, value) => {
    const _query = generateQuery(key, value);

    if (comparisonValues(_query, clearQuery(route.query))) {
      return Promise.resolve(_query);
    }

    return router
      .push({
        path: route.path,
        query: _query,
      })
      .then(() => {
        return _query;
      });
  };

  if (!comparisonValues(queryRef.value, clearQuery(route.query))) {
    router.replace({
      path: route.path,
      query: { ...route.query, ...clearQuery(queryRef.value) },
    });
  }

  return { changeQuery, clearQuery, query: queryRef };
};
