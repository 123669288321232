export default {
  widgets: {
    employeesTable: {
      headers: ['ID', 'Имя', 'Магазин'],
      storeSelect: {
        placeholder: 'Магазин',
        allStores: 'Все магазины',
      },
    },
  },
};
