import orders from '../orders/routers';
import AlifshopLayout from './layouts/AlifshopLayout';

const routes = {
  path: '/alifshop/',
  component: AlifshopLayout,
  redirect: '/alifshop/orders',
  children: [...orders],
};

export default router => {
  router.addRoute(routes);
};
