import { usePaymentStore } from '../payments/stores';
import { useBalancesStore } from '../balances/stores';

export const resetAcquiringStores = () => {
  const paymentStore = usePaymentStore();
  const balancesStore = useBalancesStore();

  paymentStore.$reset();
  balancesStore.$reset();
};
