import { useQuery } from '@tanstack/vue-query';
import { getClientActivityAreas } from '../api';

const useGetClientActivityAreasQuery = () => {
  return useQuery({
    queryKey: ['global', 'activity-areas'],
    async queryFn() {
      const response = await getClientActivityAreas();

      return response.data;
    },
    retry: 0,
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export default useGetClientActivityAreasQuery;
