const formatFileSize = sizeInBytes => {
  const gb = 1073741824;
  const mb = 1048576;
  const kb = 1024;

  let result = '';

  if (sizeInBytes >= gb) {
    const size = parseFloat(sizeInBytes / gb).toFixed(2);
    result = `${size} GB`;
    return result;
  } else if (sizeInBytes >= mb) {
    const size = parseFloat(sizeInBytes / mb).toFixed(2);
    result = `${size} MB`;
    return result;
  } else if (sizeInBytes >= kb) {
    const size = parseFloat(sizeInBytes / kb).toFixed(2);
    result = `${size} KB`;
    return result;
  }

  result = `${sizeInBytes} Bytes`;

  return result;
};

export default formatFileSize;
