import { inject, onMounted, onUnmounted, ref } from 'vue';
import { useMainStore } from '@/modules/main/common/stores';

export const useSubscribeToUpdateLocalStorage = () => {
  const subscription = ref(null);

  const $centrifuge = inject('$centrifuge');

  const mainStore = useMainStore();

  const handler = payload => {
    const key = payload.data.key;
    const value = payload.data.value;
    const showUpdateModal = payload.data.showUpdateModal || false;

    localStorage.setItem(key, value);

    if (showUpdateModal) {
      localStorage.setItem('reload', true);
      mainStore.$patch({ reload: true });
    }
  };

  onMounted(() => {
    const channel = `merchant-update-local-storage-key`;

    subscription.value = $centrifuge.getSubscription(channel) || $centrifuge.newSubscription(channel);

    subscription.value.subscribe();

    subscription.value.on('publication', handler);
  });

  onUnmounted(() => {
    if (!subscription.value) {
      return;
    }

    subscription.value.off('publication', handler);
  });
};
