export default {
  features: {
    createApplication: {
      steps: ['Товары', 'Условия', 'Создать'],
      noItemsChosen: {
        title: 'Вы не выбрали товар',
        body: 'Выбрать товар',
      },
      conditionNotSelectedAlert: {
        title: 'Вы не выбрали условие',
        body: 'Выбрать условие',
      },
      selectItemsTab: {
        itemsTable: {
          title: 'Товары',
          headers: ['Наименование', 'Sku', 'Цена', 'Кол-во'],
          totalSum: 'Общая сумма',
        },
        moreThenInStock: 'Количество товара в заявке больше чем количество товара на складе.',
        updateQuantity: 'Пожалуйста обновите количество через склад',
      },
      confirmTab: {
        firstPaymentDateInfoAlert: {
          title: 'Выберете удобную дату погашения для клиента.',
          subTitle:
            'Уточните, пожалуйста, какого числа клиенту будет удобно оплачивать в связи с начислением зарплаты.',
        },
        fields: {
          firstPaymentDate: {
            label: 'Дата первой оплаты',
          },
        },
        nextPaymentDate: 'След. дата погашения - {date}',
        submit: 'Создать заявку',
        rejectToast: {
          title: 'Отказано',
          clientLink: 'Перейти к клиенту',
        },
      },
    },
  },
};
