import { CASHBACK_STATUS } from '@/to-fsd/entities/cashback';

export default {
  pages: {
    bonuses: {
      statuses: {
        [CASHBACK_STATUS.CHARGE]: 'Одобрено',
        [CASHBACK_STATUS.TO_PAY]: 'Готово к начислению',
        [CASHBACK_STATUS.NOT_APPROVED]: 'Не одобрено',
        [CASHBACK_STATUS.REJECT]: 'Списание за возврат',
        [CASHBACK_STATUS.TOTAL]: 'Начислено за все время',
      },
      filters: {
        placeholders: {
          contractDate: 'Дата создания',
          chargedAt: 'Дата начисления',
        },
      },
    },
  },
};
