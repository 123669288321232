import { ref, watch } from 'vue';
import { useDebounce } from '@/to-fsd/shared/hooks';

const useRefDebounced = (refObject, delay) => {
  const debounced = ref(refObject.value);

  const updater = useDebounce(() => {
    debounced.value = refObject.value;
  }, delay);

  watch(refObject, () => updater());

  return debounced;
};

export default useRefDebounced;
