import { getToken } from '@/services';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';

const requireAuthToken = ({ next, to }) => {
  const checkAuth = getToken() && getToken() !== '';

  if (!checkAuth) {
    return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
  }

  return next();
};

export default requireAuthToken;
