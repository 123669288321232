export default {
  title: 'Регистрация',
  askAccount: ['Уже есть аккаунт?', 'Войдите здесь'],
  infoPage: {
    labels: {
      name: 'Название магазина',
      storeAddress: 'Адрес магазина',
      user_name: 'Ваше Ф.И.О.',
      user_phone: 'Ваш номер телефона',
    },
    placeholders: {
      name: 'Введите название магазина',
      storeAddress: 'город Ташкент, Яккасарайский район, Шота Руставели 132a',
      user_name: 'Ваше Ф.И.О.',
      user_phone: '(99) 999-99-99',
    },
    errors: {},
  },
  btns: {
    prev: 'Назад',
    complete: 'Завершить',
    ok: 'Понятно',
  },
  complete: {
    header: 'Спасибо! Скоро наши менеджеры свяжуться с вами',
    pleaseWait: 'Пожалуйста, подождите пока наши менеджеры свяжуться с вами и мы откроем вам доступ в нашу систему!',
  },
};
