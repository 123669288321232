export const ROUTE_NAMES = {
  login: 'Auth.login',
  loginToFsd: 'Auth.login.to-fsd',
  recoverPassword: 'Auth.recover-password',
  recoverPasswordToFsd: 'Auth.recover-password.to-fsd',
  registrationInfo: 'Registration.info',
  registration: 'Registration',
  registrationComplete: 'Registration.complete',
  home: 'Home',
  noPermission: 'NoPermission',
  moduleAccessDenied: 'ModuleAccessDenied',
  offline: 'Offline',
  objectNotFound: 'ObjectNotFound',
  serverError: 'ServerError',
  pageNotFound: 'PageNotFound',
  knowledgeBase: 'KnowledgeBase',
  uploadClientFile: 'UploadClientFile',
};
