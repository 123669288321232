export default {
  widgets: {
    nasiyaSearchClients: {
      phoneInput: {
        label: 'Telefon raqam',
      },
      find: 'Mijozni qidirish',
    },
  },
};
