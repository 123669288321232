export default {
  entities: {
    rejectCase: {
      rejectionType: {
        full: 'Butun',
        partial: 'Qisman',
      },
      info: {
        title: "Qaytarish haqida ma'lumot",
        clientName: 'Mijoz F.I.Sh.',
        clientPhone: 'Mijoz raqami',
        date: 'Yuborilgan sanasi',
        note: 'Qaytarish sababi',
      },
      table: {
        title: 'Qaytarish tovarlari',
        headers: ['Nomi', 'Narxi'],
      },
      cancelReason: {
        title: 'Qaytarish rad etish sababi',
      },
      photos: {
        title: 'Tovar rasmi',
        alert: 'Tovar rasmi mavjud emas',
      },
    },
  },
};
