export default {
  features: {
    clientAddCard: {
      button: 'Добавить карту',
      modal: {
        title: 'Добавление карты',
        form: {
          labels: {
            cardNumber: 'Номер карты',
            expire: 'Срок',
          },
          cancel: 'Отменить',
          sendSms: 'Отправить СМС',
          confirm: 'Потвердить',
        },
      },
    },
  },
};
