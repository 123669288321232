<template>
  <div class="overflow-auto">
    <div style="min-width: 40rem">
      <canvas :id="chartId"></canvas>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseChart',
  };
</script>

<script setup>
  import Chart from 'chart.js';
  import { hasOwnProperty } from '@/plugins/aliftech-ui/utils/hasOwnProperty';
  import { computed, ref, onMounted, onUnmounted } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import commarize from '@/utils/commarize';

  const props = defineProps({
    chartType: {
      type: String,
      default: 'line',
    },
    chartData: {
      type: Object,
      default: () => ({}),
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    chartPlugins: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: [String, Number],
      default: undefined,
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
  });

  const chartId = computed(() => props.id || 'chart');
  const chart = ref(null);

  onMounted(() => {
    chartConstructor(props.chartType, props.chartData, props.chartOptions, props.chartPlugins, props.height);
  });
  onUnmounted(() => (chart.value = null));

  const { locale } = useI18n();
  function chartConstructor(chartType, chartData, chartOptions, chartPlugins, height) {
    const elem = document.getElementById(chartId.value);
    const ctx = elem?.getContext?.('2d');
    elem ? (elem.height = height || 300) : null;
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.4,
          borderWidth: 3,
          borderCapStyle: 'rounded',
        },
      },
      scales: {
        xAxes: [
          {
            minBarLength: 2,
            gridLines: {
              display: false,
              borderDash: [5, 15],
              borderDashOffset: 4,
              offsetGridLines: true,
            },
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [50, 10],
              color: '#E5E7EB',
            },
            afterTickToLabelConversion(q) {
              q.ticks.forEach((elem, index) => {
                q.ticks[index] = commarize(elem, locale.value) || elem;
              });
            },
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
          },
        ],
      },
      tooltips: {
        enabled: true,
        title: false,
        mode: 'nearest',
        callbacks: {
          label(tooltipItems) {
            return commarize(tooltipItems.value, locale.value);
          },
        },
      },
      ...chartOptions,
    };
    const plugins = [
      {
        afterDatasetsDraw: () => {
          ctx.font = Chart.helpers.fontString(
            Chart.defaults.global.defaultFontFamily,
            'normal',
            Chart.defaults.global.defaultFontFamily
          );
          ctx.fillStyle = '#345995';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          config.data.datasets.forEach(function(dataset) {
            for (let i = 0; i < dataset?.data?.length; i++) {
              for (let key in dataset?._meta) {
                if (hasOwnProperty(dataset._meta, key) && !dataset._meta[key].hidden) {
                  if (chartType === 'line' && i % 2 !== 0) {
                    let model = dataset._meta[key].data[i]._model;
                    ctx.fillText(commarize(dataset.data[i], locale.value), model.x, model.y - 5);
                  } else if (chartType === 'bar') {
                    let model = dataset._meta[key].data[i]._model;
                    ctx.fillText(commarize(dataset.data[i], locale.value), model.x, model.y - 5);
                  }
                }
              }
            }
          });
        },
        ...chartPlugins,
      },
    ];
    const config = {
      type: chartType,
      data: chartData,
      options: options,
      plugins: [
        ...plugins,
        {
          beforeInit: chart => {
            const originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
              originalFit.bind(chart.legend)();
              this.height += 15;
            };
          },
        },
      ],
    };
    chart.value = new Chart(ctx, config);
    // element.height = this.chart?.scales?.['y-axis-0']?.ticks?.length * 10 || this.height || element.height;
  }
</script>
