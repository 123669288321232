// todo refactor module name
import { ROUTE_NAMES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
const moduleName = 'module_azo';

export default [
  {
    path: '/nasiya/applications',
    name: ROUTE_NAMES.applicationsList,
    redirect: () => {
      return {
        name: ROUTE_NAMES.applicationsListToFsd,
      };
    },
    component: () => import('../views/NasiyaApplicationsIndexPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Alifshop Merchant - Заявки'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/applications/to-fsd',
    name: ROUTE_NAMES.applicationsListToFsd,
    component: async () => {
      const { ApplicationsListPage } = await import('@/to-fsd/pages/applications-list');

      return ApplicationsListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Alifshop Merchant - Заявки'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/applications/:id',
    name: ROUTE_NAMES.applicationsShow,
    component: () => import('../views/NasiyaApplicationsShowPage'),
    redirect: to => {
      return {
        name: ROUTE_NAMES.applicationShowToFsd,
        params: {
          applicationId: to.params.id,
        },
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Заявка'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/applications/:applicationId/to-fsd',
    name: ROUTE_NAMES.applicationShowToFsd,
    component: async () => {
      const { ApplicationShowPage } = await import('@/to-fsd/pages/application-show');

      return ApplicationShowPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Заявка'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/applications/create',
    component: () => import('../layouts/NasiyaApplicationCreateLayout'),
    children: [
      {
        path: '/nasiya/items',
        alias: '/',
        name: ROUTE_NAMES.applicationsCreateItems,
        component: () => import('../views/create/NasiyaApplicationsCreateItemsPage'),
        redirect: to => {
          return {
            name: ROUTE_NAMES.createApplication,
            params: { clientId: to.query.clientId },
            query: { type: to.query.type },
          };
        },
        meta: {
          middlewares: [
            setDocumentTitle('Новая заявка - товары'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule(moduleName),
            requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
            fetchNasiyaResources,
          ],
        },
        props: true,
      },
      {
        path: '/nasiya/conditions',
        name: ROUTE_NAMES.applicationsCreateConditions,
        component: () => import('../views/create/NasiyaApplicationsCreateConditionsPage'),
        redirect: to => {
          return {
            name: ROUTE_NAMES.createApplication,
            params: { clientId: to.query.clientId },
            query: { type: to.query.type },
          };
        },
        meta: {
          middlewares: [
            setDocumentTitle('Новая заявка - условия'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule(moduleName),
            requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
            fetchNasiyaResources,
          ],
        },
        props: true,
      },
      {
        path: '/nasiya/confirm',
        name: ROUTE_NAMES.applicationsCreateConfirm,
        component: () => import('../views/create/NasiyaApplicationsCreatePage'),
        redirect: to => {
          return {
            name: ROUTE_NAMES.createApplication,
            params: { clientId: to.query.clientId },
            query: { type: to.query.type },
          };
        },
        meta: {
          middlewares: [
            setDocumentTitle('Новая заявка - создать'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule(moduleName),
            requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
            fetchNasiyaResources,
          ],
        },
        props: true,
      },
      {
        path: '/nasiya/agreement',
        name: ROUTE_NAMES.applicationsCreateAgreement,
        component: () => import('../../clients/views/NasiyaClientsAgreementPage'),
        meta: {
          middlewares: [
            setDocumentTitle('Новая заявка - соглашение'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule(moduleName),
            requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
            fetchNasiyaResources,
          ],
        },
        props: true,
      },
    ],
  },
];
