export default {
  routes: {
    main: 'Asosiy',
    clients: 'Mijozlar',
    applications: 'Arizalar',
    rejects: 'Qaytarishlar',
    tickets: 'Muammolar',
    complianceChat: 'Compliance bilan chat',
    requests: "So'rovlar",
    deliveries: 'Yetkazmalar',
    alifshop: 'Alifshop',
    items: 'Mahsulotlar',
    orders: 'Buyurtmalar',
    offers: 'Aksiyalar',
    management: 'Menejment',
    invoice: 'Hisob-faktura',
    analytics: 'Analitika',
    notifications: 'Xabarlar',
    calculator: 'Kalkulyator',
    bonuses: 'Bonuslar',
    faq: 'F.A.Q.',
    warehouse: 'Ombor',
    sales: 'Sotuvlar',
    excels: 'Hisobotlar',
    employees: 'Xodimlar',
    pos: 'Kassalar',
    allApplications: "Barcha do'konlarning arizalari",
    payments: "To'lovlar",
    balances: `To'lovlar ro'yxati`,
    scoring: 'Kartani baholash',
    searchClients: 'Mijozni qidirish',
    knowledgeBase: 'Bilimlar bazasi',
    installmentPayment: "Muddatli to'lovni to'lash",
  },
  dropdown: {
    title: 'Sozlamalar',
    logout: 'Hisobdan chiqish',
    lang: 'Til',
    ru: 'Ruscha',
    uz: 'O‘zbekcha',
    toggleLabel: 'Qorong‘i rejim',
  },
  logout: 'Chiqish',
  modules: {
    module_acquiring: 'Ekvayring & BML',
    module_alifshop: 'Alif Shop',
    module_azo: 'Alif Nasiya',
    module_admin: 'Admin sahifa',
  },
};
