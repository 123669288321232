export default {
  features: {
    rejectRejectCaseRefund: {
      button: 'Rad etish',
      modal: {
        title: "Rad etish sababini ko'rsating",
        cancel: 'Bekor qilish',
        submit: 'Tasdiqlash',
      },
      successAlert: 'Qaytarish rad etildi!',
    },
  },
};
