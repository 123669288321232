export default {
  pages: {
    addNewClient: {
      title: 'Создание клиента',
      createClient: {
        ensureDataAreCorrectAlert: {
          title: 'Перепроверьте данные!',
          body: 'После создания клиента, эти данные нельзя будет менять',
        },
        passport: {
          title: 'Паспорт клиента',
          uploadPhotoMessage: 'Загрузите фото паспорта клиента',
          fields: {
            passportPhoto: {
              label: 'Фото паспорта',
            },
          },
          continue: 'Продолжить',
        },
        idPassport: {
          title: 'ID карта',
          uploadPhotoMessage: 'Загрузите фото ID карты клиента',
          fields: {
            passportIdPhotoFront: {
              label: 'Передняя сторона ID карты',
            },
            passportIdPhotoBack: {
              label: 'Задняя сторона ID карты',
            },
          },
          submit: 'Продолжить',
        },
      },
    },
  },
};
