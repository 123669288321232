import i18n from '@/plugins/i18n';
import { format as dateFnsFormat, isValid } from 'date-fns';
import { ru, uz } from 'date-fns/locale';

const formatDate = (inputDate, { format = 'dd MMM yyyy' } = {}) => {
  const isInvalid = isValid(inputDate);

  if (isInvalid) {
    new Error('invalid date');
  }

  const lang = i18n.global.locale || 'ru';

  const map = {
    'ru': ru,
    'uz': uz,
  };

  return dateFnsFormat(inputDate, format, { locale: map[lang] });
};

export default formatDate;
