import { mainConnect, generateError } from '@/services';

export const getClientFile = (clientId, fileId) => {
  return mainConnect.get(`clients/clients/${clientId}/files/${fileId}`).catch(generateError);
};

export const getClientFiles = clientId => {
  return mainConnect.get(`/clients/clients/${clientId}/files`).catch(generateError);
};

export const uploadClientFileByUuid = formData => {
  return mainConnect.post(`/clients/qr/files`, formData).catch(generateError);
};
