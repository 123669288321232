const fileMIMETypes = {
  excel: {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: '.xlsx',
  },
  pdf: {
    type: 'application/pdf',
    extension: '.pdf',
  },
};

const downloadBlobFile = params => {
  const { blob, fileName, fileType } = params;
  return new Promise(resolve => {
    const type = fileMIMETypes[fileType]?.type;
    const name = `${fileName}${fileMIMETypes[fileType]?.extension}`;
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(new Blob([blob], { type }));
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
    resolve();
  });
};

export default downloadBlobFile;
