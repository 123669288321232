import { useI18n } from 'vue-i18n';
import { app } from '@/main';

const useLang = () => {
  const { locale } = useI18n();

  const changeLang = to => {
    if (locale.value !== to) {
      app.config.globalProperties.$cookies.set('lang', to, { expire: '1Y' });
      window.location.reload(true);
    }
  };

  return {
    lang: locale,
    changeLang,
  };
};

export default useLang;
