<template>
  <div class="block mt-8">
    <base-countdown :time="finish" class="w-full mb-2" @end="handleEnd">
      <template #default="{ minute, second, locale }">
        <div v-if="minute || second" class="flex flex-col flex-wrap item-center justify-start">
          <p class="text-gray-500 dark:text-gray-300 text-sm text-center">
            {{ minute.toString().padStart(2, '0') }}:{{ second.toString().padStart(2, '0') }}
          </p>
          <button
            class="focus:outline-none outline-none text-sm text-gray-600 dark:text-gray-400"
            type="button"
            @click="handleClick"
          >
            {{ locale(buttonText) }}
          </button>
        </div>
      </template>
    </base-countdown>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';
  import BaseCountdown from '@/components/base/BaseCountdown';

  const props = defineProps({
    finish: { type: Number, required: true },
    buttonText: { type: String, required: true },
  });

  const emit = defineEmits(['end', 'buttonClick']);

  const handleEnd = () => {
    emit('end');
  };

  const handleClick = () => {
    if (props.finish > new Date().getTime() - 1) {
      emit('buttonClick');
    }
  };
</script>
