import i18n from '@/plugins/i18n';
import { iterableToAtSelectOptions } from '@/utils/forms';
import { useMainStore } from '@/modules/main/common/stores';

export const getters = {
  _appLang: () => {
    const mainStore = useMainStore();
    return mainStore.lang;
  },
  getPaymentStatusOptions: state => {
    return [{ value: null, title: i18n.global.t('system.titles.all') }].concat(
      iterableToAtSelectOptions(state.paymentStatuses, state._appLang)
    );
  },
};
