export default {
  index: {
    title: 'Kassalar',
    headers: {
      id: 'ID',
      posName: 'Kassa',
      storeName: "Do'kon",
      qrcode: 'QR kod',
    },
    store: "Do'kon",
  },
};
