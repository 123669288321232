<template>
  <div ref="root"></div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseObserver',
  });
</script>

<script setup>
  import { onMounted, onUnmounted, ref } from 'vue';

  const props = defineProps({
    options: { type: Object, default: () => ({}) },
  });

  const emit = defineEmits(['intersect']);

  const root = ref(null);
  const observer = ref(null);

  onMounted(() => {
    observer.value = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        emit('intersect');
      }
    }, props.options);
    observer.value.observe(root.value);
  });

  onUnmounted(() => {
    observer.value.disconnect();
  });
</script>
