import { useQuery } from '@tanstack/vue-query';
import { getClientFile } from '../api';

const useGetClientFileQuery = ({ clientId, fileId }) => {
  return useQuery({
    queryKey: ['client-files', 'detail', { clientId, fileId }],
    async queryFn({ queryKey }) {
      const { clientId, fileId } = queryKey[2];

      const response = await getClientFile(clientId, fileId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetClientFileQuery;
