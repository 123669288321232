import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useNasiyaGlobalStore = defineStore('NasiyaGlobalStore', {
  state: () => {
    return {
      app: null,
      activityAreas: null,
      merchantRelations: null,
      merchantStatus: null,
      passportIssuers: null,
      creditsApp: null,
      employees: null,
      regions: null,
      conditions: {
        alifshop: null,
        merchant: null,
      },
    };
  },
  actions,
  getters,
});
