export default {
  pages: {
    addNewClient: {
      title: 'Mijoz yaratish',
      createClient: {
        ensureDataAreCorrectAlert: {
          title: 'Maʼlumotlarni qayta tekshiring!',
          body: 'Mijoz yaratilgandan soʻng, bu maʼlumotlarni oʻzgartirish mumkin boʻlmaydi',
        },
        passport: {
          title: 'Mijozning pasporti',
          uploadPhotoMessage: 'Mijoz pasportining fotosuratini yuklang',
          fields: {
            passportPhoto: {
              label: 'Pasport fotosurati',
            },
          },
          continue: 'Davom etish',
        },
        idPassport: {
          title: 'ID karta',
          uploadPhotoMessage: 'Mijoz ID kartasining fotosuratini yuklang',
          fields: {
            passportIdPhotoFront: {
              label: 'ID kartaning old tomoni',
            },
            passportIdPhotoBack: {
              label: 'ID kartaning orqa tomoni',
            },
          },
          submit: 'Davom etish',
        },
      },
    },
  },
};
