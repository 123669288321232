export default {
  create: {
    title: 'Создание заявки по QR коду',
    posTitle: 'Выберите кассу',
    invalidFields: 'Нельзя выбрать товар {itemName}, так как в нем есть следующие невалидные поля: {itemFields}',
    buttons: {
      create: 'Создать заявку',
      show: 'Показать',
    },
    modal: {
      title: 'Попросите клиента отсканировать QR код',
      successTitle: 'Заявка успешно создана',
      failTitle: 'Заявка не создана',
      checkTitle: 'Еще не оплачен',
      buttons: {
        check: 'QR код отсканирован',
        cancel: 'Отменить заявку',
        finish: 'Готово',
        ok: 'Понятно',
      },
    },
    markingNeededAlert: 'Нужно обязательно прописать маркировку для следующих товаров:',
    markingPanel: {
      title: 'Маркировка',
      desc: 'У некоторых товаров отсутствует маркировка, заполните её вручную',
      btns: {
        mark: 'Заполнить маркировку',
      },
    },
    markingModal: {
      title: 'Укажите маркировку для следующих товаров',
      placeholder: 'Маркировка',
      markingFormatAlert: 'Маркировка должна состоять из минимум 38 символов (первые 18 символов должны быть цифрами)',
      successAlert: 'Маркировка успешно сохранена',
    },
  },
};
