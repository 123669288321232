export default {
  features: {
    employeeChangePhone: {
      button: 'Сменить телефон',
      modal: {
        title: 'Сменить телефон',
        phone: {
          label: 'Номер телефона',
        },
        code: {
          label: 'Код',
          placeholder: 'Введите полученный код',
        },
        sendSms: 'Отправить смс',
        confirm: 'Подтвердить',
        cancel: 'Отменить',
      },
    },
  },
};
