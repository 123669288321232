export default {
  widgets: {
    applicationConditionsList: {
      months: 'Oyga',
      sumPerMonth: 'Sum/Oyiga',
      applicationAmount: 'Ariza narxi',
      downPaymentAmount: "Oldindan to'lov",
      commission: 'Ustama',
      creditAmount: "Muttadli to'lov narxi",
    },
  },
};
