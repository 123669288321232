<template>
  <at-badge :color="color">
    <template v-for="(index, name) in $slots" :key="`base-panel-slots-${index}`" #[name]>
      <slot :name="name"></slot>
    </template>
  </at-badge>
</template>

<script>
  import { defineAsyncComponent, defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseBadge',
    components: {
      AtBadge: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtBadge/AtBadge')),
    },
    props: { type: { type: String, default: () => '' } },
    computed: {
      color() {
        switch (this.type) {
          case 'success':
            return 'green';
          case 'danger':
            return 'red';
          case 'info':
            return 'blue';
          case 'warning':
            return 'yellow';
          case 'secondary':
          default:
            return 'gray';
        }
      },
    },
  });
</script>
