export const getters = {
  normalizedFiles: state => {
    const files = state.files?.result || [];
    return files?.reduce?.(
      (acc, file) => {
        if (file.file_type_key !== 'COMMON') {
          acc.regular[file.file_type_key] = file;
        } else {
          acc.common.push(file);
        }
        return acc;
      },
      { common: [], regular: {} }
    );
  },
};
