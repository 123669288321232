import faq from '../faq/i18n';
import deliveries from '../deliveries/i18n';
import calculator from '../calculator/i18n';
import excels from '../excels/i18n';
import problems from '../problems/i18n';
import rejects from '../rejects/i18n';
import invoice from '../invoice/i18n';
import notifications from '../notifications/i18n';
import managerApplications from '../manager-applications/i18n';
import warehouse from '../warehouse/i18n';
import clients from '../clients/i18n';
import applications from '../applications/i18n';
import analytics from '../analytics/i18n';
import sales from '../sales/i18n';
import home from '../home/i18n';
import qrApplication from '../qr-applications/i18n';
import requests from '../requests/i18n';
import bonuses from '../bonuses/i18n';

export default {
  faq,
  deliveries,
  calculator,
  excels,
  problems,
  rejects,
  invoice,
  notifications,
  managerApplications,
  warehouse,
  clients,
  applications,
  analytics,
  sales,
  home,
  qrApplication,
  requests,
  bonuses,
};
