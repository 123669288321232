export default {
  widgets: {
    applicationConditionsList: {
      months: 'Месяцев',
      sumPerMonth: 'Сум/месяц',
      applicationAmount: 'Сумма заявки',
      downPaymentAmount: 'Сумма предоплаты',
      commission: 'Комиссия',
      creditAmount: 'Сумма рассрочки',
    },
  },
};
