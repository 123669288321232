export default {
  features: {
    employeeEditInfo: {
      panel: {
        title: "Ma'lumotlar",
        fio: {
          label: 'FIO',
        },
        phone: {
          label: 'Telefon',
        },
        partner: {
          label: 'Hamkor',
        },
        store: {
          label: "Do'kon",
        },
        submit: 'Saqlash',
      },
    },
  },
};
