import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';
import { computed } from 'vue';

export const useMainStore = defineStore('MainStore', {
  state: () => {
    return {
      app: null,
      module: null,
      lang: null,
      reload: false,
      error: null,
    };
  },
  actions,
  getters,
});

export const useIsNewOnboarding = () => {
  const mainStore = useMainStore();

  return computed(() => {
    return mainStore.app?.merchant?.new_onboarding || false;
  });
};
