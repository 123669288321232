import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

const defaultValue = {
  query: {},
  data: null,
};

export const useAnalyticsStore = defineStore('AnalyticsStore', {
  state: () => {
    return {
      applicationsCount: { ...defaultValue },
      salesByDate: { ...defaultValue },
      salesByStores: { ...defaultValue },
      applicationsMean: { ...defaultValue },
      demography: { ...defaultValue },
      consultants: { ...defaultValue },
      popularCategories: { ...defaultValue },
      popularItems: { ...defaultValue },
    };
  },
  actions,
  getters,
});
