export default {
  index: {
    title: 'Заказы',
    creationDate: 'Дата создания',
    headers: {
      id: 'ID',
      items: 'Товары',
      client: 'Клиент',
      price: 'Сумма',
      date: 'СОЗДАНО',
      status: 'Статус',
    },
    andMore: 'и еще {count}',
  },
  show: {
    title: 'Покупка № {id}',
    back: 'Назад',
    goodsList: 'Список товаров',
    fillInTheMarking: 'Заполнить маркировку',
    cancelOrder: 'Отменить заказ',
    receiptMethod: {
      title: 'Способ получения',
    },
    statusChange: {
      title: 'Клиент не отвечает?',
      body: 'Можно изменить статус на “Рассмотривается” и попытаться связаться с клиентом через некоторое время',
      btn: 'Изменить статус',
      reviewing: 'Рассматривается',
      cancelled: 'Отменен',
      delivering: 'На доставку',
    },
    complete: {
      title: 'Оформить заказ',
      btn: 'Оформить',
      moneyFrozen: 'Сумма заморожена на несколько дней.',
      smsIsNeeded: 'Для снятия денег требуеться СМС-подтреждение',
      itemMarkingNeed: 'Для снятия средств необходимо подтвердить маркировку товара',
      otp: {
        label: 'Смс код',
        placeholder: 'Введите код',
      },
      sent: 'На номер клиента ({phone}) был отправлен СМС-код. Введите этот код чтобы оформить покупку',
      cancel: 'Отменить',
    },
    purchaseMethod: {
      title: 'Способ покупки',
      change: 'Изменить способ',
      delivery: 'Доставка',
      pickup: 'Самовывоз',
      pickupDescription: 'Клиент готов приехать за товаром самостоятельно',
    },
    client: {
      name: 'Имя клиента',
      phone: 'Номер клиента',
      address: 'Адрес',
      note: 'Ориентир',
    },
    comments: {
      title: 'Комментарии',
      add: 'Добавить',
    },
    items: {
      name: 'НАИМЕНОВАНИЕ',
      type: 'Тип',
      marking: 'МАРКИРОВКА',
      item: 'Товар',
      price: 'Цена',
      amount: 'Кол-во',
      total: 'Итого',
      totalSum: 'Общая сумма',
      indicateLabelingForNextItems: 'Укажите маркировку для следующих товаров',
    },
  },
};
