import applicationsService from '../../services/applications.service';
import creditsService from '../../services/credits.service';
import clientsService from '../../../clients/services/clients.service';
import { formatDate } from '@/utils/filters';
import { deliveriesService } from '@/modules/nasiya/deliveries/services/deliveries.service';

export const actions = {
  async fetchApplication(id) {
    this.loading = { ...this.loading, application: true };
    const response = await applicationsService.getApplication(id);
    this.application = response?.data;
    if (this.application && !this.application.delivery) {
      deliveriesService.getDeliveryByApplicationId(id).then(res => {
        this.application.deliveries = res?.data?.data;
      });
    }
    delete this.loading?.application;
  },
  async fetchApplicationContract(clientId, fileId) {
    const response = await clientsService.getFile(clientId, fileId);
    this.application.contract = response?.data;
  },
  async fetchBnplContract({ applicationId }) {
    const response = await applicationsService.getSignedContract(applicationId);

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = URL.createObjectURL(blob);

    this.bnplContract = {
      link,
      size: blob.size,
    };
  },
  async fetchPreviewPayment(application) {
    const params = {
      application_amount: application?.amount,
      application_condition_id: application?.application_condition_id,
      avoid_weekends: true,
      client_id: application?.client_id,
      condition: { commission: application?.commission, duration: application?.duration },
      contract_date: application?.contract_date || formatDate(new Date(), 'yyy-MM-dd'),
      down_payment_amount: application?.down_payment_amount,
      extra_days: application?.extra_days,
      first_payment_date: application?.first_payment_date
        ? formatDate(application.first_payment_date, 'yyy-MM-dd')
        : null,
    };
    this.loading = { ...this.loading, previewPayment: true };
    const response = await creditsService.getPreviewPayment(params);
    this.previewPayment = response?.data;
    delete this.loading?.previewPayment;
  },
  setApplicationAmount(amount) {
    this.application.amount = amount;
  },
  setDownPaymentHold(payload) {
    this.application.down_payment_amount = payload.amount;
    this.application.down_payment_hold = payload;
  },
  cancelDownPaymentHold(payload) {
    this.application.down_payment_amount = 0;
    this.application.down_payment_hold = payload;
  },
  setApplicationData(payload) {
    this.application = { ...this.application, ...payload };
  },
};
