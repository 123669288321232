import { defineStore } from 'pinia';
import { getters } from './getters';
import { actions } from './actions';

export const useRejectCaseStore = defineStore('RejectCaseStore', {
  state: () => {
    return {
      items: null,
      itemsName: null,
      note: null,
      onPartnerReviewRejectsCount: null,
    };
  },
  getters,
  actions,
});
