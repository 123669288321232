// todo refactor module name
const moduleName = 'module_azo';

import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/manager-applications',
    name: ROUTE_NAMES.managerApplicationsList,
    redirect: () => {
      return {
        name: ROUTE_NAMES.managerApplicationsListToFsd,
      };
    },
    component: () => import('../views/NasiyaManagerApplicationsIndexPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Все заявки'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/manager-applications/to-fsd',
    name: ROUTE_NAMES.managerApplicationsListToFsd,
    component: async () => {
      const { ManagerApplicationsListPage } = await import('@/to-fsd/pages/manager-applications-list');

      return ManagerApplicationsListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Все заявки'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/manager-applications/:id',
    name: ROUTE_NAMES.managerApplicationsShow,
    component: () => import('../views/NasiyaManagerApplicationsShowPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Заявка'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
];
