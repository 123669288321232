export default {
  information: "Ma'lumotlar",
  titles: {
    fullName: 'Mijoz F.I.SHi',
    phone: 'Mijoz telefon raqami',
    items: 'Tovarlar',
    created: 'Yaratilgan sana',
    expired: 'Amal qilish muddati',
    reason: 'Sabab',
    comments: 'Izohlar',
    notes: 'Hamkor uchun izohlar',
    status: 'Holat',
  },
  placeholders: {
    status: 'Holatlardan birini tanlang',
  },
  empty: {
    fullName: "Izm ko'rsatilmagan",
    phone: "Raqam ko'rsatilmagan",
    items: "Tovar ko'rsatilmagan",
    created: "Yaratilgan sana ko'rsatilmagan",
    expired: "Amal qilish muddati ko'rsatilmagan",
    reason: "Sabab ko'rsatilmagan",
    comments: "Izohlar ko'rsatilmagan",
    notes: "Hamkor uchun izohlar ko'rsatilmagan",
    status: "Holat ko'rsatilmagan",
  },
  options: {
    processing: 'Jarayonda',
    completed: 'Bajarildi',
  },
  note: 'Eslatma',
  addNote: "Eslatma qo'shish",
  send: 'Yuborish',
  index: {
    title: 'Muammoli holatlar',
    headers: {
      client: 'Mijoz',
      status: 'Holat',
      date: 'Sana',
      responsiblePerson: 'Javobgar shaxs',
      comments: 'Izohlar',
    },
    noComments: "Hech qanday izoh yo'q",
  },
};
