export default {
  toast: {
    requestStatusChange: 'Стутус запроса изменился на "{to}"',
  },
  statusKeys: {
    completed: 'Одобрено',
    cancelled: 'Отменено',
    underCorrection: 'На исправлении',
    processing: 'На обработке',
  },
  index: {
    title: 'Запросы',
    dropdown: {
      addItem: 'Добавить товар',
      addManually: 'Добавить вручную',
      addWithExcel: 'Добавить через Excel',
    },
    tabs: {
      all: 'Все',
      approved: 'Одобрено',
      processs: 'В обработке',
      fix: 'На исправление',
      cancelled: 'Отменено',
    },
    filters: {
      search: 'Поиск...',
      createdAt: 'Дата создания',
    },
    table: {
      headers: {
        quantity: 'КОЛ-ВО ТОВАРОВ',
        status: 'СТАТУС',
        addedAt: 'ДАТА ДОБАВЛЕНИЯ',
        addedBy: 'КТО ДОБАВИЛ',
      },
      paginationInfo: 'Показано от {from} до {to} из {total} результатов',
    },
  },
  show: {
    title: 'Запрос #{requestId}',
    back: 'Назад',
    alert: {
      desc: 'Исправьте ошибки и отправьте товары на повторное рассмотрение. Что нужно исправить описано в комментарии',
      cancel: 'Отменить',
      sendToReview: 'Отправить на рассмотрение',
    },
    table: {
      title: 'Нужно исправить',
      headers: {
        nameSku: 'НАИМЕНОВАНЕ / СКУ',
        categoryIkpu: 'КАТЕГОРИЯ / ИКПУ',
        quantitySum: 'КОЛ-ВО / ЦЕНА (СУМ)',
        fixNeeded: 'НУЖНО ИСПРАВИТЬ',
      },
      paginationInfo: 'Показано от {from} до {to} из {total} результатов',
      times: 'шт.',
    },
    editItemModal: {
      title: 'Изменение товара',
      labels: {
        name: 'Название',
        price: 'Цена',
        category: 'Категория',
        quantity: 'Количество',
        ikpu: 'ИКПУ',
      },
      buttons: {
        cancel: 'Отмена',
        confirm: 'Подтвердить',
      },
    },
    confirmCancelModal: {
      title: 'Отмена',
      desc: 'Вы действительно хотите отменить операцию?',
      confirm: 'Подтвердить',
      cancel: 'Закрыть',
    },
    confirmDeleteItemModal: {
      title: 'Удаление',
      desc: 'Вы действительно хотите удалить товар?',
      confirm: 'Удалить',
      cancel: 'Отмена',
    },
  },
  addItems: {
    title: 'Добавление товара',
    sendToReview: 'Отправить заявку',
    back: 'Назад',
    addMore: 'Добавить еще',
    form: {
      title: 'Товар',
      labels: {
        ikpu: 'ИКПУ',
        name: 'Название',
        price: 'Цена',
        category: 'Категория',
        quantity: 'Количество',
        sku: 'SKU',
        nds: 'Цена с НДС',
      },
      sum: 'Сум',
    },
  },
};
