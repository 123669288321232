export default {
  features: {
    registration: {
      placeholders: {
        tin: 'Введите ИНН',
        pinfl: 'Введите ПИНФЛ',
      },
      labels: {
        tin: 'Идентификационный номер налогоплательщика (ИНН)',
        pinfl: 'ПИНФЛ',
      },
      select: {
        tin: 'ИНН',
        pinfl: 'Пинфл',
      },
    },
  },
};
