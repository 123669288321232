export default {
  titles: {
    createInvoice: 'Hisob-faktura yaratish',
  },
  documentNumberModal: {
    title: 'Hujjat raqamni kiriting',
    label: 'Hujjat raqami',
    cancel: 'Bekor qilish',
    create: 'Yaratish',
  },
  eimzoModal: {
    title: 'ERI ni tanlang',
    subtitle:
      "ERI kalitlar topilmadi. E-imzo ishga tushirilganligini va ERI kalitlar mavjudligini tekshirib, sahifani yangilab ko'ring",
    cert: 'ERI kalit',
    cancel: 'Bekor qilish',
    apply: 'Tasdiqlash',
  },
  index: {
    banner: "Hisob-faktura ma'lumotlari Didox platformasiga joylanadi",
    tabs: {
      positiveInvoice: 'Hisob-faktura',
      negativeInvoice: 'Manfiy hisob-faktura',
      draftInvoice: 'Qoralamalar',
    },
    positive: {
      headers: {
        name: 'Nomi',
        price: 'Narxi',
        count: 'Soni',
      },
      buttons: {
        insert: 'Joylashtirish',
        sign: 'Imzolash',
        download: 'Yuklab olish',
      },
      label: 'Hisob-faktura yaratish uchun sanani tanlang',
    },
    negative: {
      headers: {
        title: 'Manfiy hisob faktura joylashtirmoqchimisiz?',
        subtitle: 'Quyidagi tovarlar uchun manfiy hisob fakturasi joylashtiriladi',
        contractWithQuantity: 'Shartnoma / tovarlar soni',
      },
      contractDate: 'Shartnoma sanasi {date}',
      itemsQuantity: 'qayratish uchun tovarlar {count}',
      itemName: 'Nomi: {name}',
      itemsAmount: 'Summa: {amount}',
      itemsCount: 'Soni: {count}',
      buttons: {
        insert: 'Joylashtirish',
        select: 'Tanlash',
        cancel: 'Bekor qilish',
        sign: 'Imzolash',
        download: 'Yuklab olish',
      },
      label: 'Manfiy hisob-faktura yaratish uchun sanani tanlang',
    },
    draft: {
      headers: {
        created_date: 'Yaratilgan sana',
        type: 'Tur',
        doc_date: 'Hisob-faktura sanasi',
        count: 'Tovarlar soni',
      },
      buttons: {
        sign: 'Imzolash',
        download: 'Yuklab olish',
      },
      types: {
        positive: 'Standart',
        negative: 'Manfiy',
      },
    },
    alert: {
      successMessage: 'Hisob-faktura muvafaqqiyatli yuborildi',
      noData: "{date} sana uchun ma'lumotlar topilmadi",
      insertedToDraft: ['{date} sana uchun hisob-faktura Didoxga jo`natilgan', 'Statusi: Qoralama'],
      signed: ['{date} sana uchun hisob-faktura Didoxga jo`natilgan', 'Statusi: Imzolangan'],
      successSigned: 'Hisob-faktura muvafaqqiyatli imzolandi',
      signingError: 'Imzolashda xatolik yuz berdi',
    },
  },
};
