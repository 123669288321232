export default {
  index: {
    title: 'Buyurtmalar',
    creationDate: 'Yaratilgan sana',
    headers: {
      id: 'ID',
      items: 'Tovarlar',
      client: 'Клиент',
      price: 'Narxi',
      date: 'Sana',
      status: 'Holat',
    },
    andMore: 'va yana {count}',
  },
  show: {
    title: 'Xarid № {id}',
    back: 'Ortga',
    goodsList: "Mahsulotlar ro'yxati",
    fillInTheMarking: "Belgini to'ldirish",
    cancelOrder: 'Buyurtmani bekor qilish',
    receiptMethod: {
      title: 'Qabul qilish usuli',
    },
    statusChange: {
      title: 'Mijoz javob bermadimi?',
      body: "Hozircha holatni \"Ko'rib chiqilmoqda\"-ga o'zgartiring va bir ozdan so'ng mijoz bilan qayta bog'laning",
      btn: 'Holatni yangilash',
      reviewing: "Ko'rib chiqilmoqda",
      cancelled: 'Bekor qilindi',
      delivering: 'Yetkazib berishga',
    },
    complete: {
      title: 'Buyurtmani rasmiylashtirish',
      btn: 'Rasmiylashtirish',
      moneyFrozen: 'Miqdor bir-necha kunga muzlatildi.',
      smsIsNeeded: "Mablag' yechib olinishi uchun tasdiqlovchi SMS kerak",
      itemMarkingNeed: "Pul mablag'larini olish uchun mahsulot belgisini tasdiqlashingiz kerak",
      otp: {
        label: 'SMS kod',
        placeholder: 'Kodni kiriting',
      },
      sent: 'Mijozning ({phone}) raqamiga SMS kod yuborildi, xaridni rasmiylashtirish uchun ushbu kodni kiriting.',
      cancel: 'Bekor qilish',
    },
    purchaseMethod: {
      title: 'Xarid turi',
      change: 'Xarid turini yangilash',
      delivery: 'Yetkazma',
      pickup: 'Olib ketish',
      pickupDescription: "Mijoz o'zi tovar uchun kelishga tayyor",
    },
    client: {
      name: 'Mijoz ismi',
      phone: 'Mijoz telefon raqami',
      address: 'Manzil',
      note: "Mo'ljal",
    },
    comments: {
      title: 'Izoh',
      add: "Qo'shish",
    },
    items: {
      name: 'Nomi',
      type: 'Turi',
      marking: 'Asl belgisi',
      item: 'Tovar',
      price: 'Narxi',
      amount: 'Soni',
      total: 'Jami',
      totalSum: 'Umumiy qiymati',
      indicateLabelingForNextItems: 'Quyidagi mahsulotlar uchun belgini kiriting',
    },
  },
};
