export default {
  index: {
    title: "To'lovlar",
    headers: {
      sum: 'Tranzaksiya miqdori / karta raqami',
      date: 'Sana va vaqti',
      status: 'Holat',
    },
    buttons: {
      revert: 'Tranzaksiyani bekor qilish',
      reset: 'Tozalash',
    },
    placeholders: {
      status: 'Holat',
    },
    statuses: {
      succeeded: 'Muvaffaqiyatli',
      failed: 'Xatolik',
      timeout: 'Vaqt tugadi',
      pending: 'Kutilmoqda',
      reverted: 'Bekor qilingan',
      need_confirmation: 'Tasdiqlash kerak',
    },
  },
  revert: {
    title: 'Tranzaksiyani bekor qilish',
    subTitle: 'Ushbu tranzaksiyani bekor qilishni aniq istaysizmi?',
    buttons: {
      confirm: 'Xa, bekor qilish',
      cancel: "Yo'q",
    },
    successTitle: 'Tranzaksiya bekor qilindi',
  },
};
