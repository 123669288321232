import { useUserStore } from '@/modules/main/user/stores';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';

const requirePermissions = permissions => {
  return async ({ next }) => {
    const userStore = useUserStore();

    const userPermissions = userStore.me.permissions;
    const userPermissionNames = userPermissions.map(permission => permission.name);

    const hasPermissionToTargetRoute = permissions.every(permissionName =>
      userPermissionNames.includes(permissionName)
    );

    if (!hasPermissionToTargetRoute) {
      return { name: ROUTE_NAMES.noPermission };
    }

    return next();
  };
};

export default requirePermissions;
