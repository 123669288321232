export default {
  titles: {
    home: 'Здравствуйте, {name}',
    totalNumber: 'Подписано заявок',
    totalSum: 'Выдано рассрочек на',
    newClients: 'Новых клиентов',
  },
  createClient: {
    title: 'Создать нового клиента',
    body: 'Вы можете создать нового клиента со статусом Аъзо',
    btn: 'Создать клиента',
  },
  contactNumber: {
    text: 'Есть вопросы? Позвоните нам:',
  },
  theme: {
    title: 'Режим',
    light: 'Дневной режим',
    dark: 'Ночной режим',
  },
};
