export default {
  widgets: {
    createRejectForm: {
      arizaUpload: {
        title: 'Загрузить заявление',
        dropdown: {
          title: 'Скачать шаблон',
          items: ['На узбексоком', 'На русском'],
        },
        alert:
          'По рассрочке уже произведена оплата. Скачайте шаблон заявления, который клиент должен заполнить и подписать, затем загрузите его обратно.',
        label: 'Заявление',
      },
      instructions: {
        list: ['Напишите причину возврата', 'Выберите товары', 'Загрузите заявление'],
      },
    },
  },
};
