import { notificationsService } from '../services/notifications.service';

export const actions = {
  async fetchNotificationsCounter(params) {
    const response = await notificationsService.getNotificationsCounter(params);
    this.notificationsCount = response?.data?.count;
  },
  setNotificationsCounter(payload) {
    this.notificationsCount = payload;
  },
};
