export default {
  index: {
    title: 'Kartani baholash',
    form: {
      card: {
        label: {
          pan: 'Karta raqami',
          exp: 'Sana',
        },
        buttons: {
          next: 'Davom etish',
        },
      },
      otp: {
        title: 'Kodni kiriting',
        label: {
          code: 'Tasdiqlash kodi',
        },
        buttons: {
          next: 'Davom etish',
          back: 'Ortga',
        },
      },
    },
    detail: {
      card: {
        title: "Karta ma'lumotlari",
        avgIncome: "Oxirgi 12 oy o'rtacha daromad",
        button: "Boshqa kartani tekshirib ko'rish",
      },
      scoring: {
        title: 'Baholash',
        transactions: {
          title: 'Tranzaksiyalar',
          amount: 'Summa',
          count: 'Miqdor',
          selectPeriod: 'Oraliqni tanlang',
        },
      },
      monitoring: {
        title: 'Monitoring',
        label: 'Oraliq',
        button: "So'rov yuborish",
        empty: "Tanlangan oraliq ichida ma'lumotlar topilmadi",
      },
    },
  },
};
