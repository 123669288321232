import i18n from '@/plugins/i18n';

const SUCCESS_CODE = 1000;

const ERROR_CODES = {
  1001: 'system.errors.badRequest',
  1002: 'system.errors.unauthorized',
  1003: 'system.errors.internalError',
  1004: 'system.errors.notFound',
  1005: 'system.errors.unprocessableEntity',
  1100: 'system.errors.invalidOtp',
  1101: 'system.errors.reversalTimeExpired',
  1102: 'system.errors.invalidCardCredentials',
  1103: 'system.errors.expiredCard',
  1104: 'system.errors.noSmsNotifications',
  1105: 'system.errors.duplication',
  1106: 'system.errors.otpTriesExceeded',
  1107: 'system.errors.cardBlocked',
};

export const handleResponse = res => {
  return new Promise((resolve, reject) => {
    if (res?.data instanceof Blob) {
      resolve(res);
    } else {
      const code = res?.data?.code || res?.data?.error?.code;
      if (code === SUCCESS_CODE || !code) {
        resolve(res);
      } else {
        const message = ERROR_CODES[code]
          ? i18n.global.t(ERROR_CODES[code])
          : i18n.global.t('system.errors.errorWithUnknownCode');
        reject({ response: { data: { message } } });
      }
    }
  });
};
