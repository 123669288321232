import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES as ADMIN_ROUTES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

// todo refactor module name
const moduleName = 'module_admin';

export default [
  {
    path: '/admin/pos',
    name: ADMIN_ROUTES.posList,
    component: () => import('../views/AdminPosIndexPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Кассы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_admin']),
      ],
    },
    redirect: () => {
      return {
        name: ADMIN_ROUTES.posListToFsd,
      };
    },
  },
  {
    path: '/admin/pos/to-fsd',
    name: ADMIN_ROUTES.posListToFsd,
    component: async () => {
      const { PosTerminalsPage } = await import('@/to-fsd/pages/pos-terminals');

      return PosTerminalsPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Кассы'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_admin']),
      ],
    },
  },
];
