<template>
  <div class="block mb-5">
    <div v-if="!!loading">
      <div class="flex flex-wrap justify-between -m-2">
        <div class="w-full md:w-7/12 p-2">
          <div class="-m-1">
            <div class="p-1">
              <at-skeleton type="row" class="h-8"></at-skeleton>
            </div>
            <div class="p-1">
              <at-skeleton type="row" class="h-6"></at-skeleton>
            </div>
          </div>
        </div>
        <div class="p-2">
          <div class="-m-1 flex flex-wrap items-center">
            <div class="p-1">
              <at-skeleton type="row" class="w-24 h-6"></at-skeleton>
            </div>
            <div class="p-1">
              <at-skeleton type="row" class="w-24 h-6"></at-skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-wrap justify-between items-center -m-2">
        <div class="block p-2">
          <slot name="left">
            <h2 class="text-2xl font-bold text-gray-900 dark:text-white leading-9">{{ title }}</h2>
            <p class="text-sm text-gray-500 mt-0.5">{{ subTitle }}</p>
          </slot>
        </div>
        <div v-if="$slots && 'right' in $slots" class="block ml-auto">
          <div class="flex flex-wrap items-center">
            <div class="p-2">
              <slot name="right"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'BaseHeader',
    components: {
      AtSkeleton: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton')),
    },
    props: {
      title: {
        type: [String, Number],
        default: '',
      },
      loading: {
        type: [String, Number, Boolean],
        default: () => false,
      },
      subTitle: {
        type: [String, Number],
        default: '',
      },
    },
  };
</script>
