export const APPLICATION_STATUS = {
  NEW: 'NEW',
  REVIEWING: 'REVIEWING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  DELETED: 'DELETED',
  CANCELLED: 'CANCELLED',
};

export const APPLICATION_CREATED_FROM = {
  ALIFSHOP: 'ALIFSHOP',
  MERCHANT: 'MERCHANT',
  MERCHANT_MOBILE: 'MERCHANT_MOBILE',
  ALIFSHOP_MOBILE: 'ALIFSHOP_MOBILE',
  ALIFMOBI: 'ALIFMOBI',
  ALIFSHOP_MINI_APP: 'ALIFSHOP_MINI_APP',
  COMPLIANCE: 'COMPLIANCE',
};

export const APPLICATION_TYPE = {
  IN_STORE: 'in_store',
  OUT_OF_STORE: 'out_of_store',
};
