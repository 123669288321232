import useGetClientFileQuery from './queries/useGetClientFileQuery';
import useGetClientFilesQuery from './queries/useGetClientFilesQuery';
import useUploadClientFileByUuidMutation from './mutations/useUploadClientFileByUuidMutation';
import { getClientFile } from './api';
import { CLIENT_FILE_TYPE } from './constants';
import { clientFileGetFileTypeKey } from './lib';

const api = {
  getClientFile,
};

export {
  CLIENT_FILE_TYPE,
  useGetClientFileQuery,
  useGetClientFilesQuery,
  useUploadClientFileByUuidMutation,
  api,
  clientFileGetFileTypeKey,
};
