export default {
  widgets: {
    installmentPayment: {
      input: {
        label: 'Введите номер клиента',
      },
      findInstallment: 'Поиск',
      client: 'Клиент',
      noInstallmentsYetAlert: 'Данный клиент не имеет рассрочек привязанных к данному магазину',
      clientNotFoundAlert: {
        title: 'Не удалось найти клиента с таким номером. Попросите клиента оплатить рассрочку через:',
        items: ['Личный кабинет в alifnasiya.uz', 'Раздел Nasiya в приложении Alif Mobi'],
      },
      payInstallment: 'Оплатить',
      payInstallmentModal: {
        title: 'Оплата за - №{contractNumber}',
        cancel: 'Отменить',
        submit: 'Оплатить ',
        inputs: {
          amount: {
            label: 'Сумма оплаты',
            sum: 'сум',
          },
          card: {
            label: 'Выбор карты',
            main: 'основное',
          },
        },
        currentPaymentAmount: 'Ежемесячный платёж',
        currentPaymentDate: 'Дата след. платежа',
        balance: 'Баланс',
      },
      otpStepModal: {
        title: 'СМС подтверждение',
        confirmPayButton: 'Потвердить оплату',
        otpIsSentToClient: 'СМС отправлен на номер клиента',
        inputs: {
          otp: {
            label: 'Код из СМС',
          },
        },
      },
      successModal: {
        message: 'Оплата прошла успешно',
        okButton: 'Понятно',
      },
      errorModal: {
        message: 'Ошибка при оплате',
        okButton: 'Понятно',
      },
    },
  },
};
