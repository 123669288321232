export default {
  index: {
    title: 'Отчёты',
    headers: {
      file: 'Файл',
      period: 'Период',
      lastGenerated: 'Последняя генерация',
      action: 'Действия',
    },
    forAllPeriod: 'За весь период',
    generate: 'Сгенерировать',
    download: 'Скачать',
    day: 'День',
    period: 'Период',
  },
  list: {
    merchants_service: 'Товары',
    merchants_rejects: 'Возвраты',
    merchants_problem: 'Проблемные клиенты',
    merchants_invoice: 'Счёт фактура',
    merchants_minus_invoice: 'Минусовая счёт фактура',
    merchants_reconciliation: 'Акт сверки',
    merchants_delivery: 'Доставки',
    delivery_tariffs: 'Тарифы на доставку',
  },
  success: {
    generated: {
      title: '"Отчет - \'{name}\' успешно сгенерирован"',
      message: "Нажмите на кнопку 'скачать' чтобы загрузить отчет",
    },
  },
  failed: {
    generated: {
      title: '"Отчет - \'{name}\' не удалось сгенерировать"',
    },
  },
  periodTypes: {
    month: 'Месяц',
    quarter: 'Квартал',
    half_year: 'Полугодие',
    year: 'Год',
  },
};
