<template>
  <template v-if="queries.creditsApp.isLoading">
    <fullscreen-loader />
  </template>

  <template v-else-if="queries.creditsApp.isError">
    <api-error :error="queries.creditsApp.error" />
  </template>

  <template v-else>
    <nasiya-layout-new v-if="isNewOnboarding" />

    <nasiya-layout-old v-else />
  </template>
</template>

<script setup>
  import { reactive } from 'vue';

  import NasiyaLayoutNew from './NasiyaLayoutNew.vue';
  import NasiyaLayoutOld from './NasiyaLayoutOld.vue';

  import { useIsNewOnboarding } from '@/modules/main/common/stores';
  import { useGetCreditsAppQuery } from '@/to-fsd/shared/queries';
  import { FullscreenLoader } from '@/to-fsd/shared/ui';
  import { ApiError } from '@/to-fsd/shared/lib';

  const queries = reactive({
    creditsApp: useGetCreditsAppQuery(),
  });

  const isNewOnboarding = useIsNewOnboarding();
</script>
