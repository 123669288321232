<template>
  <div class="flex flex-wrap justify-center">
    <div class="print:hidden w-10/12 mb-6">
      <div class="flex flex-wrap justify-between border-b border-gray-300 py-6 -m-2">
        <div class="p-2">
          <at-button class="white" icon="x" @click.prevent.stop="$router.go(-1)">
            {{ $t('applications.paymentSchedule.btns.close') }}
          </at-button>
        </div>
        <div class="flex flex-wrap align-center ml-auto">
          <div class="p-2">
            <at-button icon="printer" class="primary" @click.prevent.stop="print">
              {{ $t('applications.paymentSchedule.btns.print') }}
            </at-button>
          </div>
          <div class="p-2">
            <base-lang-change></base-lang-change>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view v-slot="{ Component }">
    <component :is="Component" class="min-h-screen"></component>
  </router-view>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';

  const print = () => {
    if (window) {
      window.print();
    }
  };

  const AtButton = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtButton/AtButton'));
</script>
