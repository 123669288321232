import Viewer from 'viewerjs';
import { nextTick, watch } from 'vue';
import debounce from '@/utils/debounce';

export default {
  mounted(el, binding, vnode) {
    const debouncedCreateViewer = debounce(createViewer, 50);
    debouncedCreateViewer(el, binding.value);
    createWatcher(el, binding, vnode, debouncedCreateViewer);
    if (!binding.modifiers.static) {
      createObserver(el, binding.value, debouncedCreateViewer, binding.modifiers.rebuild);
    }
  },
  unmounted(el) {
    destroyObserver(el);
    destroyWatcher(el);
    destroyViewer(el);
  },
};

const name = 'viewer';

async function createViewer(el, options, rebuild = false, observer = false) {
  await nextTick();
  if (observer && !imageDiff(el)) return;
  if (rebuild || !el[`$${name}`]) {
    destroyViewer(el);
    el[`$${name}`] = new Viewer(el, options);
  } else {
    el[`$${name}`].update();
  }
}

function imageDiff(el) {
  const imageContent = el.innerHTML.match(/<img([\w\W]+?)[\\/]?>/g);
  const viewerImageText = imageContent ? imageContent.join('') : undefined;
  if (el.__viewerImageDiffCache === viewerImageText) {
    return false;
  } else {
    el.__viewerImageDiffCache = viewerImageText;
    return true;
  }
}

function createObserver(el, options, debouncedCreateViewer, rebuild) {
  destroyObserver(el);
  const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
  if (!MutationObserver) {
    return;
  }
  const observer = new MutationObserver(mutations => {
    mutations.forEach(() => {
      debouncedCreateViewer(el, options, rebuild, true);
    });
  });
  const config = { attributes: true, childList: true, characterData: true, subtree: true };
  observer.observe(el, config);
  el.__viewerMutationObserver = observer;
}

function createWatcher(el, binding, vnode, debouncedCreateViewer) {
  el.__viewerUnwatch = watch(
    () => binding.value,
    newVal => {
      debouncedCreateViewer(el, newVal, true, false);
    },
    { deep: true }
  );
}

function destroyViewer(el) {
  if (!el[`$${name}`]) {
    return;
  }
  el[`$${name}`].destroy();
  delete el[`$${name}`];
}

function destroyObserver(el) {
  if (!el.__viewerMutationObserver) {
    return;
  }
  el.__viewerMutationObserver.disconnect();
  delete el.__viewerMutationObserver;
}

function destroyWatcher(el) {
  if (!el.__viewerUnwatch) {
    return;
  }
  el.__viewerUnwatch();
  delete el.__viewerUnwatch;
}
