import router from '@/router';
import i18n from './plugins/i18n';

const addI18n = module => {
  for (const key in module) {
    for (const lang in module[key]) {
      i18n.global.mergeLocaleMessage(lang, { [key]: module[key][lang] });
    }
  }
};

export const registerModules = modules => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey];
    if (module.router) {
      module.router(router);
    }
    if (module.i18n) {
      addI18n(module.i18n);
    }
  });
};
