export default {
  widgets: {
    alifshopItemSelectorPanel: {
      input: {
        label: 'Tovar nomi',
        placeholder: 'Tovar nomi va artikul',
      },
      quantity: 'Miqdori',
      finishButton: 'Bajarish',
      notInStock: "Bu tovar omborda yo'q",
      updateQuantity: 'Iltimos, tovar miqdorini obmor orqali yangilang',
    },
  },
};
