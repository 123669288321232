export default {
  features: {
    createApplication: {
      steps: ['Tovarlar', 'Shartlar', 'Ariza yaratish'],
      noItemsChosen: {
        title: 'Siz biror tovarni tanlamadingiz',
        body: 'Tovar tanlash',
      },
      conditionNotSelectedAlert: {
        title: 'Shartlar tanlanmagan',
        body: 'Shartni tanlash',
      },
      selectItemsTab: {
        itemsTable: {
          title: 'Tovarlar',
          headers: ['Nomi', 'Sku', 'Narxi', 'Soni'],
          totalSum: 'Jami',
        },
        moreThenInStock: "Arizadagi tovar miqdori ombordagi miqdordan ko'p",
        updateQuantity: 'Iltimos, tovar miqdorini obmor orqali yangilang',
      },
      confirmTab: {
        firstPaymentDateInfoAlert: {
          title: "Mijoz uchun qulay bo'lgan to'lov kunini tanlang.",
          subTitle: "Iltimos, mijozdan unga qulay bo'lgan to'lov kunini so'rang.",
        },
        fields: {
          firstPaymentDate: {
            label: 'Birinchi to’lov sanasi',
          },
        },
        nextPaymentDate: "Keyingi to'lov sanasi - {date}",
        submit: 'Ariza yaratish',
        rejectToast: {
          title: 'Rad etildi',
          clientLink: "Mijoz sahifasiga o'tish",
        },
      },
    },
  },
};
