export default {
  pages: {
    salesList: {
      title: 'Sotuvlar',
      instructionsButton: "Ko'rsatma",
      instructionsModal: {
        title: "Ko'rsatma",
        items: [
          "«Shartnoma» original nusxalari doimo kompaniyada bo'lishi shart.",
          "Barcha aktlar merchantga to'liq yuklatilishi lozim.",
          "Kunlik qilingan savdo (mahsulot) merchant orqali Excelga yuklab olinib buxgalterga jo'natilishi kerak, buxgalter shu ma’lumotlarga asosan счет фактура yuborishi kerak.",
          "Aktlarning original varianti (to'liq holda) yetkazilish tartibi:",
          '— Toshkent va Toshkent viloyati 7 kunda',
          '— Qolgan viloyatlar har 10 kunda olib kelinishi kerak',
        ],
      },
    },
  },
};
