import { useMutation } from '@tanstack/vue-query';
import { uploadClientFileByUuid } from '../api';

const useUploadClientFileByUuidMutation = () => {
  return useMutation({
    async mutationFn({ uuid, file, type, clientId }) {
      const formData = new FormData();

      formData.append('uuid', uuid);
      formData.append('file', file);
      formData.append('type', type);

      if (clientId) {
        formData.append('client_id', clientId);
      }

      const response = await uploadClientFileByUuid(formData);

      return response.data;
    },
  });
};

export default useUploadClientFileByUuidMutation;
