import { iterableToAtSelectOptions } from '@/utils/forms';
import i18n from '@/plugins/i18n';
import { useMainStore } from '@/modules/main/common/stores';

export const getters = {
  _appLang: () => {
    const mainStore = useMainStore();
    return mainStore.lang;
  },
  clientFullName: state => {
    if (!state.problemCase?.client) return null;
    return [state.problemCase.client.name, state.problemCase.client.surname, state.problemCase.client.patronymic].join(
      ' '
    );
  },
  getProblemStatusOptions: state => {
    return [{ value: null, title: i18n.global.t('system.titles.all') }].concat(
      iterableToAtSelectOptions(state.problemStatuses, state._appLang)
    );
  },
};
