import { initYandexMetrika } from 'yandex-metrika-vue3';
import router from '@/router';

export default {
  install(app) {
    app.use(initYandexMetrika, {
      id: process.env.VUE_APP_YM_ID,
      router: router,
      env: process.env.VUE_APP_ENVIRONMENT,
      options: {
        accurateTrackBounce: true,
        clickmap: true,
        defer: true,
        ecommerce: false,
        params: [],
        userParams: {},
        trackHash: false,
        trackLinks: true,
        type: 0,
        webvisor: true,
        triggerEvent: false,
      },
    });
  },
};
