import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';

export default [
  {
    path: '/sign-up',
    name: ROUTE_NAMES.registrationInfo,
    redirect: () => {
      return {
        name: ROUTE_NAMES.registration,
      };
    },
    component: () => import('@/modules/main/registration/views/RegistrationInfoPage'),
    meta: {
      middlewares: [setDocumentTitle('Регистрация - Основное')],
    },
  },
  {
    path: '/registration',
    name: ROUTE_NAMES.registration,
    component: async () => {
      const { RegistrationPage } = await import('@/to-fsd/pages/registration');

      return RegistrationPage;
    },
    meta: {
      middlewares: [setDocumentTitle('Регистрация - Основное')],
    },
  },
  {
    path: '/complete',
    name: ROUTE_NAMES.registrationComplete,
    component: () => import('@/modules/main/registration/views/RegistrationCompletePage'),
    meta: {
      middlewares: [setDocumentTitle('Регистрация - Завершить')],
    },
  },
];
