import { computed } from 'vue';
import { checkType } from '@/plugins/aliftech-ui/utils';
import { useI18n } from 'vue-i18n';

export const useAlert = queryOrMutation => {
  const { t } = useI18n();

  return computed(() => {
    if (!queryOrMutation.isError.value) {
      return null;
    }

    const error = queryOrMutation.error.value;

    if (checkType(error, 'object') && 'errors' in error) {
      return;
    }

    if (checkType(error, 'object') && 'message' in error) {
      return { message: error.message, type: 'danger', id: new Date().getTime() };
    }

    if (checkType(error, 'string')) {
      return { message: error, type: 'danger', id: new Date().getTime() };
    }

    return { message: t('system.errors.somethingWentWrong'), type: 'danger', id: new Date().getTime() };
  });
};
