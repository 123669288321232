export default {
  widgets: {
    alifshopItemSelectorPanel: {
      input: {
        label: 'Название товара',
        placeholder: 'Название и артикул товара',
      },
      quantity: 'Количество',
      finishButton: 'Завершить',
      notInStock: 'Данный товар на складе отсутствует',
      updateQuantity: 'Пожалуйста обновите количество через склад',
    },
  },
};
