import { ref } from 'vue';

/**
 * @typedef { import("vue").Ref } Ref
 */

/**
 * Method to set the system theme
 * @typedef setTheme
 * @type {Function}
 * @param {string} theme - The theme value to set
 * @returns {void}
 */

/**
 * Method to get the system theme
 * @returns {string}
 */
const getSystemTheme = () => {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  if (window.matchMedia('(prefers-color-scheme: light)').matches) {
    return 'light';
  }
  return 'light';
};

/**
 * Theme to use in the application
 * @returns {string|null}
 */
const theme = ref(localStorage.getItem('theme') || getSystemTheme());

/**
 * Hook to use theme in the application
 * @returns {{theme: Ref<string>, setTheme: setTheme, getSystemTheme: ((function(): string|null))}}
 */
export const useTheme = () => {
  /**
   * Method to set the system theme
   * @type setTheme
   */
  const setTheme = value => {
    theme.value = value;
    theme.value && localStorage.setItem('theme', theme.value);
    const themeMethods = {
      light: () => {
        document.body.classList.remove('dark');
        document.body.classList.add('light');
      },
      dark: () => {
        document.body.classList.remove('light');
        document.body.classList.add('dark');
      },
    };
    theme.value in themeMethods && themeMethods[theme.value]();
  };

  return { theme, setTheme, getSystemTheme };
};

export default useTheme;
