import { APPLICATION_STATUS } from '../constants';

export default {
  entities: {
    application: {
      table: {
        title: 'Заявки',
        seeAll: 'Посмотреть все',
        noApplicationsAlert: 'Заявок за сегодня нет',
        headers: ['Заявка', 'Создано', 'Статус'],
        applicationItems: '0 товаров | 1 товар | {count} товаров',
      },
      animations: {
        applicationOnGulChatAiReview: {
          title: 'рассматривает...',
        },
        applicationIsApproved: {
          title: 'Заявка одобрена',
        },
        applicationRejected: {
          title: 'Отказано',
        },
        applicationOnComplianceReview: {
          subTitle: 'Заявка передана кредитному отделу',
        },
      },
      statusText: {
        [APPLICATION_STATUS.NEW]: 'Новый',
        [APPLICATION_STATUS.REVIEWING]: 'Рассматривается',
        [APPLICATION_STATUS.APPROVED]: 'Одобрено',
        [APPLICATION_STATUS.REJECTED]: 'Отказано',
        [APPLICATION_STATUS.COMPLETED]: 'Оформлено',
        [APPLICATION_STATUS.DELETED]: 'Удалено',
        [APPLICATION_STATUS.CANCELLED]: 'Отменено',
      },
      applicationShowLink: 'Перейти на заявку',
    },
  },
};
