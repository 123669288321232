export default {
  widgets: {
    installmentPayment: {
      input: {
        label: 'Mijoz raqamini kiriting',
      },
      findInstallment: 'Izlash',
      client: 'Mijoz',
      noInstallmentsYetAlert: "Bu mijozning ushbu do'konga bog'liq bo'lgan muddatli to'lov shartnomalari mavjud emas.",
      clientNotFoundAlert: {
        title: 'Ushbu raqamli mijoz topilmadi. Mijozdan rassrochka to‘lovini quyidagi orqali so‘rashingiz mumkin:',
        items: ['Alifnasiya.uz saytida shaxsiy kabinet', 'Alif Mobi ilovasining Nasiya bo‘limi'],
      },
      payInstallment: 'To‘lov',
      payInstallmentModal: {
        title: "To'lov - №{contractNumber}",
        cancel: 'Bekor qilish',
        submit: 'To‘lovni amalga oshirish',
        inputs: {
          amount: {
            label: 'To‘lov miqdori',
            sum: 'sum',
          },
          card: {
            label: 'Karta tanlash',
            main: 'asosiy',
          },
        },
        currentPaymentAmount: 'Oylik to‘lov',
        currentPaymentDate: 'Keyingi to‘lov sanasi',
        balance: 'Balans',
      },
      otpStepModal: {
        title: 'SMS tasdiqlash',
        confirmPayButton: 'To‘lovni tasdiqlash',
        otpIsSentToClient: 'SMS mijoz raqamiga yuborildi',
        inputs: {
          otp: {
            label: 'SMS dan kodi',
          },
        },
      },
      successModal: {
        message: 'To‘lov muvaffaqiyatli amalga oshirildi',
        okButton: 'Tushunaman',
      },
      errorModal: {
        message: 'To‘lov jarayonida xatolik',
        okButton: 'Tushunaman',
      },
    },
  },
};
