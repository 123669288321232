import { rejectsService } from '../services/rejects.service';

export const actions = {
  async fetchOnPartnerReviewRejectsCount() {
    const maxCount = 100;

    const rejectCases = await rejectsService.getRejectCases({ status: 'ON_PARTNER_REVIEW', per_page: maxCount });

    this.onPartnerReviewRejectsCount = rejectCases.data.data.length;
  },
};
