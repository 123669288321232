<template>
  <at-table :elements="loading ? Array(+rows).fill(true) : elements">
    <template v-if="loading && ('header' in $slots || 'title' in $attrs)" #header>
      <div class="w-1/2">
        <at-skeleton :style="{ height: 24 / 16 + 'rem' }"></at-skeleton>
      </div>
    </template>
    <template v-else-if="'header' in $slots" #header>
      <slot name="header"> </slot>
    </template>
    <template #element="{ element }">
      <template v-if="loading || loadingRow === element.id">
        <at-table-item v-for="col in +cols" :key="`base-table-skeleton-col-${col}`">
          <at-skeleton :style="{ height: 24 / 16 + 'rem' }"></at-skeleton>
        </at-table-item>
      </template>
      <template v-else>
        <slot name="element" :element="element"></slot>
      </template>
    </template>
    <template v-if="'footer' in $slots" #footer>
      <slot name="footer"></slot>
    </template>
  </at-table>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'BaseTable',
    components: {
      AtTable: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtTable/AtTable')),
      AtTableItem: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtTableItem/AtTableItem')),
      AtSkeleton: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton')),
    },
    props: {
      rows: { type: [String, Number], default: () => 1 },
      cols: { type: [String, Number], default: () => 1 },
      loading: { type: [String, Number, Boolean], default: () => false },
      loadingRow: { type: [String, Number, Boolean], default: () => '' },
      elements: { type: Array, default: () => [] },
    },
  };
</script>
