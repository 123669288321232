<template>
  <div v-if="alert && open" v-show="alert" :ref="`alert-${alert?.id}`">
    <div v-if="alert && alert.id" class="-mt-4">
      <div class="pt-4">
        <at-alert
          :key="alert.id"
          :title="alert.title"
          :type="alert.type"
          :dismissible="alert.dismissible"
          @close="open = false"
        >
          <span class="whitespace-normal">{{ alert.message }}</span>
        </at-alert>
      </div>
    </div>
  </div>
  <div v-else-if="isIterableErrors && open" ref="errors">
    <div class="-mt-4">
      <div class="pt-4">
        <at-alert type="danger" :dismissible="true" @close="open = false">
          <li v-for="(value, index) in errors" :key="index">{{ index }}: {{ value }}</li>
        </at-alert>
      </div>
    </div>
  </div>
</template>

<script>
  import { deepCopy } from '../../plugins/aliftech-ui/utils';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'BaseAlert',
    components: {
      AtAlert: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtAlert/AtAlert')),
    },
    props: {
      alert: {
        type: Object,
        validate: alert => {
          return (alert?.title && alert?.message && alert?.type && alert?.id && alert?.dismissible) || !alert;
        },
        default: () => null,
      },
      errors: {
        type: Object,
        default: () => null,
      },
    },
    emits: ['close'],
    data() {
      return {
        open: true,
      };
    },
    computed: {
      isIterableErrors() {
        const errorsCopy = deepCopy(this.errors);
        const keys = errorsCopy ? Object.keys(errorsCopy) : [];
        return !!keys.length;
      },
    },
    watch: {
      alert: {
        deep: true,
        immediate: true,
        handler(value) {
          this.open = true;
          this.$nextTick(() => {
            if (value?.id) {
              this.$refs?.[`alert-${value?.id}`]?.scrollIntoView?.({ behavior: 'smooth' });
            }
          });
        },
      },
      errors: {
        deep: true,
        immediate: true,
        handler() {
          this.open = true;
          this.$nextTick(() => {
            this.$refs?.['errors']?.scrollIntoView?.({ behavior: 'smooth' });
          });
        },
      },
    },
  };
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s linear;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
