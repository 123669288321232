<template>
  <at-badge :rounded="true" :color="color" v-bind="$attrs">
    <span class="whitespace-nowrap">{{ capitalize(body) }}</span>
  </at-badge>
</template>

<script setup>
  import { capitalize } from 'vue';
</script>

<script>
  import { checkType } from '@/plugins/aliftech-ui/utils';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'BaseStatus',
    components: {
      AtBadge: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtBadge/AtBadge')),
    },
    props: {
      id: {
        type: [String, Number],
        default: '',
      },
      statuses: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      body() {
        const statuses = this.statuses;
        if (Array.isArray(statuses)) {
          const status = statuses.find(status => status.id === this.id || status.key === this.id);
          return (
            status?.[`body_${this.$i18n.locale}`] ||
            status?.[`name_${this.$i18n.locale}`] ||
            status?.lang?.[`${this.$i18n.locale}`] ||
            status?.[`${this.$i18n.locale}`] ||
            status?.name ||
            status?.body
          );
        } else if (checkType(statuses, 'object')) {
          return (
            statuses?.[this.id]?.[`body_${this.$i18n.locale}`] ||
            statuses?.[this.id]?.[`name_${this.$i18n.locale}`] ||
            statuses?.[this.id]?.[`${this.$i18n.locale}`] ||
            statuses?.[this.id]?.name ||
            statuses?.[this.id]?.body
          );
        }
        return this.$t('system.errors.noStatus');
      },
      color() {
        const isNumber = !Number.isNaN(parseInt(this.id, 10));
        const id = isNumber ? this.body?.toString?.()?.toLowerCase?.() : this.id?.toString?.()?.toLowerCase?.();
        switch (id) {
          case 'новый':
          case 'new':
          case 'created':
          case 'yangi':
          case 'active':
            return 'blue';
          case 'identified':
          case 'аъзо':
          case 'a`zo':
          case 'одобрено':
          case 'tasdiqlandi':
          case 'completed':
          case 'оформлен':
          case 'оформлено':
          case 'завершен':
          case 'approved':
          case 'выполнен':
          case 'доставлено':
          case 'yetkazib berildi':
          case 'succeeded':
          case 'paid':
          case 'partner_approved':
          case 'ok':
          case 'repaid':
            return 'green';
          case 'archived':
          case 'архивирован':
          case 'rejected':
          case 'отказано':
          case 'rad etdi':
          case 'failed':
          case 'unknown_error':
          case 'expired':
          case 'partner_denied':
          case 'unsuccess':
            return 'red';
          case 'аъзо лайт':
          case 'identify_light':
          case 'fixed':
          case 'на исправлении':
          case 'tuzatishda':
          case 'reviewing':
          case 'рассматривается':
          case 'на рассмотрении':
          case 'ko`rib chiqilmoqda':
          case 'в процессе':
          case 'pending':
          case 'refund_in_progress':
          case 'on_partner_review':
            return 'yellow';
          case 'reviewed':
          case 'на доставку':
          case 'delivering':
          case 'заявка на аъзо':
          case 'need_confirmation':
          case 'to_revert':
          case 'partially_refunded':
            return 'indigo';
          case 'on_balance':
            return 'purple';
          case 'denied':
          case 'отклонен':
          case 'deleted':
          case 'удалено':
          case 'cancelled':
          case 'отменено':
          case 'reverted':
          case 'refunded':
            return 'pink';
          default:
            return 'default';
        }
      },
    },
  };
</script>
