import { createI18n } from 'vue-i18n/index';

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  silentFallbackWarn: true,
  messages: {},
});

if (process.env.NODE_ENV !== 'test') {
  const pageContext = require.context('@/to-fsd', true, /i18n\/(ru|uz)\.js$/);

  pageContext.keys().forEach(key => {
    const locale = key.match(/\/i18n\/(ru|uz)\.js$/)[1];
    const { default: messages } = pageContext(key);

    i18n.global.mergeLocaleMessage(locale, messages);
  });
}

export default i18n;
