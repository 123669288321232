export default {
  pages: {
    knowledgeBase: {
      title: 'База знаний',
      videoCard: {
        more: 'Подробнее',
      },
    },
  },
};
