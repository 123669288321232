<template>
  <div class="relative" :style="{ width: size + 'rem', height: size + 'rem' }">
    <div class="absolute inline-flex items-center justify-center overflow-hidden rounded-full">
      <svg class="" :style="{ width: size + 'rem', height: size + 'rem' }">
        <circle
          class="text-gray-300 dark:text-gray-300"
          :stroke-width="strokeWidth"
          stroke="currentColor"
          fill="transparent"
          :r="radius"
          cx="40"
          cy="40"
        />
        <circle
          class="text-green-600 dark:text-green-500 transform -rotate-90 origin-center transition-all duration-200 ease-in"
          :stroke-width="strokeWidth"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="circumference - (percent / 100) * circumference"
          stroke-linecap="round"
          stroke="currentColor"
          fill="transparent"
          r="30"
          cx="40"
          cy="40"
        />
      </svg>
      <span class="absolute text-sm text-primary-700 dark:text-primary-400">{{ value }}/{{ total }}</span>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseProgressRing',
  });
</script>

<script setup>
  import { ref } from 'vue';
  const props = defineProps({
    value: {
      type: Number,
      default: () => 0,
    },
    total: {
      type: Number,
      default: () => 0,
    },
  });

  const strokeWidth = 5;
  const size = 5; //rem
  const radius = (size * 16) / 2 - strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;
  const percent = ref((+props.value * 100) / +props.total);
</script>
