export default {
  features: {
    clientAddCard: {
      button: 'Kartani qo‘shish',
      modal: {
        title: 'Karta qo‘shish',
        form: {
          labels: {
            cardNumber: 'Karta raqami',
            expire: 'Muddati',
          },
          cancel: 'Bekor qilish',
          sendSms: 'SMS yuborish',
          confirm: 'Tasdiqlash',
        },
      },
    },
  },
};
