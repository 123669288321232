import { createRouter, createWebHistory } from 'vue-router';
import OfflinePage from '@/modules/main/common/views/OfflinePage.vue';
import { useMainStore } from '@/modules/main/common/stores';
import { stringToUri, tokenDecryption, tokenEncoding, uriToString } from '@/utils/filters';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import i18n from '@/plugins/i18n';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

let fromOffline = false;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: ROUTE_NAMES.home,
      component: () => import('@/modules/main/common/views/HomePage'),
      meta: {
        middlewares: [
          setDocumentTitle('Главная'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
        ],
      },
    },
    {
      path: '/no-permission',
      name: ROUTE_NAMES.noPermission,
      component: () => import('@/modules/main/common/views/ErrorPage'),
      meta: { middlewares: [setDocumentTitle('Недостаточно прав')] },
    },
    {
      path: '/module-access-denied',
      name: ROUTE_NAMES.moduleAccessDenied,
      component: () => import('@/modules/main/common/views/ErrorPage'),
      meta: { middlewares: [setDocumentTitle('Доступ к модулю запрещен')] },
    },
    {
      path: '/offline',
      name: ROUTE_NAMES.offline,
      beforeEnter() {
        fromOffline = true;
      },
      component: OfflinePage,
      meta: { middlewares: [setDocumentTitle('Нет интернета')] },
    },
    {
      path: '/object-not-found',
      name: ROUTE_NAMES.objectNotFound,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Объект не найден')] },
    },
    {
      path: '/server-error',
      name: ROUTE_NAMES.serverError,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Ошибка сервера')] },
    },
    {
      path: '/:pathMatch(.*)',
      name: ROUTE_NAMES.pageNotFound,
      component: () => import(`@/modules/main/common/views/ErrorPage`),
      meta: { middlewares: [setDocumentTitle('Страница не найдена')] },
    },
    {
      path: '/clients/upload-file',
      name: ROUTE_NAMES.uploadClientFile,
      component: async () => {
        const { UploadClientFilePage } = await import('@/to-fsd/pages/upload-client-file');

        return UploadClientFilePage;
      },
      meta: {
        middlewares: [setDocumentTitle('Загрузить файл клиента')],
      },
    },
  ],
});

const onOnline = () => {
  const path = router.currentRoute.value?.query?.from
    ? tokenDecryption(uriToString(router.currentRoute.value?.query?.from))
    : '/';
  if (fromOffline) {
    router.push({ path }).then(() => {
      window.removeEventListener('onOnline', onOnline);
      window.addEventListener('offline', onOffline);
    });
  }
};

const onOffline = () => {
  const from =
    router.currentRoute.value?.fullPath !== '/'
      ? tokenEncoding(stringToUri(router.currentRoute.value?.fullPath))
      : null;
  router
    .push({
      name: ROUTE_NAMES.offline,
      query: { from },
    })
    .then(() => {
      window.removeEventListener('offline', onOffline);
      window.addEventListener('online', onOnline);
    });
};

if (typeof window !== 'undefined') {
  if ('onoffline' in window) {
    window.addEventListener('offline', onOffline);
  }
  if ('ononline' in window) {
    window.addEventListener('online', onOnline);
  }
}

const middlewarePipeline = (context, middleware, index) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return () => true;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    return nextMiddleware({ ...context, next: nextPipeline });
  };
};

export let prevRoute = null;

router.beforeEach(async (to, from) => {
  prevRoute = from;
  const mainStore = useMainStore();

  if (!mainStore.lang) {
    mainStore.$patch({ lang: i18n.global.locale || 'uz' });
  }

  const context = {
    to,
    from,
  };

  const middlewares = to.meta?.middlewares || [];

  if (middlewares.length === 0) {
    return true;
  }

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  });
});

router.afterEach(() => {
  if (localStorage.getItem('reload')) {
    const store = useMainStore();
    store.$patch({ reload: true });
  }
});

router.onError(error => {
  // eslint-disable-next-line
  console.error(error);
  // const pattern = /Loading chunk (\d)+ failed/g;
  // const isChunkLoadFailed = error.message.match(pattern);
  // if (isChunkLoadFailed) {
  //   app.config.globalProperties.$toast.error({
  //     title: i18n.global.t('app.connectionIssues'),
  //     duration: 5000,
  //   });
  // }
});

export default router;
