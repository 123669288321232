import { mainConnect, generateError } from '@/services';

const requestQueue = {};

export const clientsService = {
  /**
   * Get clients
   *
   * @param {Object} params
   * @param {String} params.ids - client ids
   * @param {String} params.q - search query
   * @param {Number} params.status_id - status id
   *
   * @returns {Promise<Clients>}
   * @typedef {Object} Clients
   * @property {Number} data[].id - client id
   * @property {String} data[].name - client name
   * @property {String} data[].surname - client surname
   * @property {String} data[].patronymic - client patronymic
   * @property {String} data[].phone - client phone
   * @property {String} data[].unique_id - client unique id
   * @property {String} data[].client_status_key - client status key
   * @property {String} data[].created_at - created_at
   * @property {Number} meta.last_page - last page for pagination
   *
   * @throws {Error}
   */
  getClients(params) {
    return mainConnect.get('clients/clients', { params }).catch(generateError);
  },

  /**
   * Get problem clients
   *
   * @param {Object} params
   * @param {String} params.ids - client ids
   *
   * @returns {Promise<Clients>}
   * @typedef {Object} Clients
   * @property {Number} data[].id - client id
   * @property {String} data[].name - client name
   * @property {String} data[].surname - client surname
   * @property {String} data[].patronymic - client patronymic
   * @property {String} data[].phone - client phone
   * @property {String} data[].unique_id - client unique id
   * @property {String} data[].client_status_key - client status key
   * @property {String} data[].created_at - created_at
   * @property {Number} meta.last_page - last page for pagination
   *
   * @throws {Error}
   */
  getProblemClients(params) {
    return mainConnect.get('clients/clients-pc', { params }).catch(generateError);
  },

  /**
   * Get clients
   *
   * @param {Object} params
   * @param {String} params.ids - client ids
   * @param {String} params.q - search query
   * @param {Number} params.status_id - status id
   *
   * @returns {Promise<Clients>}
   * @typedef {Object} Clients
   * @property {Number} data[].id - client id
   * @property {String} data[].name - client name
   * @property {String} data[].surname - client surname
   * @property {String} data[].patronymic - client patronymic
   * @property {String} data[].phone - client phone
   * @property {String} data[].unique_id - client unique id
   * @property {String} data[].client_status_key - client status key
   * @property {String} data[].created_at - created_at
   * @property {Number} meta.last_page - last page for pagination
   *
   * @throws {Error}
   */
  getIdentifiedClients(params) {
    return mainConnect.get('/clients/clients-identified', { params }).catch(generateError);
  },

  /**
   * Get client
   *
   * @param {number} id - client id
   *
   * @returns {Promise<Client>}
   * @typedef {Object} Client
   * @property {Number} id - client id
   * @property {String} name - client name
   * @property {String} surname - client surname
   * @property {String} patronymic - client patronymic
   * @property {String} avatar - avatar
   * @property {String} phone - client phone
   * @property {String} passport_id - passport id
   * @property {String} passport_type - passport type
   * @property {String} client_status.key - client status key
   * @property {String} client_status.body - client status body
   * @property {Number} reject_process.reject_reason_id - reject reason id
   * @property {Number} fix_reasons.id - client status id
   * @property {String} fix_reasons.body - fix reasons body
   *
   * @throws {Error}
   */
  getClient(id) {
    return mainConnect.get(`clients/clients/${id}`).catch(generateError);
  },

  /**
   * Send request to store client using common registration
   *
   * @param {Object} params
   * @param {String} params.passport_id - passport id
   * @param {String} params.phone - phone
   *
   * @returns {Promise}
   * @property {String} message - message
   *
   * @throws {Error}
   */
  clientRequestStoreCommon(params) {
    const link = 'clients/clients/create/request';
    if (link in requestQueue) {
      return requestQueue[link];
    }
    return (requestQueue[link] = mainConnect.post(link, params).catch(generateError)).finally(() => {
      delete requestQueue[link];
    });
  },

  /**
   * Store client using common registration
   *
   * @param {Object} params
   * @param {String} params.phone - phone
   * @param {String} params.name - name
   * @param {Number} params.otp - otp code
   * @param {String} params.passport_id - passport id
   *
   * @returns {Promise<Client>}
   * @typedef {Object} Client
   * @property {Number} id - client id
   *
   * @throws {Error}
   */
  clientStoreCommon(params) {
    return mainConnect.post(`clients/clients/create/verify`, params).catch(generateError);
  },

  /**
   * Get requirements
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Requirements>}
   * @typedef {Object} Requirements
   *
   * @throws {Error}
   */
  getRequirements(clientId) {
    const route = `/v2/clients/clients/create/${clientId}/requirements`;
    if (route in requestQueue) {
      return requestQueue[route];
    }
    return (requestQueue[route] = mainConnect.get(route).catch(generateError)).finally(() => {
      delete requestQueue[route];
    });
  },

  /**
   * Get client cards
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   *
   * @returns {Promise<Cards>}
   * @typedef {Array} Cards
   * @property {Number} id - id
   * @property {String} type - type
   *
   * @throws {Error}
   */
  getCards(params) {
    return mainConnect.get(`/cards`, { params }).catch(generateError);
  },

  /**
   * Request attach
   *
   * @param {Object} params
   * @param {Object} params.client_id - client id
   * @param {String} params.card_number - card number
   * @param {String} params.expire - expire
   * @param {Boolean} params.phone_match - true if client phone and phone the card is bound to
   *
   * @returns {Promise<Request>}
   * @typedef {Object} Request
   * @property {String} phone - phone
   *
   * @throws {Error}
   */
  requestAttach(params) {
    return mainConnect.post('/cards/request-attach', params).catch(generateError);
  },

  /**
   * Request dial
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   *
   * @returns {Promise<Request>}
   * @typedef {Object} Request
   * @property {String} message - message
   *
   * @throws {Error}
   */
  requestDial(params) {
    return mainConnect.post(`/cards/request-dial`, params).catch(generateError);
  },

  /**
   * Attach
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   * @param {String} params.code - code
   *
   * @returns {Promise<Card>}
   * @typedef {Object} Card
   * @property {Number} id - id
   * @property {Boolean} is_main - is main
   * @property {String} full_name - full name
   * @property {String} card_number - card number
   * @property {String} phone - phone
   * @property {String} expire - expire
   * @property {String} type - type
   *
   * @throws {Error}
   */
  attach(params) {
    return mainConnect.post(`/cards/attach`, params).catch(generateError);
  },

  /**
   * Get questionnaire
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Questionnaire>}
   * @typedef {Object} Questionnaire
   * @property {String} preferred_language - preferred language
   * @property {Number} activity_area_id - activity area id
   * @property {String} profession - profession
   *
   * @throws {Error}
   */
  getQuestionnaire(clientId) {
    return mainConnect.get(`clients/clients/${clientId}/questionnaire`).catch(generateError);
  },

  /**
   * Store questionnaire
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {Number} params.activity_area_id - activity area id
   * @param {String} params.preferred_language - preferred_language
   * @param {String} params.profession - profession
   *
   * @returns {Promise<Questionnaire>}
   * @typedef {Object} Questionnaire
   *
   * @throws {Error}
   */
  storeQuestionnaire(clientId, params = {}) {
    return mainConnect.post(`clients/clients/${clientId}/questionnaire`, params).catch(generateError);
  },

  /**
   * Update questionnaire
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {Number} params.activity_area_id - activity area id
   * @param {String} params.preferred_language - preferred_language
   * @param {String} params.profession - profession
   *
   * @returns {Promise<Questionnaire>}
   * @typedef {Object} Questionnaire
   *
   * @throws {Error}
   */
  updateQuestionnaire(clientId, params) {
    return mainConnect.put(`clients/clients/${clientId}/questionnaire`, params).catch(generateError);
  },

  /**
   * Get activity areas
   *
   * @returns {Promise<ActivityAreas>}
   * @typedef {Array} ActivityAreas
   * @property {Number} id - id
   * @property {String} body_uz - body uz
   * @property {String} body_ru - body ru
   *
   * @throws {Error}
   */
  getActivityAreas() {
    return mainConnect.get('clients/clients/questionnaire/activity-areas').catch(generateError);
  },

  /**
   * Get phones
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Phones>}
   * @typedef {Object} Phones
   * @property {String} main - main
   * @property {Array} phones
   * @property {Number} phones[].id - id
   * @property {String} phones[].body - body
   * @property {String} phones[].note - note
   * @property {String} phones[].name - name
   *
   * @throws {Error}
   */
  getPhones(clientId) {
    return mainConnect.get(`clients/clients/${clientId}/phones`).catch(generateError);
  },

  /**
   * Store phones
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {Array} params.phones - phones
   * @param {String} params.phones[].body - body
   * @param {String} params.phones[].note - note
   * @param {String} params.phones[].name - name
   * @param {Boolean} params.phones[].disabled - disabled
   *
   * @returns {Promise<Phones>}
   * @typedef {Array} Phones
   * @property {Number} id - id
   * @property {String} body - body
   * @property {String} note - note
   * @property {String} name - name
   *
   * @throws {Error}
   */
  storePhones(clientId, params) {
    return mainConnect.post(`clients/clients/${clientId}/phones/mass`, params).catch(generateError);
  },

  /**
   * Store phone
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {String} params.body - body
   * @param {String} params.note - note
   * @param {String} params.name - name
   *
   * @returns {Promise<Phone>}
   * @typedef {Object} Phone
   * @property {Number} id - id
   * @property {String} body - body
   * @property {String} note - note
   * @property {String} name - name
   *
   * @throws {Error}
   */
  storePhone(clientId, params) {
    return mainConnect.post(`clients/clients/${clientId}/phones`, params).catch(generateError);
  },

  /**
   * Update phone
   *
   * @param {Number} clientId - client id
   * @param {Number} phoneId - phone id
   * @param {Object} params
   * @param {String} params.body - body
   * @param {String} params.note - note
   * @param {String} params.name - name
   *
   * @returns {Promise<Phone>}
   * @typedef {Object} Phone
   * @property {Number} id - id
   * @property {String} body - body
   * @property {String} note - note
   * @property {String} name - name
   *
   * @throws {Error}
   */
  updatePhone(clientId, phoneId, params) {
    return mainConnect.put(`clients/clients/${clientId}/phones/${phoneId}`, params).catch(generateError);
  },

  /**
   * Store address
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {String} params.body - body
   * @param {String} params.note - note
   *
   * @returns {Promise<Address>}
   * @typedef {Object} Address
   * @property {Number} id - id
   * @property {String} body - body
   * @property {String} note - note
   *
   * @throws {Error}
   */
  storeAddresses(clientId, params) {
    return mainConnect.post(`clients/clients/${clientId}/addresses`, params).catch(generateError);
  },

  /**
   * Get addresses
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Addresses>}
   * @typedef {Object} Addresses
   * @property {String} main - main
   * @property {Array} addresses
   * @property {Number} addresses[].id - id
   * @property {String} addresses[].body - body
   * @property {String} addresses[].note - note
   *
   * @throws {Error}
   */
  getAddresses(clientId) {
    return mainConnect.get(`clients/clients/${clientId}/addresses`).catch(generateError);
  },

  /**
   * Get files
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Files>}
   * @typedef {Array} Files
   * @property {Number} id - id
   * @property {String} link - link
   * @property {Number} size - size
   * @property {String} file_type_key - file type key
   */
  getFiles(clientId) {
    const link = `clients/clients/${clientId}/files`;
    if (link in requestQueue) {
      return requestQueue[link];
    }
    return (requestQueue[link] = mainConnect.get(link).catch(generateError)).finally(() => {
      delete requestQueue[link];
    });
  },

  /**
   * Get passport
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Passport>}
   * @typedef {Object} Passport
   * @property {String} address - address
   * @property {String} birth_date - birthdate
   * @property {String} city_name - city name
   * @property {String} expiry_date - expiry date
   * @property {String} gender - gender
   * @property {String} issue_date - issue date
   * @property {String} name - name
   * @property {String} nationality_name - nationality name
   * @property {String} passport_id - passport id
   * @property {String} passport_issuer_name - passport issuer name
   * @property {String} patronymic - patronymic
   * @property {Number} pinfl - pinfl
   * @property {String} region - region
   * @property {String} surname - surname
   * @property {String} type - type
   * @property {String} mrz - mrz
   *
   * @throws {Error}
   */
  getPassport(clientId) {
    return mainConnect.get(`clients/clients/${clientId}/passport`).catch(generateError);
  },

  /**
   * Store passport
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {String} params.address - address
   * @param {String} params.birth_date - birth date
   * @param {String} params.city_name - city name
   * @param {String} params.expiry_date - expiry date
   * @param {String} params.gender - gender
   * @param {String} params.issue_date - issue date
   * @param {String} params.mrz - mrz
   * @param {String} params.name - name
   * @param {String} params.nationality_name - nationality name
   * @param {String} params.passport_id - passport id
   * @param {String} params.passport_issuer_name - passport issuer name
   * @param {String} params.patronymic - patronymic
   * @param {String} params.pinfl - pinfl
   * @param {String} params.region - region
   * @param {String} params.surname - surname
   * @param {String} params.type - type
   *
   * @returns {Promise<Passport>}
   * @typedef {Object} Passport
   *
   * @throws {Error}
   */
  storePassport(clientId, params) {
    return mainConnect.post(`clients/clients/${clientId}/passport`, params).catch(generateError);
  },

  /**
   * Update passport
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {String} params.birth_date - birth date
   * @param {String} params.city_name - city name
   * @param {String} params.expiry_date - expiry date
   * @param {String} params.gender - gender
   * @param {String} params.issue_date - issue date
   * @param {String} params.passport_issuer_name - passport issuer name
   * @param {String} params.name - name
   * @param {String} params.surname - surname
   * @param {String} params.patronymic - patronymic
   * @param {String} params.address - address
   * @param {String} params.nationality_name - nationality name
   * @param {String} params.region - region
   *
   * @returns {Promise<Passport>}
   * @typedef {Object} Passport
   *
   * @throws {Error}
   */
  updatePassport(clientId, params) {
    return mainConnect.put(`clients/clients/${clientId}/passport`, params).catch(generateError);
  },

  /**
   * Update passport
   *
   * @param {String} clientPhone - client phone
   * @param {File}   passportPhoto - passport photo
   *
   * @returns {Promise<FullUpdatePassport>}
   * @typedef {Object} FullUpdatePassport
   * @property {Object} result - result
   * @property {String} result.client_id - client id
   *
   * @throws {Error}
   **/
  fullUpdatePassport(clientPhone, passportPhoto, passportPhotoFace) {
    const formData = new FormData();

    formData.append('files[0][type]', 'PASSPORT');
    formData.append('files[0][file]', passportPhoto);

    formData.append('files[1][type]', 'PASSPORT_FACE');
    formData.append('files[1][file]', passportPhotoFace);

    return mainConnect.post(`/clients/clients/${clientPhone}/update-passport`, formData).catch(generateError);
  },

  /**
   * Update passport
   *
   * @param {String} clientPhone - client phone
   * @param {File}   idPassportFrontFile - passport front file
   * @param {File}   idPassportBackFile - passport back file
   *
   * @returns {Promise<FullUpdateIdPassport>}
   * @typedef {Object} FullUpdateIdPassport
   * @property {Object} result - result
   * @property {String} result.client_id - client id
   *
   * @throws {Error}
   **/
  fullUpdateIdPassport(clientPhone, idPassportFrontFile, idPassportBackFile, idPassportFace) {
    const formData = new FormData();

    formData.append('files[0][type]', 'ID_PASSPORT_FRONT');
    formData.append('files[0][file]', idPassportFrontFile);

    formData.append('files[1][type]', 'ID_PASSPORT_BACK');
    formData.append('files[1][file]', idPassportBackFile);

    formData.append('files[2][type]', 'ID_PASSPORT_FACE');
    formData.append('files[2][file]', idPassportFace);

    return mainConnect.post(`/clients/clients/${clientPhone}/update-id-passport`, formData).catch(generateError);
  },

  /**
   * Get passport issuers
   *
   * @returns {Promise<PassportIssuers>}
   * @typedef {Array} PassportIssuers
   * @property {String} name - name
   *
   * @throws {Error}
   */
  getPassportIssuers() {
    return mainConnect.get('/info/passport-issuers').catch(generateError);
  },

  /**
   * Send to ocr
   *
   * @param {String} passportType - type of passport
   * @param {Object} params
   * @param {String} params.type - type
   * @param {String} params.passport_url - passport_url || id_passport_url
   *
   * @returns {Promise<Ocr>}
   * @typedef {Object} Ocr
   * @property {Object} result - result
   * @property {String} result.passport_entities.name - name
   * @property {String} result.passport_entities.surname - surname
   * @property {String} result.passport_entities.father_name - father name
   * @property {String} result.passport_entities.passport_id - passport id
   * @property {String} result.passport_entities.pin - pin
   * @property {String} result.passport_entities.sex - sex
   * @property {String} result.passport_entities.date_of_birth - date of birth
   * @property {String} result.passport_entities.date_of_expiry - date of expiry
   * @property {String} result.passport_entities.date_of_issue - date of issue
   * @property {String} result.passport_entities.place_of_issue - place of issue
   * @property {String} result.passport_entities.place_of_birth - place of birth
   * @property {String} passport_mrz - mrz
   *
   * @throws {Error}
   */
  ocr(passportType, params) {
    const ocrRoutes = {
      id: 'ocr/id',
      passport: 'ocr/passport',
    };
    const route = ocrRoutes[passportType] || ocrRoutes.passport;
    return mainConnect
      .post(route, params)
      .then(res => {
        const data = { ...(res?.data?.result?.passport_entities || {}) };
        const passport = {};
        if (data?.code !== 'passport_is_not_readable') {
          passport.name = data?.name;
          passport.surname = data?.surname;
          passport.patronymic = data?.father_name;
          passport.passport_id = data?.passport_id;
          passport.pinfl = data?.pin;
          passport.gender = data?.sex;
          passport.birth_date = data?.date_of_birth;
          passport.expiry_date = data?.date_of_expiry;
          passport.issue_date = data?.date_of_issue;
          passport.passport_issuer_name = data?.place_of_issue;
          passport.city_name = data?.place_of_birth;
          passport.nationality_name = 'Uzbekistan';
          passport.mrz = res?.data?.result?.passport_mrz;
        }
        return Promise.resolve({ ...(res || {}), data: { ...passport } });
      })
      .catch(generateError);
  },

  /**
   * Get file
   *
   * @param {Number} clientId - client id
   * @param {Number} id - file id
   *
   * @returns {Promise}
   * @property {String} link - link
   *
   * @throws {Error}
   */
  getFile(clientId, id) {
    return mainConnect.get(`clients/clients/${clientId}/files/${id}`).catch(generateError);
  },

  /**
   * Remove file
   *
   * @param {Number} clientId - client id
   * @param {Number} id - file id
   *
   * @returns {Promise}
   *
   * @throws {Error}
   */
  removeFile(clientId, id) {
    return mainConnect.delete(`clients/clients/${clientId}/files/${id}`).catch(generateError);
  },

  /**
   * Upload file
   *
   * @param {Number} clientId - client id
   * @param {Object} params
   * @param {String} params.type - type
   * @param {BinaryData} params.file - file
   *
   * @returns {Promise<File>}
   * @typedef {Object} File
   * @property {Number} id - id
   * @property {String} link - link
   * @property {Number} size - size
   * @property {String} file_type_key - file type key
   *
   * @throws {Error}
   */
  uploadFile(clientId, params) {
    return mainConnect.post(`clients/clients/${clientId}/files`, params).catch(generateError);
  },

  /**
   * Get limit
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise<Limit>}
   * @typedef {Object} Limit
   * @property {Number} current_amount - current_amount
   */
  getLimit(clientId) {
    return mainConnect.get(`/clients/clients/${clientId}/limit`);
  },

  /**
   * Get cb agreement
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   * @param {Boolean} params.object - object
   *
   * @returns {Promise<cbAgreement>}
   * @typedef {Object} cbAgreement
   * @property {Boolean} active - active
   */
  cbAgreement(params) {
    return mainConnect.get(`/clients/cb_agreements`, { params });
  },

  /**
   * Request agreement
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   *
   * @returns {Promise<Request>}
   * @typedef {Object} Request
   *
   * @throws {Error}
   */
  requestAgreement(params) {
    return mainConnect.post(`/clients/cb_agreements/request-agreement`, params).catch(generateError);
  },

  /**
   * Store agreement
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   * @param {String} params.otp - otp code
   *
   * @returns {Promise<cbAgreement>}
   * @typedef {Object} cbAgreement
   *
   * @throws {Error}
   */
  storeAgreement(params) {
    return mainConnect.post(`/clients/cb_agreements/agree`, params).catch(generateError);
  },

  /**
   * Get pre-scoring
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise}
   */
  preScoring(clientId) {
    return mainConnect.get(`/clients/clients/${clientId}/pre-scoring`).catch(generateError);
  },

  /**
   * Get pre-scoring
   *
   * @param {Number} clientId - client id
   *
   * @returns {Promise}
   */
  requestReview(clientId) {
    return mainConnect.post(`/clients/clients/${clientId}/request-review`).catch(generateError);
  },

  /**
   * Get number of applications from merchant
   *
   * @param {Object} params
   * @param {Number} params.client_id - client id
   *
   * @returns {Promise<TodayApplicationsFromMerchantsResponse>}
   * @typedef {Object} TodayApplicationsFromMerchantsResponse
   * @property {Number} count - tells how many applications are created today
   */
  todayApplicationsFromMerchants(params) {
    return mainConnect.get(`/applications/counters/today-applications-from-merchants`, { params }).catch(generateError);
  },
};

export default clientsService;
