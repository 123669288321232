import { mainConnect, generateError } from '@/services';

export const merchantsService = {
  /**
   * Метод получения информации о приложение
   *
   * @return {Promise}
   * @throws {Error}
   */
  getApp() {
    return mainConnect.get('/v2/app').catch(generateError);
  },

  /**
   * Get merchant relations object
   *
   * @param {Object} params
   *
   * @returns {Promise<Relations>}
   * @typedef {Object} Relations
   * @property {Array} stores - merchant stores
   * @property {Number} stores[].id - store id
   * @property {String} stores[].name - store name
   * @property {Object} merchant - current merchant
   * @property {Number} merchant.id - merchant id
   * @property {String} merchant.name - merchant name
   * @property {Number} merchant.holding_initial_payment - can holding payment, 1 - can, 0 - can't
   * @property {Number} merchant.application_by_qr - has applications by qr
   * @property {String} merchant.token - merchant token
   * @property {Number} merchant.pays_vat - does merchant pay vat
   * @property {Object} store - current store
   * @property {Number} store.id - store id
   * @property {String} store.name - store name
   * @property {Number} store.dpd_delivery - has dpd delivery
   * @property {String} store.responsible_person - store responsible person
   * @property {Object} merchant_settings - merchant settings
   * @property {Boolean} merchant_settings.is_markable - tells if merchant must fill markings
   * @throws {Error}
   */
  getMerchantRelations(params) {
    return mainConnect.get('merchants/get-with-relations', { params }).catch(err => generateError(err));
  },

  /**
   * Check merchant active status
   *
   * @returns {Promise<Status>}
   * @typedef {Object} Status
   * @property {Boolean} params.active - If active - true, else - false
   * @throws {Error}
   */
  checkToActive() {
    return mainConnect.get('merchants/users/check-to-active-merchant').catch(generateError);
  },
};
