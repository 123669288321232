export default {
  features: {
    login: {
      btns: {
        login: 'Войти',
        resetPassword: 'Забыли пароль?',
        askAccount: ['Уже есть аккаунт?', 'Войдите здесь'],
        confirmSms: 'Подтвердить смс',
        restorePassword: 'Восстановить пароль',
        sendSms: 'Отправить СМС',
        repeatSmsSend: 'Прислать повторно',
        becomePartner: 'Партнер',
      },
      titles: {
        login: 'Войдите в свой аккаунт',
        support: 'Есть вопросы? Позвоните нам:',
        register: 'Регистрация',
        passwordTitle: 'Восстановление пароля',
        passwordSubTitle: 'На ваш номер будет выслан код подтверждения',
        sendCode: 'Код был выслан на номер {phone}',
        enterNewPassword: 'Введите новые пароли',
        restoredPassword: 'По вашему запросу был сброшен пароль для доступа.',
      },
      password: {
        maxLength: 'Не менее 8 символов',
        oneLowerCase: ' Как минимум одна заглавная и одна строчная буква',
        onlyLatin: 'Только латинские буквы',
        minOneNumber: 'Как минимум одна цифра',
      },
      fields: {
        rememberMe: 'Запомнить меня',
        phone: 'Номер телефона',
        password: 'Пароль',
        passwordRepeat: 'Повторите пароль',
        storeName: 'Название магазина',
        region: 'Регион',
        regionValue: 'Выберите регион',
        aboutStore: 'Расскажите о вашем магазине',
        name: 'Ваше имя',
        smsCode: 'Код из СМС',
      },
      errors: {
        passwordMismatch: 'Пароли не совпадают',
      },
    },
  },
};
