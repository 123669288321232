<template>
  <div class="flex flex-col items-center justify-center min-h-screen max-w-full">
    <svg-offline class="w-full max-w-2xl"></svg-offline>
    <div class="mt-6 text-center">
      <h4 class="text-xl font-medium text-primary">{{ $t('system.errors.offline') }}</h4>
    </div>
  </div>
</template>

<script>
  import SvgOffline from '../components/SvgOffline';
  export default {
    name: 'OfflinePage',
    components: { SvgOffline },
  };
</script>

<style scoped></style>
