import AuthLayout from './layouts/AuthLayout';
import RegistrationLayout from './layouts/RegistrationLayout';

import auth from '../auth/routers';
import registration from '../registration/routers';

const authRoutes = {
  path: '/auth',
  component: AuthLayout,
  children: [...auth],
};

const registrationRoutes = {
  path: '/registration',
  component: RegistrationLayout,
  children: [...registration],
};

export default router => {
  router.addRoute(authRoutes);
  router.addRoute(registrationRoutes);
};
