export default {
  titles: {
    home: 'Assalomu alaykum, {name}',
    totalNumber: 'Imzolangan arizalar',
    totalSum: "Berilgan muddatli to'lovlar",
    newClients: 'Yangi mijozlar',
  },
  createClient: {
    title: 'Yangi mijoz yaratish',
    body: "Siz yangi mijoz A'zo statusida yaratishingiz mumkin",
    btn: 'Mijoz yaratish',
  },
  contactNumber: {
    text: "Savollaringiz bormi? Bizga qo'ng'iroq qiling:",
  },
  theme: {
    title: 'Rejim',
    light: 'Kunduzgi rejim',
    dark: 'Tungi rejim',
  },
};
