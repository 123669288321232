// todo refactor module name
const moduleName = 'module_azo';

import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/rejects',
    name: ROUTE_NAMES.rejectsList,
    component: () => import('../views/NasiyaRejectsIndexPage'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.rejectsListToFsd,
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Возвраты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/rejects/to-fsd',
    name: ROUTE_NAMES.rejectsListToFsd,
    component: async () => {
      const { RejectCasesListPage } = await import('@/to-fsd/pages/reject-cases-list');

      return RejectCasesListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Возвраты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/rejects/:id',
    name: ROUTE_NAMES.rejectsShow,
    component: () => import('../views/NasiyaRejectsShowPage'),
    redirect: to => {
      return {
        name: ROUTE_NAMES.rejectsShowToFsd,
        params: {
          rejectCaseId: to.params.id,
        },
      };
    },
    meta: {
      middlewares: [
        setDocumentTitle('Возвраты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/rejects/:rejectCaseId/to-fsd',
    name: ROUTE_NAMES.rejectsShowToFsd,
    component: async () => {
      const { RejectCaseShowPage } = await import('@/to-fsd/pages/reject-case-show');

      return RejectCaseShowPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Возвраты'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/rejects/create/:applicationId',
    name: ROUTE_NAMES.rejectsCreate,
    redirect: to => {
      return {
        name: ROUTE_NAMES.rejectsCreateToFsd,
        params: {
          applicationId: to.params.applicationId,
        },
      };
    },
    component: () => import('../views/NasiyaRejectsCreatePage'),
    meta: {
      middlewares: [
        setDocumentTitle('Новый возврат'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
  {
    path: '/nasiya/applications/:applicationId/rejects/new',
    name: ROUTE_NAMES.rejectsCreateToFsd,
    component: async () => {
      const { CreateRejectPage } = await import('@/to-fsd/pages/create-reject-page');

      return CreateRejectPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Новый возврат'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
    props: true,
  },
];
