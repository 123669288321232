export default {
  titles: {
    analytics: 'Analitika',
  },
  panel: {
    titles: {
      applicationsCount: 'Arizalar soni',
      sales: 'Sotuvlar',
      salesByDate: 'Sotuvlar',
      salesByStores: "Do'konlar bo'yicha sotuvlar",
      applicationsMean: "O'rtacha check",
      demography: 'Demografiya',
      consultants: 'Konsultant',
      popularCategories: 'Ommabop tofalar',
      popularItems: 'Ommabop maxsulotlar',
      applicationsSum: 'Arizalar qiymati',
    },
  },
  tabs: {
    sales: 'Sotuvlar',
    applications: 'Arizalar',
    applicationsMean: "O'rtacha chek",
    popularCategories: 'Ommabop toifalar',
    popularItems: 'Ommabop mahsulotlar',
    demography: 'Demografiya',
    consultants: 'Konsultant',
    monthly: 'Oyiga',
    yearly: 'Yiliga',
  },
  select: {
    labels: {
      source: 'Manba',
      store: "Do'kon",
      consultant: 'Konsultant',
      applicationStatus: 'Ariza holati',
      period: 'Davr',
      month: 'Oy',
      year: 'Yil',
      age: 'Yosh',
      limit: 'Jadvaldagi soni:',
    },
    options: {
      loading: 'Yuklanmoqda...',
      all: 'Barchasi',
      months: [
        '',
        'Yanvar',
        'Fevral',
        'Mart',
        'Aprel',
        'May',
        'Iyun',
        'Iyul',
        'Avgust',
        'Sentabr',
        'Oktabr',
        'Noyabr',
        'Dekabr',
      ],
      ages: ['18-25', '26-32', '33-40', '41-55', '56 va katta'],
    },
    titles: {
      choose: 'Qiymatni tanlang',
      consultant: 'Konsultant',
      store: "Do'kon nomini kiriting",
    },
  },
  table: {
    headers: {
      titles: {
        items: 'Tovar',
        sales: 'Sotuvlar',
        salesSum: 'Savdo summasi',
        price: 'Narxi',
        category: 'Toifa',
        categorySum: 'Sotuvlar soni',
      },
    },
  },
  chart: {
    labels: {
      problematicCredits: "Muammoli muddatli to'lovlar",
      approvedApplications: 'Rasmiylashtirilgan arizalar',
      female: 'Ayollar',
      male: 'Erkaklar',
    },
  },
  errors: {
    noData: "Ushbu so'rov bo'yicha ma'lumot topilmadi",
  },
  radio: {
    labels: {
      sum: 'Qiymati',
      count: 'Soni',
    },
  },
};
