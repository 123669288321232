export default {
  index: {
    title: 'Yetkazmalar',
    titles: {
      delivery: 'Yetkazmalar',
    },
    headers: {
      client: 'Mijoz',
      status: 'Holat',
      date: 'Sana',
    },
  },
  show: {
    title: 'Yetkazma #{id}',
    generate: 'Dalolatnoma yaratish',
    showContract: 'Dalolatnoma',
    contractLanguage: {
      ru: 'Rus tilida',
      uz: "O'zbek tilida",
    },
    address: 'Manzil',
    nearby: "Mo'ljal",
    note: 'Eslatma',
    dpd: {
      title: {
        sender: 'Yuboruvchi manzili',
        receiver: 'Qabul qiluvchi manzili',
      },
    },
    approximateTime: 'Tahminiy yetkazib berish vaqti',
  },
  client: {
    name: 'Mijoz ismi',
    phone: 'Telefon raqami',
  },
  dpd: {
    create: {
      title: {
        header: 'Alif yetkazmasi',
        sender: 'Yuboruvchi',
        receiver: 'Qabul qiluvchi',
        parcel: 'Yetkazma',
        schedule: 'Ish kunlari',
        chooseSchedule: 'Buyurtma berish nuqtasini tanlang',
      },
      form: {
        deliveryType: 'Yetkazib berish turi',
        placeholder: 'Qiymatni tanlang',
        ENTER_ENTER: 'Eshikdan eshikgacha',
        ENTER_POINT: 'Eshikdan tortib olish punktigacha',
        region: 'Viloyat',
        district: 'Tuman',
        terminal: 'Buyurtma berish nuqtasi',
        city: 'Aholi yashash punkti',
        street: "Ko'cha",
        name: 'FISh',
        phone: 'Telefon raqami',
        datePickup: 'Yetkazmani olib ketish sanasi',
        singleWeight: 'Bitta yetkazma vazni (kg)',
        singleVolume: 'Bitta yetkazma hajmi (metr kub)',
        totalWeight: 'Yetkazma umumiy vazni (kg)',
        totalVolume: 'Yetkazma umumiy hajmi (metr kub)',
        save: 'Saqlash',
        daysOfTheWeek: {
          'monday': 'Dush',
          'tuesday': 'Sesh',
          'wednesday': 'Chor',
          'thursday': 'Pay',
          'friday': 'Jum',
          'saturday': 'Shan',
          'sunday': 'Yak',
        },
        weekend: 'Dam olish kuni',
      },
      alerts: {
        success: 'Yetkazma muvaffaqiyati yaratildi',
        info: 'Omborxonalar manzillari mavjud emas',
      },
      btns: {
        save: 'Saqlash',
        cancel: 'Bekor qilish',
      },
    },
  },
};
