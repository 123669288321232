const formatPhone = phone => {
  if (!phone) {
    throw new Error('phone is empty');
  }

  const countryCode = `+${phone.substring(0, 3)}`;
  const companyCode = `(${phone.substring(3, 5)})`;
  const number = `${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10)}`;

  return `${countryCode} ${companyCode} ${number}`;
};

export default formatPhone;
