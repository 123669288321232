import { balancesService } from '../services/balances.service';

export const actions = {
  async fetchTopUpsStatuses() {
    if (!this.topUpsStatuses) {
      const response = await balancesService.getTopUpsStatuses();
      this.topUpsStatuses = response?.data
        ? {
            ...response.data,
            'UNSUCCESS': {
              ru: 'Неуспешно',
              uz: 'Muvaffaqiyatsiz',
              en: 'Unsuccess',
            },
          }
        : [];
    }
    return Promise.resolve(this.topUpsStatuses);
  },
  async fetchTopUpsServices() {
    if (!this.topUpsServices) {
      const response = await balancesService.getTopUpServices();
      this.topUpsServices = response?.data;
    }
    return Promise.resolve(this.topUpsStatuses);
  },
  async fetchStores() {
    if (!this.stores) {
      const response = await balancesService.getMerchantsStores();
      this.stores = response?.data.stores;
      this.merchant = response?.data.merchant;
    }
    return Promise.resolve(this.topUpsStatuses);
  },
  async fetchTopUps(params) {
    const response = await balancesService.getTopUps(params);
    this.topUps = response?.data;
  },
  addCancelingToQueue(id) {
    this.cancelingIntervals.add(id);
  },
  clearAllCancelingIntervals() {
    this.cancelingIntervals.forEach(e => {
      clearInterval(e);
    });
    this.cancelingIntervals = new Set();
  },
};
