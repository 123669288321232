import { createPinia } from 'pinia';
import { useUserStore } from '@/modules/main/user/stores';
import { useRejectCaseStore } from '@/modules/nasiya/rejects/stores';
import { useProblemCaseStore } from '@/modules/nasiya/problems/stores';
import { useCreateApplicationStore } from '@/modules/nasiya/applications/stores/create-application';
import { useApplicationStore } from '@/modules/nasiya/applications/stores/application';
import { useAnalyticsStore } from '@/modules/nasiya/analytics/stores';
import { useClientStore } from '@/modules/nasiya/clients/stores';
import { useNasiyaGlobalStore } from '@/modules/nasiya/common/stores';
import { useMainStore } from '@/modules/main/common/stores';
import { useNotificationStore } from '@/modules/nasiya/notifications/stores';

const stores = createPinia();

export default stores;

export const resetStores = () => {
  const user = useUserStore();
  const rejectCaseStore = useRejectCaseStore();
  const problemCaseStore = useProblemCaseStore();
  const createApplicationStore = useCreateApplicationStore();
  const applicationStore = useApplicationStore();
  const analyticsStore = useAnalyticsStore();
  const clientStore = useClientStore();
  const globalStore = useNasiyaGlobalStore();
  const mainStore = useMainStore();
  const notificationStore = useNotificationStore();

  user.$reset();
  rejectCaseStore.$reset();
  problemCaseStore.$reset();
  createApplicationStore.$reset();
  applicationStore.$reset();
  analyticsStore.$reset();
  clientStore.$reset();
  globalStore.$reset();
  mainStore.$reset();
  notificationStore.$reset();
};
