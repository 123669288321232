// todo refactor module name
const moduleName = 'module_azo';

import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

export default [
  {
    path: '/nasiya/calculator',
    name: ROUTE_NAMES.calculator,
    redirect: () => {
      return {
        name: ROUTE_NAMES.calculatorToFsd,
      };
    },
    component: () => import('../views/NasiyaCalculatorIndexPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Калькулятор'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/calculator/to-fsd',
    name: ROUTE_NAMES.calculatorToFsd,
    component: async () => {
      const { CalculatorPage } = await import('@/to-fsd/pages/calculator');

      return CalculatorPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Калькулятор'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
        fetchNasiyaResources,
      ],
    },
  },
];
