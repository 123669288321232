import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      me: null,
    };
  },
  actions,
  getters,
});
