import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';

export default [
  {
    path: 'login',
    name: ROUTE_NAMES.login,
    component: () => import('../views/AuthPage'),
    meta: { middlewares: [setDocumentTitle('Авторизация')] },
    redirect: () => {
      return {
        name: ROUTE_NAMES.loginToFsd,
      };
    },
  },
  {
    path: 'login/to-fsd',
    name: ROUTE_NAMES.loginToFsd,
    component: async () => {
      const { LoginPage } = await import('@/to-fsd/pages/login');

      return LoginPage;
    },
    meta: { middlewares: [setDocumentTitle('Авторизация')] },
  },
  {
    path: 'recover-password',
    name: ROUTE_NAMES.recoverPassword,
    component: () => import('../views/AuthRecoverPassword'),
    redirect: () => {
      return {
        name: ROUTE_NAMES.recoverPasswordToFsd,
      };
    },
    meta: { middlewares: [setDocumentTitle('Восстоновление пароля')] },
  },
  {
    path: 'recover-password/to-fsd',
    name: ROUTE_NAMES.recoverPasswordToFsd,
    component: async () => {
      const { RecoverPasswordPage } = await import('@/to-fsd/pages/recover-password');

      return RecoverPasswordPage;
    },
    meta: { middlewares: [setDocumentTitle('Авторизация')] },
  },
];
