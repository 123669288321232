<template>
  <div v-for="(error, index) in errors" :key="`app-client-create-new-common-form-passport_id-error-${index}`">
    <p class="text-red-600 dark:text-red-500 text-sm mt-1">{{ error.$message }}</p>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseFieldErrors',
    props: { errors: { type: Array, default: () => [] } },
  });
</script>
