export default {
  statuses: {
    CHARGE: 'Tasdiqlandi',
    NOT_APPROVED: 'Tasdiqlanmadi',
    REJECT: 'Qaytarish uchun hisob',
  },
  index: {
    introductionModal: {
      title: 'Taklifnoma',
      body: [
        'Hurmatli hamkorlar va konsultantlar',
        'Biz siz uchun yangi bonuslarimizni ishga tushirmoqdamiz!',
        "Endi siz rasmiylashtirgan arizalaringiz uchun alif mobi ilovasida qo'shimcha bonuslaringizni olishingiz mumkin.",
      ],
      confirm: 'Tushunarli',
    },
    tip: "Bonuslar haqida ko'proq ma'lumot olishingiz mumkin",
    title: 'Bonuslar',
    dropdown: {
      title: 'Bu qanday ishlaydi?',
      menu: {
        title: 'Talablar roʻyxati:',
      },
    },
    filters: {
      bonusStatus: 'Bonus holati',
      dateCreated: 'Yaratilgan sana',
      dateAccrual: 'Hisobga olingan sana',
      clear: 'Tozalash',
      allStatuses: 'Barcha holatlar',
    },
    card: {
      title: "Umumiy o'tkazilgan bonus miqdori",
      sum: 'soʻm',
      statuses: {
        charged: 'Barcha tasdiqlanishlar',
        toPay: 'Hisoblash uchun tayyor',
        notApproved: 'Tasdiqlanmagan',
        reject: 'Aytarish uchun hisobdan chiqarildi',
      },
      desc: {
        total:
          "Bonus tizimiga ulangan paytdan boshlab umumiy o'tkazilgan bonus miqdori (ma'lumotlar har oyning 1 va 16-kunlarida yangilanadi)",
        charge: 'Rasmiylashtirilgan ariza va alif mobida faol sessiya mavjud',
        notApproved: "Rasmiylashtirilgan ariza bor, ammo alif mobida faol sessiya yo'q",
        reject: "Rasmiylashtirilgan ariza qaytarilganda alif mobida ilgari o'tkazilgan bonus miqdori qaytarib olinishi",
        toPay:
          'Oxirgi hisoblash paytidan buyon yig‘ilgan bonuslar. Keyingi hisoblashdan keyin hisoblagich nolga qaytariladi',
      },
    },
    table: {
      cols: {
        applicationAmount: 'RASMIYLASHTIRILGAN ARIZA SUMMASI',
        cashbackAmount: 'BONUS',
        contractDate: 'RASMIYLASHTIRILGAN SANA',
        chargedAt: "BONUS O'TKAZILGAN SANA",
        status: 'HOLAT',
      },
      total: 'Jami',
      paginationInfo: "Natijalar {from} dan {to} gacha {total} ta ko'rsatilmoqda",
    },
  },
};
