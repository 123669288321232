<template>
  <at-input v-model="value" v-money="money" v-bind="$attrs">
    <template #addOnAfter>
      {{ $t('system.sum') }}
    </template>
    <template v-if="isCustomLabel" #label>
      <slot name="label"></slot>
    </template>
  </at-input>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseMoney',
  });
</script>

<script setup>
  import { unformat } from '@/directives/money/utils';
  import { computed, defineAsyncComponent } from 'vue';

  const props = defineProps({
    isCustomLabel: { type: Boolean, default: false },
    modelValue: {
      type: [String, Number],
      default: () => '',
    },
    money: {
      type: Object,
      default: () => ({
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false /* doesn't work with directive */,
      }),
    },
  });

  const emit = defineEmits(['update:model-value']);

  const value = computed({
    get: () => {
      return props.modelValue;
    },
    set: value => {
      emit('update:model-value', unformat(value, props.money?.precision));
    },
  });

  const AtInput = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtInput/AtInput'));
</script>
