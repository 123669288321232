export default {
  widgets: {
    nasiyaSearchClients: {
      phoneInput: {
        label: 'Номер телефона',
      },
      find: 'Найти клиента',
    },
  },
};
