export default {
  pages: {
    calculator: {
      title: 'Kalkulyator',
      item: {
        label: 'Tovar nomi',
        placeholder: 'Tovar nomi va artikuli',
        hint: 'Tovar nomi va artikulini kiriting',
      },
      applicationSum: {
        label: 'Ariza miqdori',
        placeholder: '0',
      },
      downPaymentAmount: {
        label: "Oldindan to'lov miqdori",
        placeholder: '0',
      },
    },
  },
};
