import * as Sentry from '@sentry/vue';

export default {
  install(app) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        logErrors: true,
        attachProps: true,
        attachStacktrace: true,
        ignoreErrors: [
          'Avoided redundant navigation to current location',
          /Navigation cancelled from .* to .* with a new navigation/,
          'Navigation cancelled from',
        ],
        release: process.env.VUE_APP_COMMIT_HASH,
      });
    }
  },
};
