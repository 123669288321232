import auth from '../auth/i18n';
import registration from '../registration/i18n';
import sidebar from '../common/i18n/sidebar';
import system from '../common/i18n/system';
import validations from '../common/i18n/validations';
import otpInfoBlock from './i18n/otpInfoBlock';

export default {
  auth,
  registration,
  sidebar,
  system,
  validations,
  otpInfoBlock,
};
