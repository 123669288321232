import { mainConnect, generateError } from '@/services';

export const notificationsService = {
  /**
   * Get notifications list
   *
   * @param {Object} params
   * @param {Number} params.fresh - send `1` to get fresh notifications
   *
   * @returns {Promise<Notifications>}
   * @typedef {Object} Notifications
   * @property {String} data[].title_uz - uz title
   * @property {String} data[].title_ru - ru title
   * @property {String} data[].body_uz - uz content
   * @property {String} data[].body_ru - ru content
   * @property {Date} data[].created_at - created at date
   * @property {Number} meta.last_page - last page
   * @throws {Error}
   */
  getNotifications(params) {
    return mainConnect.get('notifications', { params }).catch(generateError);
  },

  /**
   * Get notifications counter
   *
   * @param {Object} params
   *
   * @returns {Promise<Counter>}
   * @typedef {Object} Counter
   * @property {String} count - count notifications
   * @throws {Error}
   */
  getNotificationsCounter(params) {
    return mainConnect.get('notifications/counter', { params }).catch(generateError);
  },
};
