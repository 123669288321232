import mainConnect, { generateError } from '@/services';

export const getCashbacksFromMerchantUser = (authId, params) => {
  return mainConnect
    .get(`/credits/cashback/${authId}/cashback-details-from-merchant-user`, { params })
    .catch(generateError);
};

export const getCashbacksGeneralAmountFromMerchantUser = authId => {
  return mainConnect.get(`/credits/cashback/${authId}/general-amount-from-merchant-user`).catch(generateError);
};
