export default {
  features: {
    employeeTogglePermissions: {
      panel: {
        title: 'Настройка доступов',
      },
      merchant_acquiring: 'Управление с эквайринг',
      merchant_admin: 'Админ панель',
      merchant_alifnasiya: 'Управление с алифнасия',
      merchant_alifshop: 'Управление с алифшоп',
      merchant_applications: 'Управление с заявками',
      merchant_deliveries: 'Управление с доставкой',
      merchant_manager: 'Управление с сотрудниками',
      merchant_moderation_goods: 'Управление с модерацией товаров',
      merchant_scoring_card: 'Управление скоринг карт',
      merchant_upload_goods: 'Управление с товарами',
    },
  },
};
