import { CLIENT_FILE_TYPE } from '@/to-fsd/entities/client-file';

export default {
  pages: {
    uploadClientFile: {
      desc: 'Fayl yuklash',
      save: 'Saqlash',
      retry: 'Qayta suratga olish',
      takePhoto: 'Suratga olish',
      successMessage: 'Fayl yuklandi',
      fileTypeText: {
        [CLIENT_FILE_TYPE.ID_PASSPORT_FACE]: 'ID karta mijoz yuzi yonida',
        [CLIENT_FILE_TYPE.PASSPORT_FACE]: 'Pasport mijoz yuzi yonida',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_DAILY_INCOME]: 'Taksi haydovchisining kunlik daromadi',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_WEEKLY_INCOME]: 'Taksi haydovchisining haftalik daromadi',
        [CLIENT_FILE_TYPE.TAXI_DRIVER_MONTHLY_INCOME]: 'Taksi haydovchisining oylik daromadi',
        [CLIENT_FILE_TYPE.MILITARY_CERTIFICATE]: 'Guvohnoma',
        [CLIENT_FILE_TYPE.PASSPORT]: 'Pasport rasmi',
        [CLIENT_FILE_TYPE.ID_PASSPORT_FRONT]: 'ID karta old tomoni rasmi',
        [CLIENT_FILE_TYPE.ID_PASSPORT_BACK]: 'ID karta orqa tomoni rasmi',
        [CLIENT_FILE_TYPE.COMMON]: 'Qoʻshimcha fayl',
        [CLIENT_FILE_TYPE.MONEY_TRANSFER_RECIPIENT]: "Pul o'tkazmalarini oluvchi",
      },
    },
  },
};
