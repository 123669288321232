export default {
  sum: 'сум',
  reset: 'Сбросить',
  btns: {
    delete: 'Удалить',
    goToMainPage: 'Вернуться на главную',
    reload: 'Обновить страницу',
  },
  titles: {
    all: 'Все',
    redirectToModule: 'Редирект на модуль...',
    copy: 'Скопировано!',
    copyError: 'Не смогли скопировать!',
  },
  errors: {
    noData: 'По данному запросу ничего не найдено',
    notSpecified: 'Не указано',
    somethingWentWrong: 'Что-то пошло не так. Попробуйте еще раз',
    clientHasNoApplications: 'У клиента еще не было заявок',
    goToApplicationsPageAlert:
      'Если хотите увидеть оформленные заявки, вы можете найти их в разделе «Заявки», во вкладке "Оформлено" используя поиск',
    noStatus: 'Статус не указан',
    noComments: 'Нет комментариев',
    network: 'Ошибка сети',
    pageNotFound: 'Страница не найдена',
    objectNotFound: 'Объект не найден',
    notAllowed: 'Недостаточно прав',
    offline: 'Нет доступных подключений к интернету, пожалуйста, проверьте соединение',
    serverError: 'Сервер не отвечает',
    accessDenied: 'Нет доступа в систему',
    modulesAccessDenied: 'Нет доступа к модулям',
    moduleAccessDenied: 'Доступ к модулю запрещен',
    errorWithUnknownCode: 'Ошибка с неизвестным кодом',
    badRequest: 'Неверный запрос',
    unauthorized: 'Неавторизованный',
    internalError: 'Внутренняя ошибка',
    notFound: 'Не найден',
    reversalTimeExpired: 'Время отмены истекло',
    unprocessableEntity: 'Необрабатываемый экземпляр',
    invalidOtp: 'Неверный ОТП код',
    invalidCardCredentials: 'Недействительные учетные данные карты',
    expiredCard: 'Истек срок действия карты',
    noSmsNotifications: 'Не подключен интернет-банкинг',
    duplication: 'Дублирование',
    otpTriesExceeded: 'Превышено число попыток ОТП кода',
    cardBlocked: 'Карта заблокирована',
  },
  success: {
    saved: 'Данные успешно сохранены',
    updated: 'Данные успешно обновлены',
    uploaded: 'Успешно добавлено',
    deleted: 'Успешно удалено',
    edited: 'Успешно изменено',
    identified: 'Успешно идентифицирован',
  },
  placeholders: {
    search: 'Поиск...',
  },
  confirmation: {
    code: 'Проверочный код был отправлен на номер {phone}',
    call: 'Звонок заказан на номер {phone}',
    codeWillBeSent: 'На номер {phone} будет отправлена смс на подтверждение',
    confirm: 'Подтвердить',
    cancel: 'Отменить',
    orderCall: 'Заказать звонок',
    process: 'Оформить',
  },
  reload: {
    siteHasBeenUpdated: 'Были добавлены некоторые изменения',
    pleaseUpdate: 'Пожалуйста, обновитесь для корректной работы сайта',
    updateNow: 'Обновить сейчас',
    updateLater: 'Обновить позже',
  },
  contractCountReminder: {
    title: 'Количество не загруженных актов - {count}',
    subtitle: 'Пожалуйста, не забудьте загрузить скан всех актов',
    button: 'Ок',
  },
};
