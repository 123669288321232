import { h } from 'vue';

export default {
  name: 'BaseCountdown',
  data() {
    return {
      count: {
        hour: 0,
        minute: 0,
        second: 0,
      },
      timer: -1,
    };
  },
  props: {
    time: { type: [String, Number], default: () => new Date().getTime() },
  },
  watch: {
    time: function(time) {
      if (time && new Date().getTime() < time) {
        this.start();
      }
    },
  },
  methods: {
    stop() {
      if (this.timer !== -1) {
        clearInterval(this.timer);
        this.timer = -1;
      }
    },
    start() {
      this.stop();
      this.timer = setInterval(() => {
        const result = this.updateTime();
        if (result <= 1) {
          this.stop();
          this.$emit('end');
        }
      }, 1000);
    },
    updateTime() {
      const result = (this.time - new Date().getTime()) / 1000;
      let hour = Math.floor(result / 60 / 60);
      const minute = (() => {
        let res = Math.floor(result / 60);
        if (res >= 60) {
          res -= Math.floor(res / 60) * 60;
        }
        return res;
      })();
      const second = Math.floor(result - minute * 60);
      this.count = {
        hour,
        minute,
        second,
      };
      return result;
    },
  },
  created() {
    this.updateTime();
    if (this.time && new Date().getTime() < this.time) {
      this.start();
    }
  },
  render() {
    return h(
      'div',
      { class: 'inline-block' },
      this.$slots?.default?.({ ...this.count, locale: this?.$t?.bind?.(this) ?? new Function('', '') })
    );
  },
};
