import { ROUTE_NAMES } from '../../common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

// todo refactor module name
const moduleName = 'module_azo';

export default [
  {
    path: '/nasiya/sales',
    redirect: () => {
      return {
        name: ROUTE_NAMES.salesListToFsd,
      };
    },
    name: ROUTE_NAMES.salesList,
    component: () => import('../views/NasiyaSalesIndexPage'),
    meta: {
      middlewares: [
        setDocumentTitle('Продажи'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
  {
    path: '/nasiya/sales/to-fsd',
    name: ROUTE_NAMES.salesListToFsd,
    component: async () => {
      const { SalesListPage } = await import('@/to-fsd/pages/sales-list');

      return SalesListPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Продажи'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_alifnasiya', 'merchant_manager']),
        fetchNasiyaResources,
      ],
    },
  },
];
