export default {
  features: {
    applicationEditFirstPaymentDate: {
      editFirstPaymentDateButton: 'Изменить дату оплаты',
      modal: {
        title: 'Укажите новую дату оплаты',
        cancel: 'Отмена',
        confirm: 'Подтвердить',
      },
    },
  },
};
