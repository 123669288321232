import { useNasiyaGlobalStore } from '@/modules/nasiya/common/stores';
import { useProblemCaseStore } from '@/modules/nasiya/problems/stores';

const fetchNasiyaResources = async ({ next }) => {
  const nasiyaGlobalStore = useNasiyaGlobalStore();
  const problemCaseStore = useProblemCaseStore();

  if (!nasiyaGlobalStore.app) {
    await nasiyaGlobalStore.fetchApp();
  }
  if (!nasiyaGlobalStore.merchantRelations) {
    await nasiyaGlobalStore.fetchMerchantRelations();
  }
  if (!problemCaseStore.problemStatuses) {
    await problemCaseStore.fetchProblemsStatuses();
  }

  return next();
};

export default fetchNasiyaResources;
