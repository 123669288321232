export default {
  sum: "so'm",
  reset: 'tozalash',
  btns: {
    delete: "O'chirish",
    goToMainPage: 'Bosh sahifaga qaytish',
    reload: 'Sahifani qayta yuklash',
  },
  titles: {
    all: 'Barchasi',
    redirectToModule: "Modulga o'tilmoqda...",
    copy: 'Kochirildi',
    copyError: 'Kochira olmadiz!',
  },
  errors: {
    noData: "Sizning so'rovingiz bo'yicha ma'lumot topilmadi",
    notSpecified: "Ko'rsatilmagan",
    somethingWentWrong: "No'malum xatolik yuz berdi. Qayta urinib ko'ring",
    clientHasNoApplications: 'Mijoz hali ariza bermagan',
    goToApplicationsPageAlert: `Rasmiylashtirilgan arizalarni ko'rmoqchi bo'lsangiz, "Arizalar" bo'limi orqali "Rasmiylashtirilgan" arizalarda faqat qidiruv orqali topishingiz mumkin`,
    noStatus: "Status ko'rsatilmagan",
    noComments: "Hech qanday izoh yo'q",
    network: 'Tarmoq xatosi',
    pageNotFound: 'Sahifa topilmadi',
    objectNotFound: 'Obyekt topilmadi',
    notAllowed: 'Huquqlar yetarli emas',
    offline: 'Internetga ulanish mavjud emas, iltimos, aloqani tekshiring',
    serverError: 'Serverda xatolik',
    accessDenied: "Tizimga kirishga ruxsat yo'q",
    modulesAccessDenied: "Modullarga kirishga ruxsat yo'q",
    moduleAccessDenied: "Modulga kirishga ruxsat yo'q",
    errorWithUnknownCode: "Noma'lum kod bilan xatolik yuz berdi",
    badRequest: "Noto'gri so'rov",
    unauthorized: 'Ruxsat berilmagan',
    internalError: 'Ichki xatolik',
    notFound: 'Topilmadi',
    reversalTimeExpired: 'Bekor qilish vaqti tugadi',
    unprocessableEntity: 'Ishlov berilmaydigan obyekt',
    invalidOtp: "Noto'g'ri OTP kod",
    invalidCardCredentials: "Karta ma'lumotlari noto'g'ri",
    expiredCard: 'Muddati tugagan karta',
    noSmsNotifications: 'Internet banking xizmati ulanmagan',
    duplication: 'Dublikatsiya',
    otpTriesExceeded: 'OTP kod urinishlari oshib ketti',
    cardBlocked: 'Karta bloklangan',
  },
  success: {
    saved: 'Maʼlumotlar muvaffaqiyatli saqlandi',
    updated: 'Maʼlumotlar muvaffaqiyatli yangilandi',
    uploaded: 'Muvaffaqiyatli qoʻshildi',
    deleted: 'Muvaffaqiyatli oʻchirib tashlandi',
    edited: 'Muvaffaqiyatli tahrirlandi',
    identified: 'Muvaffaqiyatli identifikatsiyalandi',
  },
  placeholders: {
    search: 'Izlash...',
  },
  confirmation: {
    code: '{phone} raqamiga tasdiqlash kodi yuborildi',
    call: "{phone} raqamiga qo'ng'iroq buyurtma qilindi",
    codeWillBeSent: 'Tasdiqlash uchun {phone} raqamiga SMS yuboriladi',
    confirm: 'Tasdiqlash',
    cancel: 'Bekor qilish',
    orderCall: "Qo'ng'iroqni buyurtma qilish",
    process: 'Rasmiylashtirish',
  },
  reload: {
    siteHasBeenUpdated: 'Sayt yangilandi',
    pleaseUpdate: 'Iltimos, tizimda nosozliklar kuzatilmasligi uchun saytni qayta yuklang',
    updateNow: 'Hoziroq qayta yuklash',
    updateLater: 'Keyinroq qayta yuklash',
  },
  contractCountReminder: {
    title: 'Yuklanmagan aktlar soni - {count}',
    subtitle: 'Iltimos, barcha aktlarning nusxasini yuklashni unutmang',
    button: 'Ok',
  },
};
