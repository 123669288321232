export default {
  entities: {
    installment: {
      creditItemsCount: 'Mahsulotlar',
      status: {
        ACTIVE: 'Faol',
        OVERDUE: 'Muddati o‘tgan',
        PROBLEM: 'Muammoli',
        CLOSED: 'Yopilgan',
        PAUSED: 'Kechiktirilgan',
        DELETED: 'O‘chirilgan',
      },
      currentPaymentAmount: 'Oylik to‘lov',
      currentPaymentDate: 'Keyingi to‘lov sanasi',
      balance: 'Balans',
      showScheduleCupPayments: "Grafikni ko'rsatish",
      scheduleCupPaymentsModal: {
        title: "To'lovlar jadvali",
        ok: 'Tushunarli',
        table: {
          headers: ['SANA', "Oylik to'lov", "To'langan"],
          paymentProgress: {
            paid: "To'langan",
            notPaid: 'Qoldi',
          },
        },
      },
    },
  },
};
