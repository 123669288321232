/**
 * Получение цвет для заливки по имени
 * @param {String} str
 * @return {{short: String, bg: String}}
 * */
const colorFromName = str => {
  if (!str) return { short: () => '', bg: () => '' };
  const [_name, _lastName] = str?.split(' ') ?? '';
  const name = _name && typeof _name === 'string' ? _name.slice(0, 3) : '';
  const lastName = _lastName && typeof _lastName === 'string' ? _lastName.slice(0, 3) : '';
  return {
    short: (name ? name?.[0]?.toUpperCase?.() ?? '' : '') + (lastName ? lastName?.[0].toUpperCase?.() ?? '' : ''),
    bg: (() => {
      let result = 0;
      const all = name + lastName;
      for (let i = 0; i < all.length; i++) {
        result += all.charCodeAt(i);
      }
      return result.toString(16).padEnd(6, '4');
    })(),
  };
};

export default colorFromName;
