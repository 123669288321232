import mainConnect, { generateError } from '@/services';

export const analyticsService = {
  /**
   * Get applications by progress
   *
   * @param {Object} params
   * @param {Number} params.status status
   * @param {Number} params.consultant_id consultant id
   * @param {String} params.date_type date type
   * @param {String} params.data_type data type
   * @param {String} params.source source
   * @param {String} params.store store
   * @param {String} params.request_date request date
   *
   * @returns {Promise<ApplicationsByProgress>}
   * @typedef {Array} ApplicationsByProgress
   *
   * @throws {Error}
   */
  getApplicationsByProgress(params) {
    return mainConnect.get('/report/applications-by-progress', { params }).catch(err => generateError(err));
  },

  /**
   * Get applications analytics
   *
   * @param {Object} params
   * @param {Number} params.status status
   * @param {Number} params.consultant_id consultant id
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {String} params.store store
   * @param {String} params.request_date request date
   *
   * @returns {Promise<Analytics>}
   * @typedef {Array} Analytics
   *
   * @throws {Error}
   */
  getApplicationsAnalytics(params) {
    return mainConnect.get('/report/applications', { params }).catch(err => generateError(err));
  },

  /**
   * Get applications by stores
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {Array} params.store store
   * @param {String} params.request_date request date
   *
   * @returns {Promise<ApplicationsByStores>}
   * @typedef {Array} ApplicationsByStores
   *
   * @throws {Error}
   */
  getApplicationsByStores(params) {
    let store = params?.store?.filter?.(el => el);
    store = store?.length ? `(${store.join(',')})` : null;
    return mainConnect
      .get('/report/store-problem-credits', {
        params: { ...params, store },
      })
      .catch(err => generateError(err));
  },

  /**
   * Get applications mean
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {String} params.request_date request date
   *
   * @returns {Promise<ApplicationsMean>}
   * @typedef {Array} ApplicationsMean
   *
   * @throws {Error}
   */
  getApplicationsMean(params) {
    return mainConnect.get('/report/applications-mean', { params }).catch(err => generateError(err));
  },

  /**
   * Get demography
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {String} params.age age
   * @param {String} params.request_date request date
   *
   * @returns {Promise<Demography>}
   * @typedef {Array} Demography
   *
   * @throws {Error}
   */
  getDemography(params) {
    return mainConnect.get('/report/applications-by-gender', { params }).catch(err => generateError(err));
  },

  /**
   * Get consultants report
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.data_type data type
   * @param {String} params.source source
   * @param {Array} params.consultant_id consultant id
   * @param {String} params.request_date request date
   *
   * @returns {Promise<ConsultantsReport>}
   * @typedef {Array} ConsultantsReport
   *
   * @throws {Error}
   */
  getConsultantsReport(params) {
    const consultant_id = params?.consultant_id?.length ? `(${params.consultant_id.join(',')})` : null;

    return mainConnect
      .get('/report/consultant-problem-credits', {
        params: { ...params, consultant_id },
      })
      .catch(err => generateError(err));
  },

  /**
   * Get popular categories
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {Number} params.limit limit
   * @param {String} params.request_date request date
   *
   * @returns {Promise<PopularCategories>}
   * @typedef {Array} PopularCategories
   *
   * @throws {Error}
   */
  getPopularCategories(params) {
    return mainConnect.get('/report/top-10-categories', { params }).catch(err => generateError(err));
  },

  /**
   * Get popular items
   *
   * @param {Object} params
   * @param {String} params.date_type date type
   * @param {String} params.source source
   * @param {Number} params.limit limit
   * @param {String} params.request_date request date
   *
   * @returns {Promise<PopularItems>}
   * @typedef {Array} PopularItems
   *
   * @throws {Error}
   */
  getPopularItems(params) {
    return mainConnect.get('/report/top-10-items', { params }).catch(err => generateError(err));
  },
};
