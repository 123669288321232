export default {
  stats: {
    sumOfItems: 'Sotuv summasi',
    itemsSold: 'Sotilgan tovarlar',
    token: 'Hamkor tokeni',
  },
  instructions: {
    title: "Ko'rsatma",
    conditions: {
      first: "«Shartnoma» original nusxalari doimo kompaniyada bo'lishi shart.",
      second: "Barcha aktlar merchantga to'liq yuklatilishi lozim.",
      third:
        "Kunlik qilingan savdo (mahsulot) merchant orqali Excelga yuklab olinib buxgalterga jo'natilishi kerak, buxgalter shu ma’lumotlarga asosan счет фактура yuborishi kerak.",
      fourth: "Aktlarning original varianti (to'liq holda) yetkazilish tartibi:",
      fourthList: ['— Toshkent va Toshkent viloyati 7 kunda', '— Qolgan viloyatlar har 10 kunda olib kelinishi kerak'],
    },
  },
  index: {
    allStores: "Barcha do'konlar",
    title: 'Sotuvlar',
    headers: {
      id: 'ID',
      item: 'Tovar',
      client: 'Mijoz',
      store: "Do'kon",
      sum: 'Miqdori',
      signed: 'Imzolangan',
      amount: 'Soni',
    },
  },
};
