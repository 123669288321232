export default {
  information: 'Информация',
  titles: {
    fullName: 'Ф.И.О клиента',
    phone: 'Номер клиента',
    items: 'Товары',
    created: 'Дата создания',
    expired: 'Дата истечения',
    reason: 'Причина',
    comments: 'Комментарии',
    notes: 'Комментарии к партнеру',
    status: 'Статус',
  },
  empty: {
    fullName: 'Имя не указано',
    phone: 'Номер не указан',
    items: 'Товары не указаны',
    created: 'Дата создания не указана',
    expired: 'Дата истечения не указана',
    reason: 'Причина не указана',
    comments: 'Комментарии не указаны',
    notes: 'Комментарии к партнеру не указаны',
    status: 'Статус не указан',
  },
  placeholders: {
    status: 'Выберите статус',
  },
  options: {
    processing: 'В процессе',
    completed: 'Выполнено',
  },
  note: 'Заметка',
  addNote: 'Добавить заметку',
  send: 'Отправить',
  index: {
    title: 'Проблемные кейсы',
    headers: {
      client: 'Клиент',
      status: 'Статус',
      date: 'Дата',
      responsiblePerson: 'Ответственное лицо',
      comments: 'Комментарии',
    },
    noComments: 'Нет комментариев',
  },
};
