export default {
  index: {
    title: 'Xabarnomalar',
    headers: {
      header: 'Sarlavha',
      notification: 'Xabarnoma',
      received: 'Qabul qilindi',
    },
  },
  slider: {
    link: 'Batafsil',
    outOf: '{total}-tadan {current}-chi',
  },
};
