export default {
  features: {
    registration: {
      placeholders: {
        tin: 'STIR kiriting',
        pinfl: 'PINFL-ni kiriting',
      },
      labels: {
        tin: 'Soliq to‘lovchining identifikatsiya raqami (STIR)',
        pinfl: 'PINFL',
      },
      select: {
        tin: 'STIR',
        pinfl: 'PINFL',
      },
    },
  },
};
