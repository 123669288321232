export default {
  features: {
    applicationCreate: {
      title: 'Ariza yaratish',
      body: 'Mijoz doʻkondan tashqarida boʻlsa, “Masofadan rasmiylashtirish” ni tanlang',
      createInStore: 'Mijoz doʻkonda',
      createOutStore: 'Masofadan rasmiylashtirish',
    },
  },
};
