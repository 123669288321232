export default {
  widgets: {
    applicationsListTable: {
      headers: ['ID', 'Mijoz', 'Miqdori', 'Holat', 'Yaratildi', 'Yangilandi', "Ko'rib chiqdi"],
      useSearchToFindCompletedApplicationsAlert: 'Rasmiylashtirilgan arizalarni topish uchun qidiruvdan foydalaning',
      filtersBlock: {
        labels: {
          deliveryExists: 'Yetkazib berish mavjudligi',
        },
        placeholders: {
          deliveryExists: 'Hammasi',
        },
        hasDelivery: 'Yetkazib berish mavjud',
        hasNoDelivery: 'Yetkazib berish yo‘q',
        openFilters: 'Filtr',
        contract: {
          label: 'Dalolatnoma',
          placeholder: 'Dalolatnoma filtri',
          options: ['Yuklangan', 'Yuklanmagan'],
        },
        source: {
          label: 'Manba',
          placeholder: 'Manba filtri',
        },
        cancelReason: {
          label: 'Bekor qilish sababi',
          placeholder: 'Bekor qilish sababi filtri',
        },
        rejectReason: {
          label: 'Rad etish sababi',
          placeholder: 'Rad etish sababi filtri',
        },
        store: {
          label: "Do'kon",
          placeholder: "Do'kon filtri",
        },
        resetFilters: "Filtrlarni qayta o'rnatish",
      },
    },
  },
};
