<template>
  <at-badge rounded :color="badgeColor">
    {{ badgeText }}
  </at-badge>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import { AtBadge } from '@packages/aliftech-ui';

  import { CASHBACK_STATUS } from '../constants';
  import { cashbackGetType } from '../lib';

  const props = defineProps({
    cashback: {
      type: Object,
      required: true,
    },
  });

  const { t } = useI18n();

  const badgeColor = computed(() => {
    const map = {
      [CASHBACK_STATUS.CHARGE]: 'green',
      [CASHBACK_STATUS.NOT_APPROVED]: 'yellow',
      [CASHBACK_STATUS.REJECT]: 'red',
    };

    return map[cashbackGetType(props.cashback)];
  });

  const badgeText = computed(() => {
    const map = {
      [CASHBACK_STATUS.CHARGE]: t(`bonuses.statuses.${CASHBACK_STATUS.CHARGE}`),
      [CASHBACK_STATUS.NOT_APPROVED]: t(`bonuses.statuses.${CASHBACK_STATUS.NOT_APPROVED}`),
      [CASHBACK_STATUS.REJECT]: t(`bonuses.statuses.${CASHBACK_STATUS.REJECT}`),
    };

    return map[cashbackGetType(props.cashback)];
  });
</script>
