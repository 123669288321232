import mainConnect, { generateError } from '@/services';

export const rejectsService = {
  /**
   * Get reject cases
   *
   * @param {Object} params
   * @param {Number} params.application_id - application id
   * @param {Number} params.paginate - paginate
   * @param {String} params.relationships - relationships
   * @param {String} params.status - status
   * @param {String} params.q - search
   *
   * @returns {Promise<RejectCases>}
   * @typedef {Array} RejectCases
   * @property {Number} data[].id - id
   * @property {Number} data[].is_full - is full
   * @property {String} data[].client_name - client name
   * @property {String} data[].status - status
   * @property {String} data[].date - date
   * @property {String} data[].items[].name - name
   * @property {Number} data[].items[].price - price
   * @property {Number} meta.last_page - last page
   * @property {Number} meta.total - total
   *
   * @throws {Error}
   */
  getRejectCases(params) {
    return mainConnect.get('/credits/reject-cases', { params }).catch(err => generateError(err));
  },

  /**
   * Get reject case
   *
   * @param {Number} id - reject case id
   * @param {Object} params
   * @param {String} params.relationships - relationships
   *
   * @returns {Promise<RejectCase>}
   * @typedef {Object} RejectCase
   * @property {Number} id - id
   * @property {Number} application_id - application id
   * @property {String} client_name - client name
   * @property {String} client_phone - client phone
   * @property {String} date - date
   * @property {String} note - note
   * @property {String} status - status
   * @property {String} cancel_reason - cancel reason
   * @property {Number} is_full - is full
   * @property {String} items[].name - name
   * @property {Number} items[].price - price
   * @property {String} items[].files[].url - image url
   *
   * @throws {Error}
   */
  getRejectCase(id, params = {}) {
    return mainConnect.get(`credits/reject-cases/${id}`, { params }).catch(err => generateError(err));
  },

  /**
   * Approve refund
   *
   * @param {Number} id - id
   *
   * @returns {Promise<Reject>}
   * @typedef {Object} Reject
   * @property {String} status - status
   *
   * @throws {Error}
   */
  approveRefund(id) {
    return mainConnect.post(`/credits/reject-cases/partner-approved/${id}`).catch(generateError);
  },

  /**
   * Reject refund
   *
   * @param {Number} id - id
   * @param {Object} payload
   * @param {String} payload.partner_cancel_note - partner cancel note
   *
   * @returns {Promise<Reject>}
   * @typedef {Object} Reject
   * @property {String} status - status
   *
   * @throws {Error}
   */
  rejectRefund(id, payload) {
    return mainConnect.post(`/credits/reject-cases/partner-denied/${id}`, payload).catch(generateError);
  },

  /**
   * Request to reject
   *
   * @param {Object} params
   * @param {Number} params.application_id - application id
   *
   * @returns {Promise<requestReject>}
   * @typedef {Object} requestReject
   * @property {String} phone - phone
   *
   * @throws {Error}
   */
  requestReject(params) {
    return mainConnect.post(`/reject_cases/reject_cases/request-store`, params).catch(generateError);
  },

  /**
   * Confirm reject
   *
   * @param {Object} params
   * @param {Number} params.application_id - application id
   * @param {Number} params.otp - otp
   * @param {String} params.note - note
   * @param {Array} params.application_items - application items id
   *
   * @returns {Promise<storeReject>}
   * @typedef {Object} storeReject
   * @property {String} data[].id - reject id
   *
   * @throws {Error}
   */
  storeReject(params) {
    return mainConnect.post(`/reject_cases/reject_cases_full`, params).catch(generateError);
  },
};
