<template>
  <AtDropdown :icon="theme === 'dark' ? 'moon' : 'sun'" :title="$t('home.theme.title')" position="right">
    <AtDropdownItem
      icon="sun"
      :title="$t('home.theme.light')"
      :active="theme === 'light'"
      @click="setTheme('light')"
    ></AtDropdownItem>
    <AtDropdownItem
      icon="moon"
      :title="$t('home.theme.dark')"
      :active="theme === 'dark'"
      @click="setTheme('dark')"
    ></AtDropdownItem>
  </AtDropdown>
</template>

<script>
  import { defineAsyncComponent, defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseThemeChange',
    components: {
      AtDropdown: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtDropdown/AtDropdown')),
      AtDropdownItem: defineAsyncComponent(() =>
        import('@/plugins/aliftech-ui/components/AtDropdownItem/AtDropdownItem')
      ),
    },
  });
</script>
<script setup>
  import { useTheme } from '@/hooks';

  const { theme, setTheme } = useTheme();
</script>
