import { formatDate, formatPrice, formatTime } from '@/to-fsd/shared/lib';
import { parseISO } from 'date-fns';

export const cashbackGetId = cashback => {
  return cashback.id;
};

export const cashbackGetChargedAt = cashback => {
  return cashback.charged_at;
};

export const cashbackHasChargedAt = cashback => {
  return Boolean(cashbackGetChargedAt(cashback));
};

export const cashbackGetApplicationAmount = cashback => {
  return cashback.application_amount;
};

export const cashbackGetFormattedApplicationAmount = cashback => {
  return formatPrice(cashbackGetApplicationAmount(cashback));
};

export const cashbackGetCashbackAmount = cashback => {
  return cashback.cashback_amount;
};

export const cashbackGetFormattedCashbackAmount = cashback => {
  let result = '';

  if (cashbackGetCashbackAmount(cashback) > 0) {
    result += '+';
  }

  result += formatPrice(cashbackGetCashbackAmount(cashback));

  return result;
};

export const cashbackGetContractDate = cashback => {
  return cashback.contract_date;
};

export const cashbackGetType = cashback => {
  return cashback.type;
};

export const cashbackGetFormattedContractDate = cashback => {
  return {
    date: formatDate(parseISO(cashbackGetContractDate(cashback))),
    time: formatTime(parseISO(cashbackGetContractDate(cashback)), 'dd MMM yyyy'),
  };
};

export const cashbackGetFormattedChargedAt = cashback => {
  return {
    date: formatDate(parseISO(cashbackGetChargedAt(cashback))),
    time: formatTime(parseISO(cashbackGetChargedAt(cashback)), 'dd MMM yyyy'),
  };
};
