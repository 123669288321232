export default {
  create: {
    showContract: 'Акт о возврате',
    btn: 'Запрос на удаление',
    items: {
      title: 'Выберите товар для возврата',
    },
    title: 'Новый возврат',
    confirm: {
      button: 'Оформить',
      header: 'Без вашей подписи или/и подписи клиента, заявка на возврат будет отменена',
      title: 'Оформление возврата',
      modal: {
        title: 'Вы действительно хотите вернуть этот товар?',
        btns: {
          confirm: 'Подтвердить',
          cancel: 'Отмена',
        },
      },
    },
    instruction: {
      title: 'Инструкция',
      list: ['Напишите причину возврата', 'Выберите товары'],
    },
    note: {
      title: 'Причина возврата',
      noItem: 'Товар не остался',
      wrongItem: 'Вбили неправильный товар',
      defectiveItem: 'Бракованный товар',
      clientRefused: 'Клиент отказался от товара',
      goodsWereNotDelivered: 'Товар не доставили',
      other: 'Другое',
    },
  },
  index: {
    title: 'Возвраты',
    headers: {
      client: 'Клиент',
      status: 'Статус',
      type: 'Тип',
      date: 'Дата',
      reason: 'Причина',
    },
  },
  items: {
    title: 'Товары для возврата',
    headers: {
      name: 'Наименование',
      price: 'Цена',
    },
  },
  info: {
    title: 'Информация о возврате',
    clientName: 'Ф.И.О клиента',
    clientPhone: 'Номер клиента',
    date: 'Дата отправки',
    note: 'Причина возврата',
  },
  photos: {
    title: 'Фото товара',
    alert: 'Фото товара не существует',
  },
  cancelReason: {
    title: 'Причина отмены возврата',
  },
  rejectType: {
    full: 'Полный',
    partial: 'Частичный',
  },
  btns: {
    goToApplication: 'Перейти на заявку',
    reject: 'Отказать',
    approve: 'Одобрить',
  },
  reject: {
    modal: {
      title: 'Укажите причину отказа',
      btns: {
        cancel: 'Отмена',
        reject: 'Подтвердить',
      },
    },
    toast: {
      reject: 'Отказано в возврате!',
      approve: 'Возврат одобрен!',
    },
  },
};
