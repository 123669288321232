export default {
  features: {
    clientUpdatePassport: {
      passport: {
        title: 'Паспорт клиента',
        uploadPhotoMessage: 'Загрузите фото паспорта клиента',
        fields: {
          passportPhoto: {
            label: 'Фото паспорта',
          },
          passportFace: {
            label: 'Паспорт на фоне лица',
          },
        },
        continue: 'Продолжить',
      },
      idPassport: {
        title: 'ID карта',
        uploadPhotoMessage: 'Загрузите фото ID карты клиента',
        fields: {
          passportIdPhotoFront: {
            label: 'Передняя сторона ID карты',
          },
          passportIdPhotoBack: {
            label: 'Задняя сторона ID карты',
          },
          passportIdPhoto: {
            label: 'ID карта на фоне лица',
          },
        },
        submit: 'Продолжить',
      },
      passportData: {
        gender: {
          M: 'Мужчина',
          F: 'Женщина',
        },
        fields: {
          passportId: {
            label: 'Серия и номер',
          },
          pinfl: {
            label: 'ПИНФЛ',
          },
          region: {
            label: 'Регион',
          },
          address: {
            label: 'Адрес прописки',
          },
          nationalityName: {
            label: 'Гражданство',
          },
          birthplace: {
            label: 'Место рождения',
          },
          issueDate: {
            label: 'Дата выдачи',
            placeholder: 'год-месяц-день',
          },
          expiresDate: {
            label: 'Годен до',
            placeholder: 'год-месяц-день',
          },
          issuedBy: {
            label: 'Кем выдан',
            placeholder: 'Кем выдан паспорт',
          },
          name: {
            label: 'Имя',
          },
          surname: {
            label: 'Фамилия',
          },
          patronymic: {
            label: 'Отчество',
          },
          gender: {
            label: 'Пол',
          },
          birthDate: {
            label: 'Дата рождения',
          },
          cityName: {
            label: 'Место рождения',
          },
        },
        submit: 'Сохранить',
        cancel: 'Отмена',
        fetchOcr: 'Считать с фото заново',
      },
      successAlert: 'Паспортные данные успешно обновлены.',
    },
  },
};
