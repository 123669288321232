import payments from '../payments/routers';
import balances from '../balances/routers';
import scoring from '../scoring/routers';
import AcquiringLayout from './layouts/AcquiringLayout';
import { ROUTE_NAMES } from '@/modules/acquiring/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';

const routes = {
  path: '/acquiring/',
  component: AcquiringLayout,
  redirect: '/acquiring/purchases',
  children: [
    ...payments,
    ...balances,
    ...scoring,
    {
      path: '/acquiring/clients/search',
      name: ROUTE_NAMES.searchClients,
      component: async () => {
        const { BmlSearchClientsPage } = await import('@/to-fsd/pages/bml-search-clients');

        return BmlSearchClientsPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Поиск клиентов'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_acquiring'),
          requirePermissions(['merchant_acquiring']),
        ],
      },
    },
  ],
};

export default router => {
  router.addRoute(routes);
};
