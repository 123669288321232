export default {
  pages: {
    salesList: {
      title: 'Продажи',
      instructionsButton: 'Инструкция',
      instructionsModal: {
        title: 'Инструкция',
        items: [
          'Оригиналы «Договора» всегда должны быть в компании.',
          'Все акты должны быть полностью загружены на мерчант.',
          'Ежедневная сделка (товар) должна быть загружена в Excel через продавца и отправлена бухгалтеру, который должен отправить счет-фактуру на основе этой информации.',
          'Порядок доставки оригинальной версии актов (в полном объеме):',
          '— Ташкент и Ташкентская область за 7 дней',
          '— Остальные регионы необходимо привозить каждые 10 дней',
        ],
      },
    },
  },
};
