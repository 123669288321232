import { DELIVERY_TYPE_SERVICE } from '../constants';

export default {
  entities: {
    delivery: {
      deliveryInfo: {
        title: 'Доставки',
        deliveryNumber: 'Доставка №{number}',
        modal: {
          title: 'Доставка №{number}',
          timeToDelivery: 'Приблизительное время доставки',
          senderAddress: 'Адрес отправителя:',
          receiverAddress: 'Адрес получателя:',
          closeButton: 'Понятно',
        },
      },
      dpdDeliveryTypeService: {
        [DELIVERY_TYPE_SERVICE.ENTER_ENTER]: 'C двери до двери',
        [DELIVERY_TYPE_SERVICE.ENTER_POINT]: 'C двери до пункт выдачи заказа',
        [DELIVERY_TYPE_SERVICE.POINT_POINT]: 'C пункт выдачи заказа до пункт выдачи заказа',
        [DELIVERY_TYPE_SERVICE.POINT_ENTER]: 'C пункт выдачи заказа до двери',
      },
      deliveryReceiverInfo: {
        name: 'Имя клиента',
        phone: 'Телефоны',
      },
      approximateTime: {
        text: 'Приблизительное время доставки',
      },
      senderAndReceiverAddress: {
        sender: 'Адрес отправителя',
        receiver: 'Адрес получателя',
      },
    },
  },
};

/*

 */
