import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

const defaultState = {
  id: undefined,
  result: null,
  loading: false,
  updating: false,
  alert: null,
  errors: null,
  error: null,
};

export const useClientStore = defineStore('ClientStore', {
  state: () => {
    return {
      client: defaultState,
      phones: defaultState,
      addresses: defaultState,
      files: defaultState,
      limit: null,
      passport: null,
    };
  },
  actions,
  getters,
});
