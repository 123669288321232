import { checkType } from '@/plugins/aliftech-ui/utils/checkType';
import { hasOwnProperty } from '@/plugins/aliftech-ui/utils/hasOwnProperty';
import { capitalize } from 'vue';

export const iterableToAtSelectOptions = (iterable, lang = 'ru') => {
  const arr = [];
  if (checkType(iterable, 'array')) {
    iterable.forEach(el => {
      const title = el?.[`body_${lang}`] ?? el?.[`name_${lang}`] ?? el?.lang?.[lang] ?? el?.body ?? el?.name;
      arr.push({
        value: el?.id || el?.key,
        title: capitalize(title || ''),
      });
    });
  } else if (checkType(iterable, 'object')) {
    for (const key in iterable) {
      if (hasOwnProperty(iterable, key)) {
        const title =
          iterable[key]?.[`body_${lang}`] ||
          iterable[key]?.[`name_${lang}`] ||
          iterable[key]?.body ||
          iterable[key]?.[`${lang}`] ||
          iterable[key]?.name;
        arr.push({
          value: key,
          title: capitalize(title || ''),
        });
      }
    }
  }
  return arr;
};
