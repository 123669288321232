export default {
  pages: {
    clientsShow: {
      secondaryTitle: 'Mijoz',
      tabs: ['Talablar', 'Arizalar'],
      clientRequirements: {
        fixReasons: {
          title: 'Maʼlumotlarni tuzatish kerak',
        },
        requirementsList: ['Fayllar', 'Karta', 'Soʻrovnoma'],
        sendToReview: 'Ko‘rib chiqishga yuborish',
        filesTab: {
          alreadyUploaded: 'Yuklangan',
          passportPhoto: {
            title: 'Pasport fotosurati',
          },
          passportFacePhoto: {
            title: 'Yuz fonidagi pasport fotosurati',
          },
          idPassportPhoto: {
            title: 'ID pasport fotosurati',
          },
          idPassportFacePhoto: {
            title: 'Yuz fonidagi ID pasport fotosurati',
          },
          commonFiles: {
            secondaryTitle: 'Majburiy emas',
          },
        },
        questionnaireTab: {
          personalData: {
            title: 'Shaxsiy maʼlumotlar',
            fields: {
              activityArea: {
                label: 'Faoliyat sohasi',
              },
              preferredLanguage: {
                label: 'Afzal ko‘rilgan til',
              },
              car: {
                label: 'Avtomobil',
                hasCar: 'Bor',
                doesNotHaveCar: 'Yoq',
              },
            },
          },
          contacts: {
            title: 'Kontaktlar',
            fields: {
              contactNumber: {
                label: 'Telefon raqami',
              },
              person: {
                label: 'Kim hisoblanadi',
              },
              ownerName: {
                label: 'Egasining ismi',
              },
            },
            addOneMoreContact: 'Yana bir kontakt qoʻshish',
            save: 'Saqlash',
            edit: 'Tahrirlash',
            cancel: 'Bekor qilish',
          },
          labels: {
            car: 'Avtomobil',
            hasCar: 'Bor',
            hasNoCar: 'Yoq',
          },
        },
        cardTab: {
          title: 'Mijoz kartasi',
          addMore: 'Yana qo‘shish',
          smsWasSentToMessage: 'SMS-kod mijoz raqamiga yuborildi ',
          addAnotherCardWarning: 'Yana boshqa karta qo‘shish',
          fields: {
            cardNumber: {
              label: 'Karta raqami',
            },
            expire: {
              label: 'Karta muddati',
            },
            otp: {
              label: 'Tasdiqlash kodi',
            },
          },
          requestCreate: 'Kartani qo‘shish',
          create: 'Tasdiqlash',
          cancel: 'Bekor qilish',
          infos: [
            'Asosiy kartadagi ism mijozning ismi bilan mos bo‘lishi kerak',
            'Kredit limitini oshirish uchun bir nechta kartani qo‘shish mumkin',
          ],
        },
      },
    },
  },
};
