import { ordersService } from '../services/orders.service';

export const actions = {
  async fetchOrderStatuses() {
    if (!this.orderStatuses) {
      const response = await ordersService.getOrderStatuses();
      this.orderStatuses = response?.data;
    }
    return Promise.resolve(this.orderStatuses);
  },
};
