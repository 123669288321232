export default {
  entities: {
    installment: {
      creditItemsCount: 'Товаров',
      status: {
        ACTIVE: 'Активный',
        OVERDUE: 'Просрочен',
        PROBLEM: 'Проблемный',
        CLOSED: 'Закрытый',
        PAUSED: 'Задерживающийся',
        DELETED: 'Удален',
      },
      currentPaymentAmount: 'Ежемесячный платёж',
      currentPaymentDate: 'Дата след. платежа',
      balance: 'Баланс',
      showScheduleCupPayments: 'Показать график',
      scheduleCupPaymentsModal: {
        title: 'График платежей',
        ok: 'Понятно',
        table: {
          headers: ['ДАТА', 'Ежемесячный платёж', 'Оплачено'],
          paymentProgress: {
            paid: 'Оплачено',
            notPaid: 'Осталось',
          },
        },
      },
    },
  },
};
