export default {
  widgets: {
    problemCaseInfoPanel: {
      title: "Ma'lumotlar",
      rows: [
        'Mijoz F.I.SHi',
        'Mijoz telefon raqami',
        'Tovarlar',
        'Sabab',
        'Izohlar',
        'Holat',
        'Yaratilgan sana',
        'Amal qilish muddati',
      ],
      phoneEmpty: "Raqam ko'rsatilmagan",
      emptyItems: "Tovar ko'rsatilmagan",
      emptyReason: "Sabab ko'rsatilmagan",
      emptyComments: "Izohlar ko'rsatilmagan",
      emptyCreated: "Yaratilgan sana ko'rsatilmagan",
      emptyExpired: "Amal qilish muddati ko'rsatilmagan",
    },
  },
};
