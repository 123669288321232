export default {
  toast: {
    requestStatusChange: 'Soʻrov holati quyidagicha oʻzgartirildi "{to}"',
  },
  statusKeys: {
    completed: 'Tasdiqlangan',
    cancelled: 'Bekor qilingan',
    underCorrection: 'Tuzatishda',
    processing: "Ko'rib chiqilmoqda",
  },
  index: {
    title: "So'rovlar",
    dropdown: {
      addItem: "Tovar qo'shish",
      addManually: "Qo'lda qo'shish",
      addWithExcel: 'Excel yuklash',
    },
    tabs: {
      all: 'Barchasi',
      approved: 'Tasdiqlangan',
      processs: 'Bajarilayotgan',
      fix: 'Tuzatishda',
      cancelled: 'Bekor qilingan',
    },
    filters: {
      search: 'Qidirish...',
      createdAt: 'Yaratilgan sana',
    },
    table: {
      headers: {
        quantity: 'Mahsulotlar soni',
        status: 'Holati',
        addedAt: "Qo'shilgan sana",
        addedBy: "Kim qo'shdi",
      },
      paginationInfo: "Natijalar {from} dan {to} gacha {total} ta ko'rsatilmoqda",
    },
  },
  show: {
    title: "So'rov #{requestId}",
    back: 'Orqaga',
    alert: {
      desc:
        "Xato ko'rsatilgan tovarlarni to'g'rilab, qayta yuboring. To'g'rilanishi kerak bo'lgan joylar izohda ko'rsatilgan",
      cancel: 'Bekor qilish',
      sendToReview: "Ko'rib chiqish uchun yuborish",
    },
    table: {
      title: 'Tuzatish kerak',
      headers: {
        nameSku: 'NOMI / SKU',
        categoryIkpu: 'TOIFA / IKPU',
        quantitySum: 'MIQDORI / NARXI (SUM)',
        fixNeeded: 'Tuzatish kerak',
      },
      paginationInfo: "Natijalar {from} dan {to} gacha {total} ta ko'rsatilmoqda",
      times: 'ta.',
    },
    editItemModal: {
      title: "Tovar o'zgartirish",
      labels: {
        name: 'Nomi',
        price: 'Narxi',
        category: 'Toifasi',
        quantity: 'Miqdori',
        ikpu: 'IKPU',
      },
      buttons: {
        cancel: 'Bekor qilish',
        confirm: 'Tasdiqlash',
      },
    },
    confirmCancelModal: {
      title: 'Bekor qilish',
      desc: 'Haqiqatan ham amalni bekor qilmoqchimisiz?',
      confirm: 'Tasdiqlash',
      cancel: 'Yopish',
    },
    confirmDeleteItemModal: {
      title: "O'chirish",
      desc: "Siz rostdan ham ushbu mahsulotni o'chirishni istaysizmi?",
      confirm: "O'chirish",
      cancel: 'Bekor qilish',
    },
  },
  addItems: {
    title: "Mahsulot qo'shish",
    sendToReview: 'Arizani yuborish',
    back: 'Orqaga',
    addMore: "Yana qo'shish",
    form: {
      title: 'Mahsulot',
      labels: {
        ikpu: 'IKPU',
        name: 'Nomi',
        price: 'Narxi',
        category: 'Toifasi',
        quantity: 'Miqdori',
        sku: 'SKU',
        nds: 'Narxi (NDS bilan)',
      },
      sum: 'Jami',
    },
  },
};
