export const actions = {
  addItems(payload) {
    const itemIndex = this.items.findIndex(item => item.sku === payload.sku);
    if (itemIndex !== -1) {
      this.items[itemIndex].quantity = this.items[itemIndex].quantity + 1;
      return;
    }
    this.items.push(payload);
  },
  deleteItem(sku) {
    const itemIndex = this.items.findIndex(item => item.sku === sku);
    if (itemIndex !== -1 && this.items[itemIndex].quantity > 1) {
      this.items[itemIndex].quantity = this.items[itemIndex].quantity - 1;
      return;
    }
    this.items = this.items.filter(item => item.sku !== sku);
  },
};
