export const CLIENT_FILE_TYPE = {
  ID_PASSPORT_FACE: 'ID_PASSPORT_FACE',
  PASSPORT_FACE: 'PASSPORT_FACE',
  COMMON: 'COMMON',
  PASSPORT: 'PASSPORT',
  ID_PASSPORT_BACK: 'ID_PASSPORT_BACK',
  ID_PASSPORT_FRONT: 'ID_PASSPORT_FRONT',
  TAXI_DRIVER_DAILY_INCOME: 'TAXI_DRIVER_DAILY_INCOME',
  TAXI_DRIVER_WEEKLY_INCOME: 'TAXI_DRIVER_WEEKLY_INCOME',
  TAXI_DRIVER_MONTHLY_INCOME: 'TAXI_DRIVER_MONTHLY_INCOME',
  MILITARY_CERTIFICATE: 'MILITARY_CERTIFICATE',
  MONEY_TRANSFER_RECIPIENT: 'MONEY_TRANSFER_RECIPIENT',
};
