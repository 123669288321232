export default {
  index: {
    title: 'Уведомления',
    headers: {
      header: 'Загаловок',
      notification: 'Уведомление',
      received: 'Получено',
    },
  },
  slider: {
    link: 'Подробнее',
    outOf: '{current} из {total}',
  },
};
