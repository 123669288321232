import { defineStore } from 'pinia';
import { actions } from './actions';

export const useNotificationStore = defineStore('NotificationsStore', {
  state: () => {
    return {
      notificationsCount: 0,
    };
  },
  actions,
});
