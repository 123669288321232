export default {
  pages: {
    home: {
      title: 'Главная',
      quickLinks: {
        calculator: {
          title: 'Калькулятор',
        },
        faq: {
          title: 'FAQ',
        },
        knowledgeBase: {
          title: 'База знаний',
        },
        techSupport: {
          title: 'Написать в поддежку',
        },
      },
      clientsTable: {
        secondaryTitle: 'За сегодня',
      },
      applicationsTable: {
        secondaryTitle: 'За сегодня',
      },
    },
  },
};
