import AtToast from '@/plugins/aliftech-ui/toast';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';
import { useUserStore } from '@/modules/main/user/stores';
import { app as Vue } from '@/main';
import { setToken } from '@/services';
import { resetStores } from '@/stores';

const fetchAuthUser = async ({ next, to }) => {
  const userStore = useUserStore();

  const destroyLoginDetails = () => {
    Vue.config.globalProperties.$cookies.delete('auth');
    setToken('');
    resetStores();
  };

  if (!userStore.me) {
    try {
      await userStore.fetchUser();
    } catch (e) {
      destroyLoginDetails();
      AtToast.error({
        title: e.message,
        duration: 7000,
      });
      return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
    }
  }

  return next();
};

export default fetchAuthUser;
