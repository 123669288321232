<template>
  <div class="flex w-full items-center justify-center p-6">
    <div class="animate-ping h-6 w-6 rounded-full bg-primary-400 opacity-75"></div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BasePageLoading',
  });
</script>
