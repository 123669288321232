import { ROUTE_NAMES as ACQUIRING_ROUTES } from '@/modules/acquiring/common/constants/routeNames';
import { ROUTE_NAMES as ALIFSHOP_ROUTES } from '@/modules/alifshop/common/constants/routeNames';
import { ROUTE_NAMES as ADMIN_ROUTES } from '@/modules/admin/common/constants/routeNames';
import { ROUTE_NAMES as NASIYA_ROUTES } from '@/modules/nasiya/common/constants/routeNames';

export const modulesList = {
  module_azo: {
    name: 'NASIYA',
    homeRoute: NASIYA_ROUTES.home,
  },
  module_alifshop: {
    name: 'ALIFSHOP',
    homeRoute: ALIFSHOP_ROUTES.ordersList,
  },
  module_acquiring: {
    name: 'ACQUIRING',
    homeRoute: ACQUIRING_ROUTES.balancesList,
  },
  module_admin: {
    name: 'ADMIN',
    homeRoute: ADMIN_ROUTES.employeesList,
  },
};

export const modulesName = Object.keys(modulesList).reduce((acc, key) => {
  acc[modulesList[key].name] = key;
  return acc;
}, {});
