import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useCreateApplicationStore = defineStore('CreateApplicationStore', {
  state: () => {
    return {
      items: [],
      firstPaymentDate: null,
      selectedConditionId: null,
    };
  },
  actions,
  getters,
});
