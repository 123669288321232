import { useMainStore } from '@/modules/main/common/stores';
import AtToast from '@/plugins/aliftech-ui/toast';
import { ROUTE_NAMES } from '@/modules/main/common/constants/routeNames';
import { app as Vue } from '@/main';
import { setToken } from '@/services';
import { resetStores } from '@/stores';

const fetchPrmApp = async ({ to, next }) => {
  const mainStore = useMainStore();

  const destroyLoginDetails = () => {
    Vue.config.globalProperties.$cookies.delete('auth');
    setToken('');
    resetStores();
  };

  if (!mainStore.app) {
    try {
      await mainStore.fetchPrmApp();
    } catch (e) {
      destroyLoginDetails();
      AtToast.error({
        title: e.message,
        duration: 7000,
      });
      return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
    }
  }

  return next();
};

export default fetchPrmApp;
