<template>
  <div class="relative">
    <span class="underline text-sm text-indigo-600 cursor-pointer" @click="handleClick">
      {{ $t('otpInfoBlock.trigger') }}
    </span>

    <div
      v-if="showPopup"
      class="absolute top-full left-0 shadow-lg rounded-md p-4 bg-white dark:bg-gray-900 dark:border dark:border-gray-500 z-10"
      @click.stop
    >
      <h2 class="font-semibold text-sm">
        {{ $t('otpInfoBlock.title') }}
      </h2>

      <ul class="mt-4 flex flex-col gap-y-2">
        <li class="flex gap-x-2">
          <div class="shrink-0 text-indigo-600 font-semibold">1.</div>
          <p class="pt-0.5 flex-grow text-sm">
            {{ $t('otpInfoBlock.steps[0]') }}
          </p>
        </li>

        <li class="flex gap-x-2">
          <div class="shrink-0 text-indigo-600 font-semibold">2.</div>
          <p class="pt-0.5 flex-grow text-sm">
            {{ $t('otpInfoBlock.steps[1]') }}
          </p>
        </li>

        <li class="flex gap-x-2">
          <div class="shrink-0 text-indigo-600 font-semibold">3.</div>
          <p class="pt-0.5 flex-grow text-sm">
            {{ $t('otpInfoBlock.steps[2]') }}
          </p>
        </li>

        <li class="flex gap-x-2">
          <div class="shrink-0 text-indigo-600 font-semibold">4.</div>
          <p class="pt-0.5 flex-grow text-sm">
            {{ $t('otpInfoBlock.steps[3]') }}
          </p>
        </li>

        <li class="flex gap-x-2">
          <div class="shrink-0 text-indigo-600 font-semibold">5.</div>
          <p class="pt-0.5 flex-grow text-sm">
            <i18n-t keypath="otpInfoBlock.steps[4]">
              <template #technicalSupport>
                <a href="https://t.me/alifpartnerschat" target="_blank" class="text-indigo-600">
                  {{ $t('otpInfoBlock.technicalSupport') }}
                </a>
              </template>
            </i18n-t>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const showPopup = ref(false);

  const pinflImageOutsideHandler = () => {
    showPopup.value = false;
  };

  const handleClick = () => {
    if (!showPopup.value) {
      showPopup.value = true;

      setTimeout(() => {
        document.addEventListener('click', pinflImageOutsideHandler, { once: true });
      }, 0);
    }
  };
</script>
