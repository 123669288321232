export default {
  widgets: {
    applicationsListTable: {
      headers: ['ID', 'Клиент', 'Сумма', 'Статус', 'Создано', 'Обновлено', 'Обработал'],
      useSearchToFindCompletedApplicationsAlert: 'Воспользуйтесь поиском, чтобы найти оформленные заявки',
      filtersBlock: {
        labels: {
          deliveryExists: 'Наличие доставки',
        },
        placeholders: {
          deliveryExists: 'Все',
        },
        hasDelivery: 'Есть доставка',
        hasNoDelivery: 'Нет доставки',
        openFilters: 'Фильтр',
        contract: {
          label: 'Скан акта',
          placeholder: 'Фильтр по скану акта',
          options: ['Загружен', 'Не загружен'],
        },
        source: {
          label: 'Источник',
          placeholder: 'Фильтр по источнику',
        },
        cancelReason: {
          label: 'Причина отмены',
          placeholder: 'Фильтр по причине отмены',
        },
        rejectReason: {
          label: 'Причина отказа',
          placeholder: 'Фильтр по причине отказа',
        },
        store: {
          label: 'Магазин',
          placeholder: 'Фильтр по магазинам',
        },
        resetFilters: 'Сбросить фильтр',
      },
    },
  },
};
