import { mainConnect, generateError } from '@/services';
import { handleResponse } from '../../common/service';

const requestQueue = {};

export const paymentsService = {
  /**
   * Get payments
   *
   * @param {Object} params
   *
   * @returns {Promise<Payments>}
   * @typedef {Object} Payments
   * @property {Number} code - code
   * @property {Array} data - payments array
   * @property {String} data[].ref - payment id
   * @property {String} data[].session_ref - session id,
   * @property {Number} data[].amount - amount on sums,
   * @property {String} data[].masked_pan - masked card number],
   * @property {String} data[].state - payment status,
   * @property {String} data[].created_at - date,
   * @property {String} data[].epos - name of terminal
   * @throws {Error}
   */
  getPayments(params) {
    const link = '/acquiring/charges';
    if (link in requestQueue) {
      return requestQueue[link];
    }
    return (requestQueue[link] = mainConnect
      .get(link, { params })
      .catch(generateError)
      .finally(() => {
        delete requestQueue[link];
      }));
  },

  /**
   * Revert payment
   *
   * @param {Object} params
   * @param {string} params.payment_ref - payment id
   *
   * @returns {Promise<Payment>}
   * @typedef {Object} Payment
   * @property {Number} code - code
   * @throws {Error}
   */
  revertPayment(params) {
    return mainConnect
      .post(`/acquiring/admin/checkout/payment/revert`, params)
      .then(handleResponse)
      .catch(generateError);
  },

  /**
   * Get payment statuses
   *
   * @param {Object} params
   *
   * @returns {Promise<Statuses>}
   * @typedef {Object} Statuses
   * @throws {Error}
   */
  getPaymentStatuses(params) {
    return mainConnect.get('/acquiring/charges/statuses', { params }).catch(generateError);
  },
};
