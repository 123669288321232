export default {
  widgets: {
    searchClients: {
      phone: 'Номер телефона',
      dateOfBirth: 'Дата рождения',
      findClient: 'Найти клиента',
      clientCard: {
        client: 'Клиент',
        limit: 'Лимит',
        hasNoLimitInfo: 'У клиента нет лимита',
      },
    },
  },
};
