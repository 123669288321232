export default {
  features: {
    applicationEditFirstPaymentDate: {
      editFirstPaymentDateButton: "To'lov sanasini o'zgartirish",
      modal: {
        title: "Yangi to'lov sanasini kiriting",
        cancel: 'Bekor qilish',
        confirm: 'Tasdiqlash',
      },
    },
  },
};
