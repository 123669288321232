export default {
  features: {
    applicationCreate: {
      title: 'Создать заявку',
      body: 'Выберите “Дистанционное оформление”, если клиент находится вне магазина',
      createInStore: 'Клиент в магазине',
      createOutStore: 'Дистанционное оформление',
    },
  },
};
