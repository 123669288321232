export default {
  widgets: {
    salesItemsListTable: {
      stats: {
        itemsSold: 'Продано товаров',
        sumOfItems: 'Товаров на сумму',
        token: 'Токен партнера',
      },
      filters: {
        store: {
          allStores: 'Все магазины',
        },
        reset: 'Сброс',
      },
      table: {
        headers: ['ID', 'Клиент', 'Товар', 'Магазин', 'Сумма', 'Подписано', 'Кол-во'],
      },
    },
  },
};
