import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import { ROUTE_NAMES } from '../../common/constants/routeNames';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';

// todo refactor module name
const moduleName = 'module_acquiring';

export default [
  {
    path: '/acquiring/purchases',
    name: ROUTE_NAMES.balancesList,
    component: () => import('../views/AcquiringBalancesIndexPage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Список оплат'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_acquiring']),
      ],
    },
  },
  {
    path: '/acquiring/balances/create-invoice',
    name: ROUTE_NAMES.createInvoice,
    component: () => import('../views/AcquiringBalancesCreateInvoicePage.vue'),
    meta: {
      middlewares: [
        setDocumentTitle('Cоздание инвойса'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
        requireAccessToModule(moduleName),
        requirePermissions(['merchant_acquiring']),
      ],
    },
  },
];
