import { merchantsService } from '../services/merchants.service';
import { clientsService } from '../../clients/services/clients.service';
import creditsService from '../../applications/services/credits.service';
import { deliveriesService } from '../../deliveries/services/deliveries.service';
import applicationsService from '../../applications/services/applications.service';
import { employeesService } from '../../../admin/employees/services/employees.service';

export const actions = {
  async fetchApp() {
    if (!this.app) {
      const response = await merchantsService.getApp();
      this.app = response?.data;
    }
    return Promise.resolve(this.app);
  },
  async fetchMerchantRelations(params) {
    if (!this.merchantRelations) {
      const response = await merchantsService.getMerchantRelations(params);
      this.merchantRelations = response?.data;
    }
    return Promise.resolve(this.merchantRelations);
  },
  async checkMerchantStatus() {
    const response = await merchantsService.checkToActive();
    this.merchantStatus = response?.data;
  },
  async fetchActivityAreas() {
    if (!this.activityAreas) {
      const response = await clientsService.getActivityAreas();
      this.activityAreas = response?.data;
    }
    return Promise.resolve(this.activityAreas);
  },
  async fetchPassportIssuers(params) {
    const result = {
      list: [],
      options: [],
      dataList: [],
    };
    if (!this.passportIssuers) {
      const response = await clientsService.getPassportIssuers(params);
      const data = response?.data;
      if (Array.isArray(data)) {
        this.passportIssuers = data.reduce?.((acc, issuer) => {
          acc.list.push(issuer);
          acc.dataList.push(issuer.name);
          acc.options.push({ title: issuer.name, value: issuer.name });
          return acc;
        }, result);
      }
    }
    return Promise.resolve(result);
  },
  async fetchCreditsApp() {
    if (!this.creditsApp) {
      const response = await creditsService.getApp();
      this.creditsApp = response?.data;
    }
    return Promise.resolve(this.creditsApp);
  },
  async fetchRegions(params) {
    if (!this.regions) {
      const response = await deliveriesService.getRegions(params);
      this.regions = response?.data;
    }
    return Promise.resolve(this.regions);
  },
  async fetchConditions(params) {
    const type = params?.['post_alifshop'] ? 'alifshop' : 'merchant';
    if (!this.conditions[type]) {
      const response = await applicationsService.getConditions(params);
      this.conditions[type] = response?.data;
    }
    return Promise.resolve(this.conditions[type]);
  },
  async fetchEmployees(params) {
    if (!this.employees) {
      const response = await employeesService.getMerchantEmployees(params);
      let employees = response?.data;
      if (employees?.meta?.last_page !== employees?.meta?.current_page) {
        const totalEmployees = await employeesService.getMerchantEmployees({ per_page: employees?.meta?.total });
        employees = totalEmployees?.data;
      }
      this.employees = employees?.data;
    }
    return Promise.resolve(this.employees);
  },
};
