import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const usePaymentStore = defineStore('PaymentsStore', {
  state: () => {
    return {
      paymentStatuses: null,
    };
  },
  actions,
  getters,
});
