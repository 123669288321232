export default {
  pages: {
    rejectCasesList: {
      title: 'Qaytarishlar',
      noResults: "Natijalar yo'q",
      headers: {
        client: 'Mijoz',
        status: 'Holati',
        type: 'Turi',
        date: 'Sana',
        reason: 'Sabab',
      },
      rejectType: {
        full: 'Butun',
        partial: 'Qisman',
      },
      note: {
        title: 'Qaytarish sababi',
        noItem: 'Tovar qolmadi',
        wrongItem: "Noto'g'ri tovar kiritilgan",
        defectiveItem: 'Buzuq tovar',
        clientRefused: 'Mijoz tovarni rad etdi',
        goodsWereNotDelivered: 'Tovar yetkazib berilmadi',
        other: 'Boshqa',
      },
    },
  },
};
