import i18n from '@/plugins/i18n';

const formatTime = (date, { withDayPeriod = true } = {}) => {
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const hours = date.getHours();
  let dayPeriod = '';

  if (hours > 4 && hours < 12) {
    dayPeriod = (i18n.global.locale ?? 'ru') === 'ru' ? 'утра' : 'tong.';
  } else if (hours >= 12 && hours < 16) {
    dayPeriod = (i18n.global.locale ?? 'ru') === 'ru' ? 'дня' : 'kun.';
  } else if (hours >= 16 && hours < 22) {
    dayPeriod = (i18n.global.locale ?? 'ru') === 'ru' ? 'веч.' : 'kech.';
  } else {
    dayPeriod = (i18n.global.locale ?? 'ru') === 'ru' ? 'ночи' : 'tun.';
  }

  if (withDayPeriod) {
    return `${hours}:${minutes} ${dayPeriod}`;
  } else {
    return `${hours}:${minutes}`;
  }
};

export default formatTime;
