export const DELIVERY_STATUS = {
  NEW: 'NEW',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  COURIER_PICKED_UP: 'COURIER_PICKED_UP',
  DELIVERED: 'DELIVERED',
  CANCELLED: 'CANCELLED',
  REFUND: 'REFUND',
};

export const DELIVERY_TYPE_SERVICE = {
  POINT_POINT: 'POINT_POINT',
  POINT_ENTER: 'POINT_ENTER',
  ENTER_ENTER: 'ENTER_ENTER',
  ENTER_POINT: 'ENTER_POINT',
};
