import { mainConnect, generateError } from '@/services';

export const prmService = {
  /**
   * Get prm app data
   *
   * @return {Promise}
   * @throws {Error}
   */
  getPrmApp() {
    return mainConnect.get('prm/app').catch(generateError);
  },
};
