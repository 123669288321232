export default {
  widgets: {
    createRejectForm: {
      arizaUpload: {
        title: 'Arizani yuklash',
        dropdown: {
          title: 'Shablonni yuklab olish',
          items: ['O‘zbek tilida', 'Rus tilida'],
        },
        alert:
          'Muddatli to‘lov bo‘yicha to‘lov amalga oshirilgan. Mijoz to‘ldirib, imzolashi kerak bo‘lgan ariza shablonini yuklab oling, so‘ng uni qayta yuklang.',
        label: 'Ariza',
      },
      instructions: {
        list: ['Qaytarish sababini kiriting', 'Tovarlarni tanlang', 'Arizani yuklang'],
      },
    },
  },
};
