import { analyticsService } from '../services/analytics.service';

export const actions = {
  async fetchApplicationsCount(query) {
    this.applicationsCount.data = null;
    this.applicationsCount.query = { ...(this.applicationsCount.query || {}), ...(query || {}) };
    const response = await analyticsService.getApplicationsByProgress(this.applicationsCount.query);
    this.applicationsCount.data = response?.data;
  },
  async fetchSalesByDate(query) {
    this.salesByDate.data = null;
    this.salesByDate.query = { ...(this.salesByDate.query || {}), ...(query || {}) };
    const params = { data_type: 'sum', status: 5, ...this.salesByDate.query };
    const response = await analyticsService.getApplicationsAnalytics(params);
    this.salesByDate.data = response?.data;
  },
  async fetchSalesByStores(query) {
    this.salesByStores.data = null;
    this.salesByStores.query = { ...(this.salesByStores.query || {}), ...(query || {}) };
    const response = await analyticsService.getApplicationsByStores(this.salesByStores.query);
    this.salesByStores.data = response?.data;
  },
  async fetchApplicationsMean(query) {
    this.applicationsMean.data = null;
    this.applicationsMean.query = { ...(this.applicationsMean.query || {}), ...(query || {}) };
    const response = await analyticsService.getApplicationsMean(this.applicationsMean.query);
    this.applicationsMean.data = response?.data;
  },
  async fetchDemography(query) {
    this.demography.data = null;
    this.demography.query = { ...(this.demography.query || {}), ...(query || {}) };
    const response = await analyticsService.getDemography(this.demography.query);
    this.demography.data = response?.data;
  },
  async fetchConsultantsReport(query) {
    this.consultants.data = null;
    this.consultants.query = { ...(this.consultants.query || {}), ...(query || {}) };
    const response = await analyticsService.getConsultantsReport(this.consultants.query);
    this.consultants.data = response?.data;
  },
  async fetchPopularCategories(query) {
    this.popularCategories.data = null;
    this.popularCategories.query = { ...(this.popularCategories.query || {}), ...(query || {}) };
    const response = await analyticsService.getPopularCategories(this.popularCategories.query);
    this.popularCategories.data = response?.data;
  },
  async fetchPopularItems(query) {
    this.popularItems.data = null;
    this.popularItems.query = { ...(this.popularItems.query || {}), ...(query || {}) };
    const response = await analyticsService.getPopularItems(this.popularItems.query);
    this.popularItems.data = response?.data;
  },
};
