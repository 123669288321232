export default {
  pages: {
    knowledgeBase: {
      title: 'Bilimlar bazasi',
      videoCard: {
        more: 'Batafsil',
      },
    },
  },
};
