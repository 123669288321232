import { defineStore } from 'pinia';
import { actions } from './actions';
import { getters } from './getters';

export const useProblemCaseStore = defineStore('ProblemCaseStore', {
  state: () => {
    return {
      problemCase: null,
      problemStatuses: null,
      newProblemsCount: null,
    };
  },
  actions,
  getters,
});
