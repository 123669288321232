export default {
  features: {
    login: {
      btns: {
        login: 'Kirish',
        resetPassword: 'Parolni unutdingizmi?',
        askAccount: ['Hisobingiz bormi?', 'Bu erga kiring'],
        confirmSms: 'SMSni tasdiqlash',
        restorePassword: 'Parolni qayta tiklash',
        sendSms: 'SMS yuborish',
        repeatSmsSend: 'Qayta yuborish',
        becomePartner: 'Hamkorlik',
      },
      titles: {
        login: 'Hisobingizga kiring',
        support: "Savollaringiz bormi? Bizga qo'ng'iroq qiling:",
        register: "Ro'yxatdan o'tish",
        passwordTitle: 'Parolni tiklash',
        passwordSubTitle: 'Sizning raqamingizga tasdiqlash kodi yuboriladi',
        sendCode: 'Kod {phone} raqamiga yuborildi',
        enterNewPassword: 'Yangi parollarni kiriting',
        restoredPassword: "Sizning so'rovingiz bo'yicha kirish paroli qayta tiklandi.",
      },
      password: {
        maxLength: 'Eng kamida 8 ta belgi',
        oneLowerCase: 'Kamida bitta katta va bitta kichik harf',
        onlyLatin: 'Faqat lotin harflari',
        minOneNumber: 'Kamida bitta raqam',
      },
      fields: {
        rememberMe: 'Meni eslab qolish',
        phone: 'Telefon raqami',
        password: 'Parol',
        passwordRepeat: 'Parolni takrorlang',
        storeName: "Do'kon nomi",
        region: 'Viloyat',
        regionValue: 'Viloyatni tanlang',
        aboutStore: "Do'koningiz haqida gapirib bering",
        name: 'Ismingiz',
        smsCode: 'SMSdagi kod',
      },
      errors: {
        passwordMismatch: 'Parollar mos emas',
      },
    },
  },
};
