export default {
  titles: {
    analytics: 'Аналитика',
  },
  panel: {
    titles: {
      applicationsCount: 'Количество заявок',
      sales: 'Продажи',
      salesByDate: 'Продажи',
      salesByStores: 'Продажи по магазинам',
      applicationsMean: 'Средний чек',
      demography: 'Демография',
      consultants: 'Консультанты',
      popularCategories: 'Популярные категории',
      popularItems: 'Популярные товары',
      applicationsSum: 'Сумма заявок',
    },
  },
  tabs: {
    sales: 'Продажи',
    applications: 'Заявки',
    applicationsMean: 'Средний чек',
    popularCategories: 'Популярные категории',
    popularItems: 'Популярные товары',
    demography: 'Демография',
    consultants: 'Консультанты',
    monthly: 'За месяц',
    yearly: 'За год',
  },
  select: {
    labels: {
      source: 'Источник',
      store: 'Магазин',
      consultant: 'Консультант',
      applicationStatus: 'Статус заявки',
      period: 'Период',
      month: 'Месяц',
      year: 'Год',
      age: 'Возраст',
      limit: 'Отображать в таблице по:',
    },
    options: {
      loading: 'Загружается...',
      all: 'Все',
      months: [
        '',
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      ages: ['18-25', '26-32', '33-40', '41-55', '56 и больше'],
    },
    titles: {
      choose: 'Выберите значение',
      consultant: 'Консультант',
      store: 'Введите название магазина',
    },
  },
  table: {
    headers: {
      titles: {
        items: 'Товар',
        sales: 'Продажи',
        salesSum: 'Продаж на сумму',
        price: 'Цена',
        category: 'Категория',
        categorySum: 'Продаж в категории',
      },
    },
  },
  chart: {
    labels: {
      problematicCredits: 'Проблемные рассрочки',
      approvedApplications: 'Оформленные заявки',
      female: 'Женщины',
      male: 'Мужчины',
    },
  },
  errors: {
    noData: 'По данному запросу ничего не найдено',
  },
  radio: {
    labels: {
      sum: 'Сумма',
      count: 'Количество',
    },
  },
};
