import { problemsService } from '../services/problems.service';
import { clientsService } from '../../clients/services/clients.service';

export const actions = {
  async fetchProblemCase({ id, params }) {
    const problem = await problemsService.getProblem(id, params);
    const clientId = problem?.data?.client_id;
    const client = await clientsService.getClient(clientId);
    this.problemCase = { ...(problem?.data || {}), client: client?.data || {} };
  },
  async fetchProblemsStatuses() {
    if (!this.problemStatuses) {
      const statuses = await problemsService.getProblemStatuses();
      this.problemStatuses = statuses?.data;
    }
  },
  async fetchNewProblemsCount() {
    const maxCount = 100;
    const statusId = this.problemStatuses?.find(el => el.name === 'Новый')?.id;
    const result = await problemsService.getProblems({ status_id: statusId, per_page: maxCount });
    const problemsCount = result.data?.data?.length;
    this.newProblemsCount = statusId && problemsCount ? problemsCount : 0;
  },
};
