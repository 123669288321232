<template>
  <at-dropdown :title="name[$i18n?.locale]" position="right">
    <at-dropdown-item @click="changeLang('ru')">{{ name.ru }}</at-dropdown-item>
    <at-dropdown-item @click="changeLang('uz')">{{ name.uz }}</at-dropdown-item>
  </at-dropdown>
</template>

<script>
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'BaseLangChange',
    components: {
      AtDropdown: defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtDropdown/AtDropdown')),
      AtDropdownItem: defineAsyncComponent(() =>
        import('@/plugins/aliftech-ui/components/AtDropdownItem/AtDropdownItem')
      ),
    },
    data() {
      return {
        lang: this.$i18n.locale || 'ru',
        name: {
          ru: 'Русский',
          uz: "O'zbek",
        },
      };
    },
    methods: {
      changeLang(lang) {
        if (this.$i18n.locale !== lang) {
          this.$cookies.set('lang', lang, { expire: '1Y' });
          window.location.reload(true);
        }
      },
    },
  };
</script>
