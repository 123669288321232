export const debounce = (callback, delay = 500) => {
  let timeoutId;

  return function executedFunction(...args) {
    const func = () => {
      clearTimeout(timeoutId);
      callback(...args);
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
};

export default debounce;
