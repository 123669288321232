import { clientsService } from '../services/clients.service';
import { checkType } from '@/plugins/aliftech-ui/utils';
import i18n from '@/plugins/i18n';

export const actions = {
  async fetchFiles(id) {
    this.files.loading = true;
    return clientsService
      .getFiles(id)
      .then(response => this.setResult({ name: 'files', payload: response?.data, id }))
      .catch(error => this.setError({ name: 'files', payload: error }));
  },
  async uploadFile({ id, params }) {
    this.files.updating = true;
    return clientsService
      .uploadFile(id, params)
      .then(response => {
        const result = this.files?.result?.filter?.(file => !file.empty) || [];
        result.push(response?.data);
        this.setResult({ name: 'files', payload: result, id });
      })
      .catch(error => {
        this.setError({ name: 'files', payload: error });
        throw error;
      });
  },
  async removeFile({ clientId, id }) {
    this.files.updating = true;
    return clientsService
      .removeFile(clientId, id)
      .then(() => {
        const result = this.files.result?.filter?.(file => file.id?.toString?.() !== id?.toString?.());
        this.setResult({ name: 'files', payload: result, id: clientId });
      })
      .catch(error => this.setError({ name: 'files', payload: error }));
  },
  async fetchClientLimit(clientId) {
    const response = await clientsService.getLimit(clientId);
    this.limit = response?.data?.current_amount || 0;
  },
  async fetchPassport(clientId) {
    if (!this.passport) {
      const response = await clientsService.getPassport(clientId);
      this.passport = response?.data || null;
    }
    return Promise.resolve(this.passport);
  },
  setResult({ name, payload, id }) {
    if (checkType(this[name], 'object')) {
      this[name] = {
        ...this[name],
        loading: false,
        updating: false,
        alert: null,
        errors: null,
        error: null,
        result: payload,
        id,
      };
    }
  },
  setError({ name, payload }) {
    if (checkType(this[name], 'object') && payload) {
      let alert = null;
      let errors = null;
      let error = null;
      if (checkType(payload, 'object') && 'errors' in payload) {
        errors = payload.errors;
      } else if (checkType(payload, 'object')) {
        error = payload;
        if ('message' in payload) {
          alert = { type: 'danger', message: payload.message, id: new Date().getTime(), dismissible: true };
        }
      } else if (checkType(payload, 'string')) {
        alert = { type: 'danger', message: payload, id: new Date().getTime(), dismissible: true };
      } else {
        alert = {
          type: 'danger',
          message: i18n.global.t('system.errors.somethingWentWrong'),
          id: new Date().getTime(),
        };
      }
      this[name] = { ...this[name], loading: false, updating: false, errors, error, alert };
    }
  },
  setSuccess({ name, payload }) {
    if (checkType(this[name], 'object')) {
      const alert = { type: 'success', message: payload, id: new Date().getTime(), dismissible: true };
      this[name] = { ...this[name], loading: false, updating: false, errors: null, error: null, alert };
    }
  },
};
