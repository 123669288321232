<template>
  <div class="flex flex-col flex-wrap justify-start items-center min-h-screen">
    <div class="w-full block py-6">
      <div class="container mx-auto px-4">
        <div class="-m-1 sm:-m-2 flex justify-end w-full">
          <div class="p-1 sm:p-2">
            <at-button :to="{ name: ROUTE_NAMES.registrationInfo }">
              {{ $t('auth.btns.becomePartner') }}
            </at-button>
          </div>
          <div class="p-1 sm:p-2">
            <base-theme-change></base-theme-change>
          </div>
          <div class="p-1 sm:p-2">
            <base-lang-change></base-lang-change>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full my-auto py-4">
      <div class="container mx-auto px-4">
        <div class="flex items-center justify-between -m-2">
          <div class="block p-2 lg:w-5/12 md:w-8/12 w-full">
            <div class="block w-full" :class="{ relative: animated }">
              <router-view v-slot="{ Component }">
                <component :is="Component"></component>
              </router-view>
            </div>
          </div>
          <div class="p-2 lg:w-6/12 md:4/12 md:block hidden">
            <svg-auth-illustration></svg-auth-illustration>
          </div>
        </div>
      </div>
      <div class="block w-full mt-12">
        <div class="container mx-auto px-4">
          <div class="block mb-6">
            <h2 class="text-gray-400 text-lg font-bold">{{ $t('auth.titles.support') }}</h2>
          </div>
          <div class="flex flex-wrap items-start sm:-m-3 -m-1">
            <div v-for="(phone, index) in getPhones" :key="'auth-page-phones-' + index" class="block sm:p-3 p-1">
              <a :href="'tel:' + phone.value" class="sm:text-lg text-gray-900 dark:text-white font-bold">
                {{ phone.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineAsyncComponent, ref, computed } from 'vue';
  import { ROUTE_NAMES } from '../constants/routeNames';

  const animated = ref(false);
  const phones = ['+998 90 052 53 17', '+998 90 014 04 42'];
  const getPhones = computed(() => {
    return phones.map(phone => ({ value: '+' + phone.replace(/\D/g, ''), title: phone }));
  });

  const SvgAuthIllustration = defineAsyncComponent(() => import('../components/SvgAuthIllustration'));
  const AtButton = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtButton/AtButton'));
</script>
