import { onMounted, onUnmounted } from 'vue';
import { useNasiyaGlobalStore } from '@/modules/nasiya/common/stores';
import { useUserStore } from '@/modules/main/user/stores';

const useJivoFieldsAutoComplete = () => {
  const globalStore = useNasiyaGlobalStore();
  const userStore = useUserStore();

  onMounted(() => {
    window.jivo_onChangeState = async state => {
      if (state === 'chat') {
        await globalStore.fetchMerchantRelations();

        window.jivo_api.setContactInfo({
          name: globalStore.merchantRelations.store.name,
          phone: '+' + userStore.me.phone,
        });
      }
    };
  });

  onUnmounted(() => {
    window.jivo_onChangeState = undefined;
  });
};

export { useJivoFieldsAutoComplete };
