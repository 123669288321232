export default {
  index: {
    title: 'Сотрудники',
    placeholders: {
      store: 'Магазин',
    },
    allStores: 'Все магазины',
    headers: {
      id: 'ID',
      name: 'Имя',
      acquiring: 'Эквайринг',
      qrcode: 'QR код',
      store: 'Магазин',
    },
    btns: {
      addEmployee: 'Добавить сотрудника',
    },
  },
  show: {
    goBack: 'Назад',
    changePhone: 'Сменить телефон',
    changePhoneModal: {
      title: 'Сменить телефон',
      labels: {
        code: 'Код',
        phoneNumber: 'Номер телефона',
      },
      placeholders: {
        code: 'Введите полученный код',
      },
      btns: {
        sendSms: 'Отправить смс',
        cancel: 'Отменить',
        confirm: 'Подтвердить',
      },
    },
    baseInfo: {
      save: 'Сохранить',
      title: 'Информация',
      fio: 'ФИО',
      phone: 'Телефон',
      partner: 'Партнёр',
      store: 'Магазин',
    },
    permissions: {
      title: 'Настройка доступов',
      merchant_acquiring: 'Управление с эквайринг',
      merchant_admin: 'Админ панель',
      merchant_alifnasiya: 'Управление с алифнасия',
      merchant_alifshop: 'Управление с алифшоп',
      merchant_applications: 'Управление с заявками',
      merchant_deliveries: 'Управление с доставкой',
      merchant_manager: 'Управление с сотрудниками',
      merchant_moderation_goods: 'Управление с модерацией товаров',
      merchant_scoring_card: 'Управление скоринг карт',
      merchant_upload_goods: 'Управление с товарами',
    },
  },
  modal: {
    titles: {
      qrcode: 'QR код сотрудника',
    },
    btns: {
      print: 'Распечатать',
      download: 'Скачать',
    },
  },
  create: {
    title: 'Добавление нового сотрудника',
    search: {
      label: 'Номер телефона',
      placeholder: '(00) 000-00-00',
    },
    store: {
      label: 'Магазин',
      placeholder: 'Выберите магазин',
    },
    noUser: 'Не удалось найти пользователя',
    add: 'Добавить',
    userIsEmployee: 'Пользователь с данным номером уже являяется сотрудником одного из мерчантов',
    createEmployee: {
      btns: {
        sendSms: 'Отправить смс',
        addEmployee: 'Добавить сотрудника',
      },
      placeholders: {
        code: 'Введите полученный код',
        name: 'Введите имя',
        store: 'Введите магазин',
      },
      labels: {
        phone: 'Номер телефона',
        code: 'Код',
        name: 'Имя',
        store: 'Магазин',
      },
    },
  },
};
