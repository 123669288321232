<template>
  <div id="main-app" class="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white">
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>

  <vue-query-devtools button-position="top-right" position="top" />
</template>

<script setup>
  import { useTheme } from '@/hooks';
  import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
  import { useControlJivoWidgetVisibility } from '@/hooks/useControlJivoWidgetVisibility';

  useControlJivoWidgetVisibility();

  const { theme, setTheme } = useTheme();
  setTheme(theme.value);
</script>
