// todo refactor module name
// const moduleName = 'module_acquiring';
// import { ROUTE_NAMES } from '../../common/constants/routeNames';

export default [
  // {
  //   path: '/acquiring/payments',
  //   name: ROUTE_NAMES.paymentsList,
  //   component: () => import('../views/AcquiringPaymentsIndexPage'),
  //   meta: {
  //     title: 'Платежи',
  //     module: moduleName,
  //   },
  // },
];
