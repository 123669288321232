export default {
  pages: {
    calculatorPreviewPayment: {
      title: 'График платежей',
      btns: {
        close: 'Закрыть',
        print: 'Печать',
      },
    },
  },
};
