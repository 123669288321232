import NasiyaLayout from './layouts/NasiyaLayout';
import NasiyaPrintLayout from './layouts/NasiyaPrintLayout.vue';
import home from '../home/routers';
import warehouse from '../warehouse/routers';
import notifications from '../notifications/routers';
import calculator from '../calculator/routers';
import previewPayment from '../calculator/routers/preview';
import deliveries from '../deliveries/routers';
import faq from '../faq/routers';
import problems from '../problems/routers';
import rejects from '../rejects/routers';
import sales from '../sales/routers';
import analytics from '../analytics/routers';
import excels from '../excels/routers';
import clients from '../clients/routers';
import applications from '../applications/routers';
import managerApplications from '../manager-applications/routers';
import invoice from '../invoice/routers';
import qrApplications from '../qr-applications/routers';
import requests from '../requests/routers';
import bonuses from '../bonuses/routers';
import { ROUTE_NAMES } from '@/modules/nasiya/common/constants/routeNames';
import setDocumentTitle from '@/router/middleware/setDocumentTitle';
import requireAuthToken from '@/router/middleware/requireAuthToken';
import fetchAuthUser from '@/router/middleware/fetchAuthUser';
import fetchPrmApp from '@/router/middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from '@/router/middleware/requireAccessToAtLeastOneModule';
import fetchNasiyaResources from '@/router/middleware/fetchNasiyaResources';
import requireAccessToModule from '@/router/middleware/requireAccessToModule';
import requirePermissions from '@/router/middleware/requirePermissions';
import { useIsNewOnboarding } from '@/modules/main/common/stores';

const mainRoutes = {
  path: '/nasiya/',
  component: NasiyaLayout,
  children: [
    ...home,
    ...warehouse,
    ...notifications,
    ...calculator,
    ...deliveries,
    ...faq,
    ...problems,
    ...rejects,
    ...sales,
    ...analytics,
    ...excels,
    ...clients,
    ...applications,
    ...managerApplications,
    ...invoice,
    ...qrApplications,
    ...requests,
    ...bonuses,
    {
      path: '/nasiya/calculator/preview-payment/to-fsd',
      name: ROUTE_NAMES.calculatorPreviewToFsd,
      component: async () => {
        const { CalculatorPreviewPaymentPage } = await import('@/to-fsd/pages/calculator-preview-payment');

        return CalculatorPreviewPaymentPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('График платежей'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_azo'),
          requirePermissions(['merchant_alifnasiya', 'merchant_applications']),
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'home/to-fsd/old-onboarding',
      name: ROUTE_NAMES.homeToTsdOldOnboarding,
      component: async () => {
        const { HomePage } = await import('@/to-fsd/pages/old-onboarding-home');

        return HomePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Главная'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_azo'),
          requirePermissions(['merchant_alifnasiya']),
          fetchNasiyaResources,
          ({ next }) => {
            const isNewOnboarding = useIsNewOnboarding();

            if (isNewOnboarding.value) {
              return {
                name: ROUTE_NAMES.homeToTsd,
              };
            }

            return next();
          },
        ],
      },
    },
    {
      path: 'home/to-fsd',
      name: ROUTE_NAMES.homeToTsd,
      component: async () => {
        const { HomePage } = await import('@/to-fsd/pages/home');

        return HomePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Главная'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          requireAccessToModule('module_azo'),
          requirePermissions(['merchant_alifnasiya']),
          fetchNasiyaResources,
          ({ next }) => {
            const isNewOnboarding = useIsNewOnboarding();

            if (!isNewOnboarding.value) {
              return {
                name: ROUTE_NAMES.homeToTsdOldOnboarding,
              };
            }

            return next();
          },
        ],
      },
    },
    {
      path: 'knowledge-base',
      name: ROUTE_NAMES.knowledgeBase,
      component: async () => {
        const { KnowledgeBasePage } = await import('@/to-fsd/pages/knowledge-base');

        return KnowledgeBasePage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('База знаний'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'knowledge-base/videos/:videoId',
      name: ROUTE_NAMES.knowledgeBaseShow,
      component: async () => {
        const { KnowledgeBaseShowPage } = await import('@/to-fsd/pages/knowledge-base-show');

        return KnowledgeBaseShowPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('База знаний'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
    {
      path: 'installment-payment',
      name: ROUTE_NAMES.installmentPayment,
      component: async () => {
        const { InstallmentPaymentPage } = await import('@/to-fsd/pages/installment-payment');

        return InstallmentPaymentPage;
      },
      meta: {
        middlewares: [
          setDocumentTitle('Оплата рассрочки'),
          requireAuthToken,
          fetchAuthUser,
          fetchPrmApp,
          requireAccessToAtLeastOneModule,
          fetchNasiyaResources,
        ],
      },
    },
  ],
};

const printRoutes = {
  path: '/nasiya/',
  component: NasiyaPrintLayout,
  children: [...previewPayment],
};

export default router => {
  router.addRoute(mainRoutes);
  router.addRoute(printRoutes);
};
