import { useQuery } from '@tanstack/vue-query';
import { getPassportIssuers } from '../api';

const useGetPassportIssuersQuery = () => {
  return useQuery({
    queryKey: ['global', 'passport-issuers'],
    async queryFn() {
      const response = await getPassportIssuers();

      const result = {
        list: [],
        options: [],
        dataList: [],
      };

      const data = response.data;

      if (Array.isArray(data)) {
        data.forEach(issuer => {
          result.list.push(issuer);
          result.dataList.push(issuer.name);
          result.options.push({ title: issuer.name, value: issuer.name });
        });
      }

      return result;
    },
    retry: 0,
    gcTime: Infinity,
    staleTime: Infinity,
  });
};

export default useGetPassportIssuersQuery;
