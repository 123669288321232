export default {
  pages: {
    rejectCasesList: {
      title: 'Возвраты',
      noResults: 'Нет результатов',
      headers: {
        client: 'Клиент',
        status: 'Статус',
        type: 'Тип',
        date: 'Дата',
        reason: 'Причина',
      },
      rejectType: {
        full: 'Полный',
        partial: 'Частичный',
      },
      note: {
        title: 'Причина возврата',
        noItem: 'Товар не остался',
        wrongItem: 'Вбили неправильный товар',
        defectiveItem: 'Бракованный товар',
        clientRefused: 'Клиент отказался от товара',
        goodsWereNotDelivered: 'Товар не доставили',
        other: 'Другое',
      },
    },
  },
};
