export default {
  pages: {
    calculator: {
      title: 'Калькулятор',
      item: {
        label: 'Название товара',
        placeholder: 'Название и артикул товара',
        hint: 'Введите название и артикул товара',
      },
      applicationSum: {
        label: 'Сумма заявки',
        placeholder: '0',
      },
      downPaymentAmount: {
        label: 'Сумма предоплаты',
        placeholder: '0',
      },
    },
  },
};
