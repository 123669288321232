import mainConnect, { generateError } from '@/services';

export const problemsService = {
  /**
   * Get problem cases
   *
   * @param {Object} params
   * @param {Number} params.status_id - status id
   * @param {String} params.q - search
   *
   * @returns {Promise<Problems>}
   * @typedef {Object} Problems
   * @property {Number} data[].id - id
   * @property {Number} data[].status_id - status id
   * @property {String} data[].comment_from_merchant - comment from merchant
   * @property {String} data[].created_at - created at
   * @property {Number} meta.last_page - last page
   *
   * @throws {Error}
   */
  getProblems(params) {
    return mainConnect.get('/merchants/problem-cases', { params: params }).catch(err => generateError(err));
  },

  /**
   * Get problem case
   *
   * @param {Number} id - id
   * @param {Object} params
   *
   * @returns {Promise<Problem>}
   * @typedef {Object} Problem
   * @property {Number} id - id
   * @property {Number} client_id - client id
   * @property {Number} status_id - status id
   * @property {String} description - description
   * @property {String} merchant_comments - merchant_comments
   * @property {String} application_items[].good_name - good name
   * @property {Number} application_items[].price - price
   * @property {String} created_at - created_at
   * @property {String} deadline - deadline
   *
   * @throws {Error}
   */
  getProblem(id, params = {}) {
    return mainConnect
      .get(`/merchants/problem-cases/${id}`, { params: { ...params } })
      .catch(err => generateError(err));
  },

  /**
   * Get problem statuses
   *
   * @param {Object} params
   *
   * @returns {Promise<ProblemStatuses>}
   * @typedef {Object} ProblemStatuses
   * @property {Number} id - id
   * @property {Object} lang - lang
   * @property {String} name - name
   *
   * @throws {Error}
   */
  getProblemStatuses(params = {}) {
    return mainConnect
      .get(`/merchants/problem-cases/statuses`, { params: { ...params } })
      .catch(err => generateError(err));
  },

  /**
   * Set problem case status
   *
   * @param {Number} id
   * @param {Object} params
   * @param {Number} params.status_id - status id
   *
   * @returns {Promise<Problem>}
   * @typedef {Object} Problem
   * @property {Number} status_id - status id
   * @property {String} status_key - status key
   *
   * @throws {Error}
   */
  setStatus(id, params) {
    return mainConnect.put(`/merchants/problem-cases/${id}/set-status`, params).catch(err => generateError(err));
  },

  /**
   * Set problem case comment
   *
   * @param {Number} id
   * @param {Object} params
   * @param {String} params.body - body
   *
   * @returns {Promise<Problem>}
   * @typedef {Object} Problem
   * @property {String} comment_from_merchant - comment from merchant
   *
   * @throws {Error}
   */
  setComment(id, params) {
    return mainConnect.put(`/merchants/problem-cases/${id}/set-comment`, params).catch(err => generateError(err));
  },
};
