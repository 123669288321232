<template>
  <div class="bg-white dark:bg-gray-800 sticky top-0 z-[10]">
    <div class="border-b border-gray-300 dark:border-gray-700">
      <div class="h-16 flex items-center flex-wrap justify-start px-6 gap-2">
        <button v-if="withGoBackButton" @click="goBack()">
          <chevron-left-icon class="w-6 h-6 text-gray-400 text:bg-white" />
        </button>
        <h1 class="text-2xl flex items-center gap-x-2">
          <span v-if="secondaryTitle" class="text-gray-400 font-medium">
            {{ secondaryTitle }}
          </span>

          <span class="font-semibold">
            {{ primaryTitle }}
          </span>
        </h1>

        <div v-if="slots.actions" class="ml-auto flex items-center gap-2">
          <slot name="actions" />
        </div>
      </div>
    </div>

    <div v-if="hasBottomContent" class="px-6 py-3 border-b border-gray-300 dark:border-gray-700">
      <div class="flex h-full justify-start items-center flex-wrap gap-4">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, useSlots } from 'vue';

  import { ChevronLeftIcon } from '@heroicons/vue/outline';

  import useBack from '../hooks/use-back/useBack';

  defineProps({
    withGoBackButton: {
      type: Boolean,
      default: false,
    },
    primaryTitle: {
      type: String,
      required: true,
    },
    secondaryTitle: {
      type: String,
      default: null,
    },
  });

  const slots = useSlots();

  const goBack = useBack();

  const hasBottomContent = computed(() => slots.bottom);
</script>
