<template>
  <div v-click-outside="hide" class="relative border-0 p-0">
    <template v-if="loading">
      <at-skeleton type="input-with-label"></at-skeleton>
    </template>
    <template v-else>
      <at-input
        :model-value="value?.title"
        v-bind="$attrs"
        @update:model-value="search"
        @trailing-button-click="clearValue"
      >
        <template #trailingButton>
          <x-icon class="h-4 w-4" />
        </template>
      </at-input>
      <div
        v-if="showList"
        class="
          absolute
          w-full
          mt-2
          rounded-md
          bg-white
          max-h-40
          overflow-y-auto
          shadow-lg
          z-50
          dark:bg-gray-700
        "
      >
        <div v-if="fetching" class="flex flex-wrap items-center justify-center px-4 py-2">
          <at-loading></at-loading>
        </div>
        <ul v-else-if="items?.length" class="divide-y divide-gray-200 dark:divide-gray-700">
          <li
            v-for="(item, index) in items"
            :key="`base-api-search-${index}`"
            class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 cursor-pointer hover:text-gray-900 dark:hover:text-white dark:hover:text-white hover:bg-gray-100 dark:hover:bg-gray-800"
            @click="setValue({ value: item?.value, title: item?.title })"
          >
            {{ item?.title }}
          </li>
        </ul>
        <p v-else class="block text-center text-sm">
          {{ noData }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import { XIcon } from '@heroicons/vue/solid';

  export default defineComponent({
    name: 'BaseApiSearch',
  });
</script>

<script setup>
  import { ref, computed, defineAsyncComponent } from 'vue';

  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    modelValue: { type: Object, default: () => ({}) },
    noData: { type: String, default: () => 'Нет данных' },
    api: { type: Function, default: () => new Function() },
  });
  const value = computed({
    get: () => {
      return props.modelValue;
    },
    set: item => {
      emit('update:modelValue', { value: item.value, title: item.title });
    },
  });
  const showList = ref(false);

  function hide() {
    showList.value = false;
  }

  function setValue(val) {
    emit('update:modelValue', val);
    hide();
  }

  const items = ref([]);
  const loading = ref(false);
  const fetching = ref(false);

  const getCategory = value => {
    fetching.value = true;
    return props
      .api({ search: value })
      .then(res => {
        items.value = res?.data;
        return res;
      })
      .finally(() => {
        fetching.value = false;
      });
  };

  const clearValue = () => {
    if (value.value.value !== null || value.value.title !== null) {
      emit('update:modelValue', { value: null, title: null });
      getCategory();
      showList.value = true;
    }
  };

  const timeoutId = ref(undefined);
  const search = q => {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
      timeoutId.value = undefined;
    }
    timeoutId.value = setTimeout(() => {
      showList.value = true;
      return getCategory(q);
    }, 800);
  };

  const AtInput = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtInput/AtInput'));
  const AtLoading = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtLoading/AtLoading'));
  const AtSkeleton = defineAsyncComponent(() => import('@/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton'));
</script>
