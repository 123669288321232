import i18n from '@/plugins/i18n';
import { iterableToAtSelectOptions } from '@/utils/forms';
import { useMainStore } from '@/modules/main/common/stores';

export const getters = {
  _appLang: () => {
    const mainStore = useMainStore();
    return mainStore.lang;
  },
  getActivityAreaOptions: state => {
    return iterableToAtSelectOptions(state.activityAreas, state._appLang);
  },
  getRegionOptions: state => {
    return iterableToAtSelectOptions(state.app?.regions, state._appLang);
  },
  getRejectStatusOptions: state => {
    return [{ value: null, title: i18n.global.t('system.titles.all') }].concat(
      iterableToAtSelectOptions(state.creditsApp?.reject_case_statuses, state._appLang)
    );
  },
  getEmployeesOption: state => {
    const result = [];
    state.employees?.forEach(employee => {
      result.push({
        title: employee.user_name,
        value: employee.user_id,
      });
    });
    return result;
  },
  getApplicationStatusOptions: state => {
    return iterableToAtSelectOptions(state.app?.application_statuses, state._appLang);
  },
  getStoreOptions: state => {
    return iterableToAtSelectOptions(state.merchantRelations?.stores, state._appLang);
  },
  getStores: state => {
    return state.merchantRelations?.stores || [];
  },
  getRejectReasonOptions: state => {
    return [{ value: null, title: i18n.global.t('system.titles.all') }].concat(
      iterableToAtSelectOptions(state.app?.application_reject_reason, state._appLang)
    );
  },
  rejectReasons: state => {
    return state.app?.application_reject_reason || [];
  },
  getCancelReasonOptions: state => {
    return [{ value: null, title: i18n.global.t('system.titles.all') }].concat(
      iterableToAtSelectOptions(state.app?.application_cancel_reasons, state._appLang)
    );
  },
  getContactOptions: state => {
    return iterableToAtSelectOptions(state.app?.phone_contacts, state._appLang);
  },
  getDeliveryStatusOptions: state => {
    return iterableToAtSelectOptions(state.app?.delivery_statuses, state._appLang);
  },
  getDeliveryStatuses: state => {
    return state.app?.delivery_statuses || [];
  },
  getDeliveryStatusKeys: state => {
    return (
      state.app?.delivery_statuses?.reduce((acc, status) => {
        acc[status.key] = status.id;
        return acc;
      }, {}) || {}
    );
  },
  getClientStatusOptions: state => {
    return iterableToAtSelectOptions(state.app?.client_statuses, state._appLang);
  },
  getClientStatuses: state => {
    return state.app?.client_statuses || [];
  },
  isCentrifugeUsed: state => {
    return state.app?.centrifugo;
  },
  storeId: state => {
    return state.merchantRelations?.store?.id;
  },
};
