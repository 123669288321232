import mainConnect, { generateError } from '@/services';

export const creditsService = {
  /**
   * Get credits app
   *
   * @returns {Promise<CreditsApp>}
   * @typedef {Object} CreditsApp
   * @property {Object} cancel_reject_reasons - cancel reject reasons
   * @property {Object} reject_case_statuses - reject case statuses
   *
   * @throws {Error}
   */
  getApp() {
    return mainConnect.get('credits/app').catch(generateError);
  },

  /**
   * Get Preview Payment
   *
   * @param {Object} params
   * @param {Number} params.application_amount - application amount
   * @param {Number} params.application_condition_id - application condition id
   * @param {Boolean} params.avoid_weekends - avoid weekends
   * @param {Number} params.client_id - client id
   * @param {Object} params.condition - down condition
   * @param {Number} params.condition.commission - commission
   * @param {Number} params.condition.duration - duration
   * @param {String} params.contract_date - contract date
   * @param {Number} params.down_payment_amount - down payment amount
   * @param {Number} params.extra_days - extra days
   * @param {String} params.first_payment_date - first payment date
   *
   * @returns {Promise<PreviewPayment>}
   * @typedef {Object} PreviewPayment
   * @property {Number} amount - amount
   * @property {Number} client_commission - client_commission
   * @property {String} contract_date - contract_date
   * @property {Number} down_payment_amount - down_payment_amount
   * @property {Number} duration - duration
   * @property {Number} initial_extra_days - initial_extra_days
   * @property {Number} monthly_payment_amount - monthly_payment_amount
   * @property {Array} payments - payments
   * @property {Number} payments[].balance_after - balance after
   * @property {Number} payments[].balance_before - balance before
   * @property {Number} payments[].payment_amount - payment amount
   * @property {String} payments[].payment_date - payment date
   * @property {Number} prepayment - prepayment
   * @property {Number} total_amount - total_amount
   *
   * @throws {Error}
   */
  getPreviewPayment(params) {
    return mainConnect.post('credits/preview-payment', params).catch(generateError);
  },

  /**
   * Request to verify store
   *
   * @param {Number} applicationId - application id
   * @param {Object} params
   * @param {Boolean} params.isTrusted - isTrusted
   *
   * @returns {Promise<VerifyStore>}
   * @typedef {Object} VerifyStore
   *
   * @throws {Error}
   */
  verifyStore(applicationId, params) {
    return mainConnect.post(`credits/applications/${applicationId}/verify_store`, params).catch(generateError);
  },

  /**
   * Store credit
   *
   * @param {Object} params
   * @param {Number} params.application_id - application id
   * @param {String} params.code - code
   *
   * @returns {Promise<StoreCredit>}
   * @typedef {Object} StoreCredit
   *
   * @throws {Error}
   */
  storeCredit(params) {
    return mainConnect.post('credits/credits', params).catch(generateError);
  },

  /**
   * Calculate Credits
   *
   * @param {Object} params
   * @param {Array} params.data
   * @param {Number} params.data[].application_amount - application amount
   * @param {Number} params.data[].commission - commission
   * @param {Number} params.data[].condition_id - condition id
   * @param {Number} params.data[].down_payment_amount - down payment amount
   * @param {Number} params.data[].prepayment - prepayment
   * @param {Number} params.data[].duration - duration
   *
   * @returns {Promise<CalculateCredits>}
   * @typedef {Array} CalculateCredits
   * @property {Number} application_amount - application amount
   * @property {Number} commission - commission
   * @property {Number} commission_amount - commission amount
   * @property {Number} condition_id - condition id
   * @property {Number} down_payment_amount - down payment amount
   * @property {Number} duration - duration
   * @property {Number} monthly_payment_amount - monthly payment amount
   * @property {Number} total_amount - total amount
   *
   * @throws {Error}
   */
  calculateCredits(params) {
    return mainConnect.post(`credits/calculate-credits`, { data: params }).catch(generateError);
  },
};

export default creditsService;
