export default {
  widgets: {
    employeesTable: {
      headers: ['ID', 'Ismi', "Do'kon"],
      storeSelect: {
        placeholder: "Do'kon",
        allStores: "Barcha do'konlar",
      },
    },
  },
};
