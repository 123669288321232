import { mainConnect, generateError } from '@/services';

export const employeesService = {
  /**
   * Attach employee to store
   *
   * @param {Number} employeeId - employee id
   * @param {Number} params.store_id - id of store employee is being attached to
   *
   * @return {Promise}
   * @throws {Error}
   */

  attachEmployeeToStore(employeeId, params) {
    return mainConnect.put(`/merchants/users/${employeeId}`, params).catch(generateError);
  },

  /**
   * Get merchant employee
   *
   * @param {number} id - Employee id
   *
   * @return {Promise}
   * @throws {Error}
   */
  getEmployee(id) {
    return mainConnect.get(`merchants/users/${id}`).catch(generateError);
  },

  /**
   * Get merchant stores
   *
   * @return {Promise}
   * @throws {Error}
   */
  getStores() {
    return mainConnect.get(`stores/stores`).catch(generateError);
  },

  /**
   * Get merchant employees list
   *
   * @param {Object} query
   * @param {String} query.q - search query
   * @param {Number} query.page - page
   * @param {Number} query.per_page - pagination, default 15
   *
   * @returns {Promise<Users>}
   * @typedef {Object} Users
   * @property {Number} data[].id - company user id
   * @property {Number} data[].user_id - user id
   * @property {String} data[].phone - user phone
   * @property {String} data[].full_name - user name
   * @property {Boolean} data[].module_acquiring - module acquiring
   * @property {Boolean} data[].module_azo - module nasiya
   * @property {Boolean} data[].module_alifshop - module alifshop
   * @property {Boolean} data[].module_admin - is user admin
   * @property {Number} meta.last_page - last page for pagination
   * @throws {Error}
   */
  getMerchantEmployees(query) {
    return mainConnect.get(`merchants/users`, { params: query }).catch(generateError);
  },

  /**
   * Request to store merchant user
   *
   * @param {Object} params
   * @param {String} params.phone - phone number
   *
   * @return {Promise}
   * @throws {Error}
   */
  requestMerchantUser(params) {
    return mainConnect.post(`/merchants/users/request-store`, params).catch(generateError);
  },

  /**
   * Confirm request to store merchant user
   *
   * @param {Object} params
   * @param {Number} params.code - otp code
   * @param {Number} params.user_id - user id
   * @param {Number} params.company_id - company id
   *
   * @return {Promise}
   * @throws {Error}
   */
  storeMerchantUser(params) {
    return mainConnect.post(`/merchants/users`, params).catch(generateError);
  },

  /**
   * Request update phone
   *
   * @param {Number} userId - user id
   * @param {Object} body
   * @param {Number} body.phone - client phone
   *
   * @return {Promise}
   * @throws {Error}
   */
  requestUpdatePhone(userId, body) {
    return mainConnect.post(`/merchants/users/${userId}/request-update-phone`, body).catch(generateError);
  },

  /**
   * Update phone
   *
   * @param {Number} userId - user id
   * @param {Object} body
   * @param {Number} body.code - otp code
   * @param {Number} body.store_id - otp code
   * @param {Number} body.code - otp code
   * @param {Number} body.code - otp code
   *
   * @return {Promise}
   * @throws {Error}
   */
  updatePhone(userId, body) {
    return mainConnect.post(`/merchants/users/${userId}/update-phone`, body).catch(generateError);
  },

  /**
   * Update phone
   *
   * @param {Object} body
   * @param {Number} body.phone - client phone
   *
   * @return {Promise}
   * @throws {Error}
   */
  requestCreateUser(body) {
    return mainConnect.post(`/merchants/users/request-store`, body).catch(generateError);
  },

  /**
   * Update phone
   *
   * @param {Object} body
   * @param {Number} body.code - code
   * @param {Number} body.store_id - store id
   * @param {Number} body.name - client name
   * @param {Number} body.phone - client phone
   *
   * @return {Promise}
   * @throws {Error}
   */
  createUser(body) {
    return mainConnect.post(`/merchants/users/store-user`, body).catch(generateError);
  },
};
