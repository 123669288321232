import { useQuery } from '@tanstack/vue-query';
import { getCashbacksGeneralAmountFromMerchantUser } from '../api';
import { useUserStore } from '@/modules/main/user/stores';
import { computed } from 'vue';

const useGetCashbacksGeneralAmountFromMerchantUser = () => {
  const userStore = useUserStore();

  const authId = computed(() => userStore.me.id);

  return useQuery({
    queryKey: ['cashbacks', 'general-amount', { authId }],
    async queryFn({ queryKey }) {
      const { authId } = queryKey[2];

      const response = await getCashbacksGeneralAmountFromMerchantUser(authId);

      return response.data;
    },
    retry: 0,
    gcTime: 0,
    staleTime: Infinity,
  });
};

export default useGetCashbacksGeneralAmountFromMerchantUser;
