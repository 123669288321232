import { checkType } from '@/plugins/aliftech-ui/utils';

const commarize = (number, locale = 'ru', min) => {
  if (!checkType(number, 'number')) {
    number = Number(number);
  }
  min = min || 1e3;
  // Alter numbers larger than 1k
  if (number >= min) {
    const units = {
      ru: [' тыс.', ' млн.', ' млрд.', ' трлн.'],
      uz: [' ming', ' mln.', ' мlr.', ' trl.'],
      en: [' k', ' M', ' B', 'T'],
    };
    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    const unit = Math.floor((number.toFixed(0).length - 1) / 3) * 3;
    // Calculate the remainder
    const num = (number / ('1e' + unit)).toFixed(1);
    const unitName = units?.[locale || 'ru']?.[Math.floor(unit / 3) - 1];

    // output number remainder + unitname
    return num + unitName;
  }

  // return formatted original number
  return number.toLocaleString();
};

export default commarize;
