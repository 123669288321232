import { Centrifuge } from 'centrifuge';

const url = process.env.VUE_APP_CENTRIFUGE_URL;
const token = process.env.VUE_APP_CENTRIFUGE_TOKEN;

const centrifuge = new Centrifuge(url);

centrifuge.setToken(token);

centrifuge.connect();

export default centrifuge;
