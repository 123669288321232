export default {
  entities: {
    posTerminal: {
      headers: {
        id: 'ID',
        posName: 'Kassa',
        storeName: "Do'kon",
        qrcode: 'QR kod',
      },
      store: "Do'kon",
      modal: {
        titles: {
          qrcode: 'Xodimning QR kodi',
        },
        btns: {
          print: 'Chop etish',
          download: 'Yuklab olish',
        },
      },
    },
  },
};
