export default {
  statuses: {
    CHARGE: 'Одобрено',
    NOT_APPROVED: 'Не одобрено',
    REJECT: 'Списание за возврат',
  },
  index: {
    ok: 'Хорошо',
    introductionModal: {
      title: 'Приглашение',
      body: [
        'Уважаемые партнеры и консультанты',
        'Рады сообщить вам о запуске наших новых бонусов!',
        'Теперь вы можете предоставлять рассрочки клиентам и получать за это дополнительные бонусы в нашем приложении alif mobi.',
      ],
      confirm: 'Понятно',
    },
    tip: 'Можете узнать подробно о бонусах',
    title: 'Бонусы',
    dropdown: {
      title: 'Как это работает?',
      menu: {
        title: 'Список требований:',
      },
    },
    filters: {
      bonusStatus: 'Статус бонуса',
      dateCreated: 'Дата создания',
      dateAccrual: 'Дата начисления',
      clear: 'Сброс',
      allStatuses: 'Все статусы',
    },
    card: {
      title: 'Начислено за всё время',
      sum: 'сум',
      statuses: {
        charged: 'Одобрено за все время',
        toPay: 'Готово к начислению',
        notApproved: 'Не одобрено',
        reject: 'Списано за возврат',
      },
      desc: {
        total:
          'Общие начисления с момента подключения бонусной системы (данные обновляются 1-го числа и 16-го числа каждого месяца)',
        charge: 'Есть оформленная заявка, и есть активная сессия в alif mobi',
        notApproved: 'Есть оформленная заявка, но нет активной сессии в alif mobi',
        reject: 'Списание суммы бонуса за возврат рассрочки, по которой ранее были начисления в alif mobi',
        toPay: 'Накопленные бонусы с момента последнего начисления. При последующем начислении счетчик обнуляется',
      },
    },
    table: {
      cols: {
        applicationAmount: 'СУММА ЗАЯВКИ',
        cashbackAmount: 'БОНУС',
        contractDate: 'ДАТА ОФОРМЛЕНИЯ',
        chargedAt: 'ДАТА НАЧИСЛЕНИЯ',
        status: 'СТАТУС',
      },
      total: 'Итого',
      paginationInfo: 'Показано от {from} до {to} из {total} результатов',
    },
  },
};
