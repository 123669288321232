<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="block w-full" viewBox="0 0 760.598 433.056">
    <g>
      <path
        d="M284.076,277.561s1.861-8.935-1.13-14.539-4.859-2.153-4.677,2.428,1.1,14.513,1.1,14.513"
        fill="#EDA59F"
      ></path>
      <path
        d="M282.818,272.844s-2.14-19.335-18.465-5.753c-3.141,2.613,5.551,15.763,5.551,15.763Z"
        fill="#EDA59F"
      ></path>
    </g>
    <path
      d="M169.819,416.925c-9.812,0-14.861-3.613-15.218-3.88l1.193-1.6c.144.1,14.794,10.435,43.513-5.48,30.214-16.743,33.415-31.511,41.147-67.187,2.477-11.431,5.561-25.656,10.184-42.593,4.227-15.486,20.44-31.946,42.16-31.946v2c-20.727,0-36.2,15.7-40.23,30.473-4.61,16.886-7.687,31.082-10.16,42.489-7.638,35.245-11.125,51.331-42.132,68.514C187.382,414.858,177.238,416.925,169.819,416.925Z"
    ></path>
    <path
      d="M264.35,267.094a3.47,3.47,0,0,0-.152,1.058c.31,1.805,2.27,3.876,3.7,4.094,2.33.353,8.928-3.02,11.04-4.966,1.974-1.823-.78-5.97-1.124-6.522a.359.359,0,0,1-.037-.051C275.829,258.14,265.224,264.524,264.35,267.094Z"
      fill="#EDA59F"
    ></path>
    <g>
      <path d="M379.719,252.575s-7.733-4.1-6.006-25.157l-15.976-2.962s1.795,18.878-6.288,24.872Z" fill="#EDA59F"></path>
      <g opacity="0.15">
        <path d="M354.723,245.381,368,235.228l-10.424-.108A28.756,28.756,0,0,1,354.723,245.381Z"></path>
      </g>
      <path
        d="M382.778,219.253c-1.106,6.389-4.592,12.438-10.228,14.5a40.485,40.485,0,0,1-19.357,2.615c-17.49-1.434-14.03-41.2-5.727-48.926s32.673-2.833,35.638,10.087C384.637,204.213,383.831,213.173,382.778,219.253Z"
        fill="#EDA59F"
      ></path>
      <path
        d="M335.233,183.753c0-5.283,6.573-13.938,24.111-2l.017.012a49.856,49.856,0,0,1,13.529,1.479,25.457,25.457,0,0,1,8.715,4.48h0c4.881,3.96,8.439,10.475,6.781,20.945-2.88,18.195-10.333,23.051-14.089,25.283-.957.568-1.189.1-1.521-.957a81.18,81.18,0,0,1-2.229-11.97c-.571-4.2-4.345-6.87-3.889-11.9.16-1.759,2.991-8.2-5.772-8.274-4.928-.04-3.086.156-8.346.156-.749,0-1.412-.034-2.022-.088l-.006,0C332.353,200.919,335.233,189.035,335.233,183.753Z"
        fill="#0f1011"
      ></path>
      <path
        d="M365.3,216.429a6.143,6.143,0,0,0,12.244,1,6.04,6.04,0,0,0-5.62-6.624C367.543,210.666,365.574,213.048,365.3,216.429Z"
        fill="#EDA59F"
      ></path>
      <path
        d="M368,196.943l4.523,1.656s-1.6,22.6-2.869,28.775-4.807,10.2-8.821,10.544c-6.163.528-10.629.57-15.15-1.045-5.1-1.822-4.384-15.5-4.384-15.5s16.405,1.777,20.944-.4S368,196.943,368,196.943Z"
        fill="#0f1011"
      ></path>
      <g>
        <circle cx="357.759" cy="213.883" r="1.449" fill="#0f1011"></circle>
        <circle cx="346.328" cy="212.946" r="1.449" fill="#0f1011"></circle>
        <path
          d="M343.818,208.858a.681.681,0,0,1-.009-1.362l3.838-.047a.666.666,0,0,1,.689.671.681.681,0,0,1-.672.69l-3.838.048Z"
          fill="#0f1011"
        ></path>
        <path
          d="M360.887,210.258a.734.734,0,0,1-.16-.018l-3.784-.912a.681.681,0,0,1,.318-1.324l3.784.911a.681.681,0,0,1-.158,1.343Z"
          fill="#0f1011"
        ></path>
      </g>
      <g opacity="0.15">
        <path
          d="M351.327,220.92a.5.5,0,0,1-.146-.022l-3.488-1.072a.5.5,0,0,1-.31-.682l2.293-5.107a.5.5,0,0,1,.912.41l-2.06,4.59,2.946.905a.5.5,0,0,1-.147.978Z"
        ></path>
      </g>
      <path
        d="M347.705,227.046s5.348-.564,9.1.746c0,0-.029-3.523-4.262-3.87S347.705,227.046,347.705,227.046Z"
        fill="#fff"
      ></path>
    </g>
    <g>
      <g>
        <path
          d="M318.623,408.432c-1.9-.319-23.333-3.98-23.333-3.98l3.535-20.7s21.378,3.653,23.273,3.97Z"
          fill="#EDA59F"
        ></path>
        <path
          d="M283.062,428.288a4.108,4.108,0,0,0,3.693,4.752h0A4.108,4.108,0,0,0,290.674,431l12.112-22.755a3.461,3.461,0,0,0,.16-2.885,19.107,19.107,0,0,1-1.46-11.873,17.03,17.03,0,0,1,4.635-8.167,1.547,1.547,0,0,0-.768-2.691l-11.788-1.974a2.678,2.678,0,0,0-3.085,2.21Z"
          fill="#c1d8ed"
        ></path>
      </g>
      <path
        d="M384.981,352.444c8.895.371,17.955.631,27.015.855l13.616.3,13.6.245,1.355.024a22.2,22.2,0,0,1,2.276,44.232l-134.4,16.32L302.971,374.7l133.8-20.655L440.4,398.3l-13.808.892-13.8.834c-9.214.533-18.433,1.031-27.816,1.416Z"
        fill="#55affc"
      ></path>
      <g>
        <path
          d="M418.884,399.734c-18.552-2.7-20.4-3.195-21.009-3.358l5.449-20.281c-.061-.017-.14-.038-.236-.059,1.52.332,10.95,1.772,18.818,2.916Z"
          fill="#EDA59F"
        ></path>
        <path
          d="M442.062,417.785a4.107,4.107,0,0,1-2.724,5.366h0a4.109,4.109,0,0,1-4.235-1.258l-16.211-20.042a3.46,3.46,0,0,1-.7-2.8,19.112,19.112,0,0,0-.82-11.934,17.031,17.031,0,0,0-6.1-7.138,1.547,1.547,0,0,1,.243-2.788l11.2-4.176a2.678,2.678,0,0,1,3.448,1.584Z"
          fill="#c1d8ed"
        ></path>
      </g>
      <path
        d="M355.139,397.844c-5.632-.479-11.261-.825-16.89-1.15-5.628-.3-11.257-.6-16.883-.8l-8.441-.325-8.438-.243c-5.626-.188-11.25-.265-16.875-.372l1.95-40.409c10.529,1.472,21.072,2.825,31.611,4.211s21.092,2.662,31.642,3.96l15.833,1.866,15.844,1.776c10.569,1.136,21.138,2.264,31.731,3.194l-4.649,39.83c-10.522-1.534-21.067-2.87-31.612-4.2l-15.828-1.92-15.838-1.83c-10.565-1.166-21.127-2.351-31.7-3.432s-21.146-2.189-31.732-3.181a20.231,20.231,0,0,1,.7-40.339l1.252-.07c5.616-.33,11.233-.629,16.847-1.038l8.422-.577,8.421-.658c5.614-.423,11.226-.948,16.838-1.468,5.612-.546,11.223-1.115,16.832-1.816Z"
        fill="#55affc"
      ></path>
      <polygon
        points="405.416 346.31 408.376 396.015 322.886 395.011 329.339 351.005 405.416 346.31"
        fill="#55affc"
      ></polygon>
    </g>
    <path
      d="M306.652,336.032a10.5,10.5,0,0,1-8.875-4.891l-30.312-47.98,17.754-11.216L305.2,303.567l21.122-45.943L345.4,266.4l-29.2,63.522a10.5,10.5,0,0,1-8.845,6.091C307.115,336.025,306.883,336.032,306.652,336.032Z"
      fill="#2f3235"
    ></path>
    <path
      d="M323.323,354.353c4.19,9.63,86.52,13.63,87.62-3.31.7-10.78-8.8-20.19-8.8-41.2s19.33-38.84,3.58-54.59c-4.57-4.57-12.46-7.52-22.08-7.53-.72,5.61-34.78,5.63-35.04.13-1.3.02-2.31.04-2.94.07-33.18,1.43-18.6,30.19-18.28,57.93C327.773,338.313,319,344.433,323.323,354.353Z"
      fill="#2f3235"
    ></path>
    <path
      d="M348.55,247.853c.26,5.5,3.25,13.59,12.48,14.2,12.3.81,21.84-8.72,22.56-14.33h-.03C371.45,247.723,355.61,247.743,348.55,247.853Z"
      fill="#EDA59F"
    ></path>
    <path
      d="M426.406,340.118a10.888,10.888,0,0,1-6.4-2.076l-59.193-42.924,12.8-17.652,27.892,20.227-10.975-26.419,20.137-8.366,25.807,62.125a10.9,10.9,0,0,1-10.069,15.085Z"
      fill="#2f3235"
    ></path>
    <path
      d="M605.531,416.935a204.718,204.718,0,0,1-48.254-5.719,172.21,172.21,0,0,1-61.79-28.521c-22.509-16.621-40.7-39.095-54.072-66.8-11.319-23.453-28.655-38.742-47.563-41.947-14.95-2.533-29.8,2.689-41.818,14.7l-1.414-1.414c12.487-12.487,27.956-17.91,43.566-15.262,19.554,3.314,37.426,19,49.03,43.049,29.935,62.023,78.767,85.475,114.461,94.228,38.741,9.5,70.82,4.235,71.139,4.179l.336,1.972A158.541,158.541,0,0,1,605.531,416.935Z"
    ></path>
    <g>
      <path
        d="M375.165,287.122s-.936-9.079-5.486-13.512-5.282-.579-3.72,3.731,5.451,13.5,5.451,13.5"
        fill="#EDA59F"
      ></path>
      <path
        d="M373.954,282.631s-10.379-20.253-21.818-2.361c-2.2,3.442,13.077,14.923,13.077,14.923Z"
        fill="#EDA59F"
      ></path>
    </g>
    <rect
      x="385.907"
      y="271.195"
      width="21.805"
      height="73.118"
      transform="translate(-85.279 448.328) rotate(-54.052)"
      fill="#2f3235"
    ></rect>
    <g>
      <path
        d="M617.48,354.673l-3.859,57.534a4.963,4.963,0,0,0,4.952,5.3h80.359a4.963,4.963,0,0,0,4.937-4.453l5.946-57.535a4.963,4.963,0,0,0-4.937-5.473H622.433A4.963,4.963,0,0,0,617.48,354.673Z"
        fill="#9ebcea"
      ></path>
      <path
        d="M620.692,355.2l-3.962,57.983a1.875,1.875,0,0,0,1.871,2h80.252a1.874,1.874,0,0,0,1.865-1.682l6.106-57.984a1.875,1.875,0,0,0-1.865-2.067h-82.4A1.876,1.876,0,0,0,620.692,355.2Z"
        fill="#fff"
      ></path>
      <rect x="593.402" y="410.574" width="112.111" height="6.929" rx="3.465" fill="#9ebcea"></rect>
    </g>
    <g>
      <g>
        <circle cx="524.248" cy="269.026" r="13.767" fill="#9ebcea"></circle>
        <path
          d="M549.814,254.3a4.4,4.4,0,0,1-3.684-1.99,25.252,25.252,0,0,0-2.812-3.547l-.509-.5a26.232,26.232,0,0,0-37.143,0,25.385,25.385,0,0,0-3.3,4.046,4.4,4.4,0,0,1-7.361-4.813,34.244,34.244,0,0,1,4.442-5.452,35.024,35.024,0,0,1,48.223-1.288h.068l1.31,1.288a34.168,34.168,0,0,1,4.44,5.45,4.4,4.4,0,0,1-3.675,6.807Z"
          fill="#9ebcea"
        ></path>
        <path
          d="M480.279,242.118a4.4,4.4,0,0,1-3.66-6.832,57.644,57.644,0,0,1,7.239-8.855,57.136,57.136,0,0,1,80.781,0,57.552,57.552,0,0,1,7.238,8.833,4.4,4.4,0,1,1-7.326,4.867,48.8,48.8,0,0,0-6.131-7.481,48.342,48.342,0,0,0-68.344,0,48.894,48.894,0,0,0-6.13,7.5A4.4,4.4,0,0,1,480.279,242.118Z"
          fill="#9ebcea"
        ></path>
        <path
          d="M586.227,230.157a4.4,4.4,0,0,1-3.666-1.963,70.713,70.713,0,0,0-8.86-10.827,69.947,69.947,0,0,0-98.906,0,72.185,72.185,0,0,0-8.889,10.839,4.4,4.4,0,0,1-7.307-4.9,81.028,81.028,0,0,1,9.977-12.161,78.741,78.741,0,0,1,111.342,0,79.577,79.577,0,0,1,9.967,12.177,4.4,4.4,0,0,1-3.658,6.834Z"
          fill="#9ebcea"
        ></path>
      </g>
      <g>
        <circle cx="572.663" cy="273.064" r="33.288" fill="#fd5d76"></circle>
        <path
          d="M585.794,290.245a3.522,3.522,0,0,1-2.546-1.083l-26.261-27.3a3.533,3.533,0,1,1,5.091-4.9l26.262,27.3a3.532,3.532,0,0,1-2.546,5.981Z"
          fill="#fff"
        ></path>
        <path
          d="M559.532,290.245a3.531,3.531,0,0,1-2.545-5.981l26.261-27.3a3.533,3.533,0,1,1,5.092,4.9l-26.262,27.3A3.52,3.52,0,0,1,559.532,290.245Z"
          fill="#fff"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <g>
          <rect
            x="644.803"
            y="364.901"
            width="2.587"
            height="16.784"
            transform="translate(453.195 -347.524) rotate(45)"
            fill="#9ebcea"
          ></rect>
          <rect
            x="644.803"
            y="364.901"
            width="2.587"
            height="16.784"
            transform="translate(1366.914 180.392) rotate(135)"
            fill="#9ebcea"
          ></rect>
        </g>
        <g>
          <rect
            x="672.496"
            y="364.901"
            width="2.587"
            height="16.784"
            transform="translate(461.307 -367.106) rotate(45)"
            fill="#9ebcea"
          ></rect>
          <rect
            x="672.496"
            y="364.901"
            width="2.587"
            height="16.784"
            transform="translate(1414.189 160.81) rotate(135)"
            fill="#9ebcea"
          ></rect>
        </g>
      </g>
      <path
        d="M647.941,400.421l-1.915-1.754c.209-.229,5.236-5.6,14.675-5.6,9.492,0,13.921,5.435,14.105,5.667l-2.033,1.615c-.151-.186-3.892-4.686-12.072-4.686C652.393,395.665,647.984,400.373,647.941,400.421Z"
        fill="#9ebcea"
      ></path>
    </g>
    <path
      d="M171.1,417.5H51.312c-8.787-7.013-9.393-22.812-.285-29.868,7.02-5.437,17.633-3.983,24.231,1.673a23.4,23.4,0,0,1-.07-12.907c2.146-7.607,6.915-15.335,14.2-18.411,18.583-7.841,31.787,8.588,34.433,17.774,4.757-7,15.4-10.695,22.081-5.985,8.463,5.965,8.08,15.37,1.329,22.121,9-1.677,15.769-.384,22.008,6.313C173.985,403.31,174.712,412,171.1,417.5Z"
      fill="#8ddbe0"
    ></path>
    <path
      d="M603.267,82.01H377.044c1.28-8.594,5.981-16.56,13.164-20.449,8.884-4.811,19.681-3.391,29.7-2.15S441,61.348,449.226,55.493c4.14-2.946,7.068-7.241,10.42-11.062,9.58-10.932,24.686-18.41,38.7-14.551,7.053,1.942,13.072,6.483,19.758,9.45a46.983,46.983,0,0,0,22.367,3.879c7.9-.585,15.758-3.169,23.594-2.01,9.932,1.478,18.067,8.768,23.956,16.9C593.6,65.807,597.586,73.281,603.267,82.01Z"
      fill="#dee8f5"
    ></path>
    <path
      d="M320.36,147.722H94.382a15.735,15.735,0,0,1,10.254-22.689c7.378-1.75,15.717,1.841,22.442-1.656,5.218-2.716,7.629-8.846,8.739-14.618s1.425-11.869,4.228-17.037c4.71-8.678,15.894-12.554,25.55-10.486s17.677,9.1,22.957,17.446c2.172,3.431,4.145,7.307,7.724,9.223,4.133,2.208,9.276,1.156,13.52-.822s8.1-4.822,12.542-6.291a22.6,22.6,0,0,1,25.03,8.458c3.815,5.578,5.412,13.256,11.4,16.382a18.339,18.339,0,0,0,8.108,1.622c11.089.268,22.487-2.316,33.1.912A32.106,32.106,0,0,1,320.36,147.722Z"
      fill="#dee8f5"
    ></path>
  </svg>
</template>
